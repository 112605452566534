import { useMemo } from 'react'

import useLabel from '../../../../hooks/form/useLabel'

const Type = ({ rowData: { isSuperAdmin, isAdmin } }) => {
  const labelKey = useMemo(() => {
    switch (true) {
      case isSuperAdmin:
        return 'super_admin'
      case isAdmin:
        return 'admin'
      default:
        return 'user'
    }
  }, [isSuperAdmin, isAdmin])

  const { labelText } = useLabel({ labelKey })

  return labelText
}

export default Type
