import React, { useCallback, useEffect } from 'react'
import { Field, useField } from 'formik'
import { inject, observer } from 'mobx-react'

import RenderIf from '../../../hoc/RenderIf'
import Condition, { Else, If } from '../../../hoc/Condition'
import CustomInput from '../../../common/CustomInput'
import Dropzone from '../../../Dropzone'
import { mediaResourceConfig } from '../../config'
import CustomRadioButton from '../../../common/CusomRadioButton'
import {
  PromotionMediaResourceType,
  PromotionMediaType,
} from '../../../../constants/promotions'
import {
  getYoutubeEmbedUrl,
  isMediaLinkRequired,
} from '../../../../helpers/promotions'
import usePrevious from '../../../../hooks/usePrevious'

const MediaResourceGroup = ({ mediaType, addImage, removeImage, images }) => {
  const [, , { setValue: setMediaLink }] = useField('mediaLink')
  const [
    { value: mediaResourceType },
    ,
    { setValue: setMediaResourceType },
  ] = useField('mediaResourceType')

  const prev = usePrevious({ mediaType })

  useEffect(() => {
    if (
      mediaType !== prev.mediaType &&
      prev.mediaType === PromotionMediaType.Image &&
      mediaResourceType === PromotionMediaResourceType.File
    ) {
      setMediaResourceType(PromotionMediaResourceType.Url)
    }
  }, [mediaType, mediaResourceType])

  const onMediaLinkChange = useCallback(
    (e) => {
      let { value } = e.target

      if (mediaType === PromotionMediaType.Video) {
        const embed = getYoutubeEmbedUrl(value)
        value = embed || value
      }

      setMediaLink(value)
    },
    [mediaType, setMediaLink],
  )

  return (
    <div className="p-grid p-col-12">
      <RenderIf condition={mediaType === PromotionMediaType.Image}>
        <div className="p-d-flex p-dir-col p-justify-center p-col-12 p-md-4 p-lg-3 p-xl-2">
          {mediaResourceConfig.map((elem, index) => {
            return (
              <Field
                key={index}
                name="mediaResourceType"
                component={CustomRadioButton}
                {...elem}
              />
            )
          })}
        </div>
      </RenderIf>
      <Condition condition={isMediaLinkRequired(mediaType, mediaResourceType)}>
        <If>
          <div className="p-col-12 p-md-8">
            <Field
              type="text"
              name="mediaLink"
              labelText="media_url"
              component={CustomInput}
              overrideOnChange={onMediaLinkChange}
            />
          </div>
        </If>
        <Else>
          <div className="p-col-12 p-md-8 p-lg-6 promotion-dropzone">
            <Dropzone
              titleKey="promotion_drop_only_one_image"
              accept="image/*"
              multiple={false}
              files={images}
              handleAddFiles={addImage}
              handleRemove={removeImage}
            />
          </div>
        </Else>
      </Condition>
    </div>
  )
}
const withData = inject((stores) => ({
  addImage: stores.PromotionCardStore.addImage,
  removeImage: stores.PromotionCardStore.removeImage,
  images: stores.PromotionCardStore.images,
}))

export default withData(observer(MediaResourceGroup))
