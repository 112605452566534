import { action, computed, observable } from 'mobx'

import Http, {
  buildQuery,
  getFileNameFromContentDisposition,
} from '../../helpers/http'
import AuthStore from '../Auth/mobx'
import api from '../../configs/api'
import { noop, pipe } from '../../utils/function'
import {
  Notification,
  success,
  error,
  notifyErrorOccurred,
} from '../../helpers/notifications'
import { ErrorMsg } from '../../constants/errorMsg'
import { findIndexById } from '../../utils/array'
import { UserType } from '../../constants/user'
import { LONG_NOTIFICATION_TIMEOUT } from '../../constants'
import { mapSearchParamsToObj, removePages } from '../../hooks/useSearchParams'
import { download } from '../../utils/react'

const processQueryFields = ({ type, ...fields }) => {
  switch (type) {
    case UserType.User:
      fields = { ...fields, isAdmin: false, isSuperAdmin: false }
      break
    case UserType.Admin:
      fields = { ...fields, isAdmin: true, isSuperAdmin: false }
      break
    case UserType.SuperAdmin:
      fields = { ...fields, isAdmin: true, isSuperAdmin: true }
      break
  }

  return fields
}

const makeQuery = pipe(
  processQueryFields,
  AuthStore.processAuthData,
  buildQuery,
)

class UsersStore {
  resource = `${api.auth}/v1/users`

  @observable isLoading = false
  @observable isExporting = false
  @observable isUserLoading = false
  @observable errorMsg = null
  @observable settingsErrorMsg = null
  @observable avatarErrorMsg = null
  @observable deviceCategoriesErrorMsg = null
  @observable usersList = []
  @observable usersCount = 0
  @observable user = null
  @observable qrCode = null
  @observable lastGetAllQuery = {}

  @computed get currentUserAvatarUrl() {
    const { avatar, id } = AuthStore.user || {}

    if (avatar) {
      return `${this.resource}/${id}/avatars/${avatar}`
    }
  }

  @action
  updateAdminDeviceCategories = async (id, payload, { resetForm }) => {
    const response = await this.update(
      `${this.resource}/${id}/devices/categories`,
      payload,
    )
    this.postUpdate(
      response,
      id,
      'deviceCategoriesErrorMsg',
      Notification.ADMIN_DEVICE_CATEGORIES_UPDATED,
      resetForm,
    )
  }

  @action
  setSettingsError = (err) => {
    this.settingsErrorMsg = err
  }

  @action
  uploadAvatar = async (avatar) => {
    const { id } = AuthStore.user

    this.isLoading = true

    const data = new FormData()
    data.set('avatar', avatar)

    const { result, message = null } = await Http.put(
      `${this.resource}/${id}/avatars`,
      data,
    )

    this.setAvatarError(message)
    if (result) {
      this.updateUserLocalInfo(result, id)
      success(Notification.NEW_AVATAR_UPLOADED)
    }

    this.isLoading = false
  }

  isUserDeletable = (user = this.user) => {
    let isDeletable = user.isDeletable && AuthStore.hasPrivilegeOver(user)
    if (user.id == AuthStore.user?.id) {
      isDeletable = isDeletable && user.isVerified
    }
    return isDeletable
  }

  @action
  exportUsers = async () => {
    if (this.isExporting) return
    this.isExporting = true

    const query = pipe(removePages, makeQuery)(this.lastGetAllQuery)

    const { response, message, parsed } = await Http.get(
      `${this.resource}/export/csv${query}`,
      {
        isBlob: true,
        passResponse: true,
      },
    )

    if (message) {
      notifyErrorOccurred(message)
    } else {
      const name = getFileNameFromContentDisposition(
        response.headers.get('content-disposition'),
        'users.csv',
      )
      download(name, parsed)
    }

    this.isExporting = false
  }

  @action
  getUsers = async (fields) => {
    this.lastGetAllQuery = fields
    const query = makeQuery(fields)

    this.isLoading = true
    const {
      message,
      result = [],
      count = 0,
    } = await Http.get(`${this.resource}${query}`)
    this.errorMsg = message
    this.usersList = result
    this.usersCount = count
    this.isLoading = false
  }

  @action
  getUser = async (id) => {
    this.isUserLoading = true
    const { result, message } = await Http.get(`${this.resource}/${id}`)
    if (message) {
      this.errorMsg = message
    } else {
      this.user = result
      this.errorMsg = null
    }
    this.isUserLoading = false
  }

  @action
  toggleUser = async (user = this.user) => {
    const { id, isDeleted } = user
    this.isLoading = true
    let response, notification

    if (isDeleted) {
      response = await Http.put(`${this.resource}/${id}/restore`)
      notification = Notification.USER_RESTORED
    } else if (this.isUserDeletable(user)) {
      response = await Http.delete(`${this.resource}/${id}`)
      notification = Notification.USER_REMOVED
    } else {
      error(ErrorMsg.CANT_DELETE_THIS_USER, { path: 'errors' })
      this.isLoading = false
      return
    }

    const { message, result } = response

    if (message) {
      error(message, {
        path: 'errors',
        timeout: LONG_NOTIFICATION_TIMEOUT,
      })
    } else {
      success(notification, { replacers: { id } })

      const idx = findIndexById(this.usersList, id)
      if (~idx) {
        this.usersList[idx] = {
          ...this.usersList[idx],
          ...result,
        }
      }

      if (this.user?.id == id) {
        this.user = {
          ...this.user,
          ...result,
        }
      }
      if (id == AuthStore.user.id) void AuthStore.logout()
    }

    this.isLoading = false
  }

  @action
  removeAvatar = async () => {
    const { id, avatar: name } = AuthStore.user
    this.isLoading = true

    const { result, message = null } = await Http.delete(
      `${this.resource}/${id}/avatars/${name}`,
    )

    this.setAvatarError(message)
    if (result) {
      this.updateUserLocalInfo(result, id)
      success(Notification.AVATAR_REMOVED)
    }

    this.isLoading = false
  }

  @action
  setAvatarError = (error) => {
    this.avatarErrorMsg = error
  }

  @action
  unlinkSocial = async ({ oauth, id = AuthStore.user.id }) => {
    this.isLoading = true
    const { message, result } = await Http.patch(
      `${this.resource}/${id}/oauth/unlink`,
      { oauth },
    )
    if (message) {
      this.settingsErrorMsg = message
    } else {
      this.updateUserLocalInfo(result, id)
      this.settingsErrorMsg = null
    }
    this.isLoading = false
  }

  @action
  resetPasswordEmail = async () => {
    const { email } = this.user
    this.isLoading = true
    const response = await AuthStore.resetPasswordEmail(email)
    this.settingsErrorMsg = response?.message || null
    if (!response?.message) {
      success(Notification.RESET_PASSWORD_LINK_SENT, { replacers: { email } })
    }
    this.isLoading = false
  }

  @action
  disable2fa = () => {
    const { id, enabled2fa, enabledSms } = this.user
    if (enabled2fa || enabledSms)
      return this.updateSettings({ id, enabled2fa: false, enabledSms: false })
  }

  @action
  addUser = async (payload, history) => {
    this.isLoading = true
    const { message = null, result } = await Http.post(this.resource, payload)
    this.errorMsg = message
    if (result) {
      success(Notification.USER_CREATED)
      this.cleanUp()
      history.push(`/users/user/${result.id}`)
    }
    this.isLoading = false
  }

  @action
  updateUserInfo = async ({ id, ...payload }, { resetForm }) => {
    const response = await this.update(`${this.resource}/${id}`, payload)
    this.postUpdate(
      response,
      id,
      'errorMsg',
      Notification.INFO_UPDATED,
      resetForm,
    )
  }

  @action
  setCurrentUserLanguage = async (language) => {
    if (!AuthStore.isLogin) return

    const { result } = await Http.patch(`${this.resource}/current/language`, {
      language,
    })

    this.updateUserLocalInfo(result)
  }

  @action
  updateSettings = async ({ id, ...payload }) => {
    const response = await this.update(
      `${this.resource}/${id}/settings`,
      payload,
    )
    this.postUpdate(
      response,
      id,
      'settingsErrorMsg',
      Notification.SETTINGS_UPDATED,
      (result) => {
        if (result.enabled2fa) this.removeQrCode()
      },
    )
  }

  @action
  removeQrCode = () => {
    this.qrCode = null
  }

  @action
  generate2FA = async () => {
    const response = await Http.get(`${api.auth}/v1/2fa/generate`, {
      isBlob: true,
    })
    if (response.message) {
      this.setError(response.message)
    } else {
      this.setError(null)
      this.qrCode = window.URL.createObjectURL(response)
    }
  }

  @action
  setError = (error) => {
    this.errorMsg = error
  }

  @action
  update = async (url, payload) => {
    payload = AuthStore.processAuthData(payload)
    this.isLoading = true
    return Http.put(url, payload)
  }

  @action
  postUpdate = (
    { message, result },
    id,
    errKey,
    successMsg,
    onSuccess = noop,
  ) => {
    if (message) {
      this[errKey] = message
    } else {
      success(successMsg)
      this.updateUserLocalInfo(result, id)
      this[errKey] = null
      onSuccess(result)
    }
    this.isLoading = false
  }

  @action
  updateUserLocalInfo = (result, id = result?.id) => {
    if (!result) return

    const { user, updateCurrentUserInfo } = AuthStore
    if (id == user.id) updateCurrentUserInfo(result)

    if (this.user && this.user.id == id) {
      this.user = { ...this.user, ...result }
    }
  }

  @action
  cleanUp = () => {
    this.profileCleanUp()
    this.user = null
    this.deviceCategoriesErrorMsg = null
    this.isUserLoading = false
    this.usersList = []
    this.usersCount = 0
  }

  @action
  profileCleanUp = () => {
    this.removeQrCode()
    this.errorMsg = null
    this.settingsErrorMsg = null
    this.avatarErrorMsg = null
    this.isLoading = false
    this.isExporting = false
    this.lastGetAllQuery = {}
  }
}

export default new UsersStore()
