import { GiKeyboard } from 'react-icons/gi'
import { VscGroupByRefType } from 'react-icons/vsc'
import { RiFolderSettingsLine } from 'react-icons/ri'

export const deviceCards = [
  {
    labelKey: 'devices',
    icon: GiKeyboard,
    url: '/devices/list',
  },
  {
    labelKey: 'device_profiles',
    icon: RiFolderSettingsLine,
    url: '/devices/profiles',
  },
  {
    labelKey: 'gaming_sets',
    icon: VscGroupByRefType,
    url: '/devices/gaming-sets',
  },
]
