import React, { useMemo } from 'react'
import { inject } from 'mobx-react'

import usePagination from '../../../hooks/table/usePagination'
import FilterForm from './FilterForm'
import View from './View'
import { CommunityError } from '../../messages/Error'
import calcTableHeight from '../../hoc/calcTableHeight'
import { CommunityProfilesContext } from '../../../context'

export * from './config'

const Profiles = ({
  wrapperRef,
  filterRef,
  height,
  getAll,
  cleanUp,
  Filters,
  values,
  columnList,
  columnPropsMapping,
}) => {
  const paginationData = usePagination(cleanUp)

  const ctx = useMemo(
    () => ({ getAll, columnList, columnPropsMapping, Filters, values }),
    [getAll, columnList, columnPropsMapping, Filters, values],
  )

  return (
    <div className="p-fluid page-content-wrapper" ref={wrapperRef}>
      <CommunityProfilesContext.Provider value={ctx}>
        <FilterForm {...paginationData} filterRef={filterRef} />
        <CommunityError />
        <View {...paginationData} tableHeight={height} />
      </CommunityProfilesContext.Provider>
    </div>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.CommunityStore.cleanUp,
}))

export default withData(calcTableHeight(Profiles))
