import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { availableLanguages, fallbackLng } from './constants/languages'

export default () => {
  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng,
      backend: {
        loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
      },
      detection: {
        checkWhitelist: true,
      },
      ns: ['translations'],
      defaultNS: 'translations',
      debug: false,
      whitelist: availableLanguages,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    })
}
