import React from 'react'
import { inject, observer } from 'mobx-react'

import './UploadedBefore.scss'
import RenderIf from '../../../../../hoc/RenderIf'
import Item from './Item'

const UploadedBefore = ({ images }) => (
  <RenderIf condition={images.length}>
    <div className="p-grid device-images-list">
      {images.map((file) => (
        <Item file={file} key={file.filename} />
      ))}
    </div>
  </RenderIf>
)

const withData = inject((stores) => ({
  images: stores.DevicesStore.images,
}))

export default withData(observer(UploadedBefore))
