import React from 'react'
import { Card } from 'primereact/card'
import { useTranslation } from 'react-i18next'
import { Field, useField } from 'formik'

import CustomRadioButton from '../../common/CusomRadioButton'
import { mediaConfig } from '../config'
import MediaResourceGroup from './MediaResourceGroup'
import RenderIf from '../../hoc/RenderIf'
import { PromotionMediaType } from '../../../constants/promotions'

const MediaContent = () => {
  const [{ value: mediaType }] = useField('mediaType')

  const { t } = useTranslation()

  return (
    <Card title={t('card.title.media_content')}>
      <div className="p-col-12 p-grid">
        {mediaConfig.map((elem, index) => {
          return (
            <Field
              key={index}
              name="mediaType"
              component={CustomRadioButton}
              {...elem}
            />
          )
        })}
      </div>
      <RenderIf condition={mediaType !== PromotionMediaType.None}>
        <MediaResourceGroup mediaType={mediaType} />
      </RenderIf>
    </Card>
  )
}

export default MediaContent
