import React, { useMemo } from 'react'
import { Field } from 'formik'

import CustomInput from '../CustomInput'
import { bindClassName } from '../../../utils/react'

export const defaultGridClassName = 'p-col-12 p-md-6 p-lg-4 p-xxl-3'

const FormFields = (props) => {
  const {
    values,
    propsMapping,
    gridClassName = defaultGridClassName,
    groupWrapperClassName = 'user-profile-card',
    withWrapper = true,
    isRequired = true,
    labelPropName = 'labelText',
    children,
  } = props

  const keys = useMemo(() => Object.keys(values), [values])

  const fields = keys.map((key) => {
    const { skip, wrapperClassName, ...fieldProps } = propsMapping?.[key] || {}
    if (skip) return null

    const field = (
      <Field
        key={key}
        name={key}
        component={CustomInput}
        isRequired={isRequired}
        {...(labelPropName ? { [labelPropName]: key } : {})}
        {...fieldProps}
      />
    )

    if (fieldProps.noGrid) return field

    return (
      <div {...bindClassName(gridClassName, [wrapperClassName])} key={key}>
        {field}
      </div>
    )
  })

  if (!withWrapper) return fields

  return (
    <div {...bindClassName('p-grid', [groupWrapperClassName])}>
      {fields}
      {children}
    </div>
  )
}

export default FormFields
