import { useCallback, useEffect, useState } from 'react'

import { DEFAULT_LIMIT } from '../../constants'
import { noop } from '../../utils/function'
import useSearchParams from '../useSearchParams'

const usePagination = (
  cleanUp,
  { defaultLimit = DEFAULT_LIMIT, defaultOffset = 0 } = {},
) => {
  const params = useSearchParams()

  const getValue = useCallback(
    (key, defaultV) => {
      const v = params[key]
      return v !== void 0 ? v : defaultV
    },
    [params],
  )

  const [pagination, setPagination] = useState({
    limit: +getValue('limit', defaultLimit),
    offset: +getValue('offset', defaultOffset),
    sortField: getValue('sortField', null),
    sortOrder: getValue('sortOrder', null),
  })

  useEffect(() => cleanUp || noop, [])

  const onPage = useCallback(
    ({ first, rows }) => {
      setPagination((pagination) => ({
        ...pagination,
        limit: rows,
        offset: first,
      }))
    },
    [setPagination],
  )

  const onSort = useCallback((data) => {
    setPagination((pagination) => ({
      ...pagination,
      ...data,
    }))
  }, [])

  return {
    ...pagination,
    removableSort: true,
    pagination,
    setPagination,
    onSort,
    onPage,
  }
}

export default usePagination
