import api from '../../../../configs/api'
import CrudStore from '../../../../stores/common/CrudStore'

class ApplicationPlatformsStore extends CrudStore {
  resource = `${api.applications}/v1/application-platforms`
  itemUrlTemplate = '/applications/platform/{id}'

  NOTIFICATION_CREATED = 'APPLICATION_PLATFORM_CREATED'
  NOTIFICATION_UPDATED = 'APPLICATION_PLATFORM_UPDATED'
  NOTIFICATION_DELETED = 'APPLICATION_PLATFORM_DELETED'
}

export default new ApplicationPlatformsStore()
