import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../../common/CustomInput'
import { pipe } from '../../../../utils/function'
import CommonFiltersForm, {
  DateRangeField,
} from '../../../table/CommonFiltersForm'
import DeviceCategoryId from '../../../devices/DeviceCategoryId'
import CustomTriStateCheckbox from '../../../common/CustomTriStateCheckbox'

const initialValues = {
  DeviceCategoryId: null,
  company: '',
  model: '',
  sensor: '',
  isPublic: null,
  dateRange: null,
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll, filterRef }) => {
  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      <div className={className}>
        <Field
          type="text"
          name="DeviceCategoryId"
          placeholder="DeviceCategoryId"
          component={DeviceCategoryId}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="company"
          placeholder="company"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="model"
          placeholder="model"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="isPublic"
          placeholder="isPublic"
          component={CustomTriStateCheckbox}
        />
      </div>
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.DevicesStore.isLoading,
  getAll: stores.DevicesStore.getAll,
}))

export default pipe(observer, withData)(FilterForm)
