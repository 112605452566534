import { mapToArray } from '../../utils/array'
import { ErrorMsg } from '../../constants/errorMsg'
import { merge } from '../../utils/object'
import DropzoneInvalidFilesException from '../../exceptions/DropzoneInvalidFilesException'
import { humanizeSize } from '../../utils/size'

const defaultErrors = {
  quantityExceeded: ErrorMsg.DROPZONE_FILES_QUANTITY_EXCEEDED,
  fileLimitExceeded: ErrorMsg.DROPZONE_FILE_LIMIT_EXCEEDED,
  filesLimitExceeded: ErrorMsg.DROPZONE_FILES_LIMIT_EXCEEDED,
  unacceptableFile: ErrorMsg.DROPZONE_UNACCEPTABLE_FILES,
  notValidFileError: ErrorMsg.DROPZONE_UNACCEPTABLE_FILES,
}

export const isExtensionsMatch = (name, allowedExtensions) => {
  if (!allowedExtensions) return true

  return allowedExtensions.some((extension) => {
    extension = extension.replace(/^\./, '')
    const regex = new RegExp(`${['', ...extension.split('.')].join('\\.')}$`)
    return regex.test(name)
  })
}

export const validateFiles = (opts) => {
  let {
    files = [],
    currFiles = [],
    filesMaxQuantity = Infinity,
    fileLimit = Infinity,
    filesLimit = Infinity,
    allowedExtensions,
    isValidFile,
    errors: _errors = {},
  } = opts

  files = mapToArray(files)

  const newLen = files.length + currFiles.length

  const errors = merge(defaultErrors, _errors)

  const invalid = (errorMsg, replacers) => {
    throw new DropzoneInvalidFilesException(errorMsg, replacers)
  }

  if (newLen > filesMaxQuantity) invalid(errors.quantityExceeded)

  const sum = files.reduce((acc, file) => {
    const { size, name } = file

    if (size > fileLimit) {
      invalid(errors.fileLimitExceeded, { limit: humanizeSize(fileLimit) })
    }

    if (!isExtensionsMatch(name, allowedExtensions)) {
      const extensions = allowedExtensions.map((e) => `*.${e}`).join(', ')
      invalid(errors.unacceptableFile, { extensions })
    }

    if (isValidFile && !isValidFile(file)) invalid(errors.notValidFileError)

    return acc + size
  }, 0)

  if (sum > filesLimit) {
    invalid(errors.filesLimitExceeded, { limit: humanizeSize(filesLimit) })
  }
}
