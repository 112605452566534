import React from 'react'
import { Field } from 'formik'

import CustomCalendar from '../../../common/CustomCalendar'

const DateRangeField = ({ className, ...rest }) => {
  return (
    <div className={className}>
      <Field
        name="dateRange"
        component={CustomCalendar}
        placeholder="created"
        notFuture
        {...rest}
      />
    </div>
  )
}

export default React.memo(DateRangeField)
