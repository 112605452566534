import React from 'react'
import { inject } from 'mobx-react'

import SecondaryButton from '../../buttons/SecondaryButton'
import UploadModal from './UploadModal'

const UploadGroup = ({ toggleModal }) => (
  <>
    <SecondaryButton labelKey="upload" onClick={toggleModal} />
    <UploadModal />
  </>
)

const withData = inject((stores) => ({
  toggleModal: stores.DesktopApplicationStore.toggleModal,
}))

export default withData(UploadGroup)
