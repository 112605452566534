import React from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../../constants'
import { renderIntoTable } from '../../../../utils/react'
import ActionBody from './ActionBody'

const renderActionBody = renderIntoTable(ActionBody)

const View = ({
  limit,
  offset,
  onPage,
  list,
  isLoading,
  count,
  tableHeight,
}) => {
  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No device profiles found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} device profiles"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      <Column className="middle-td-style" field="name" header="Name" />
      <Column className="middle-td-style" field="sensor" header="Sensor" />
      <Column
        className="middle-td-style"
        field="DeviceCategory.name"
        header="Device category"
      />
      <Column
        className="common-td-style"
        header="Actions"
        body={renderActionBody}
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  list: stores.DeviceProfilesStore.list,
  count: stores.DeviceProfilesStore.count,
  isLoading: stores.DeviceProfilesStore.isLoading,
}))

export default withData(observer(View))
