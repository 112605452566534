import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'

import DateRangeField from './DateRangeField'
import useApplyFilters from '../../../hooks/table/useApplyFilters'
import PageTracker from '../PageTracker'
import ApplyFilters from '../ApplyFilters'
import Clear from '../Clear'
import Error from '../../messages/Error'
import FiltersWrapper from './ui/Wrapper'
import useSearchParams from '../../../hooks/useSearchParams'
import { compare, defaultDatePredicate } from '../../../utils/object'
import { parseBoolean } from '../../../utils/string'
import { mapToArray } from '../../../utils/array'
import { isValidDate } from '../../../utils/date'

const CommonFiltersForm = (props) => {
  const {
    getAll,
    limit,
    offset,
    sortField,
    sortOrder,
    applyLabel,
    initialValues,
    isLoading,
    validationSchema,
    errorMsg,
    errorComponent: ErrorComponent,
    children,
    filterRef,
    outFormElem,
  } = props

  const searchParams = useSearchParams()

  const applyFilters = useApplyFilters(getAll, {
    limit,
    offset,
    sortField,
    sortOrder,
  })

  const _initialValues = useMemo(() => {
    return Object.entries(initialValues).reduce((acc, [key, value]) => {
      const sValue = searchParams[key]

      const isArray =
        Array.isArray(value) || Array.isArray(sValue) || key === 'dateRange'
      const defaultValue = value

      if (sValue !== void 0) {
        const asBoolean = parseBoolean(sValue)
        value = asBoolean !== null ? asBoolean : sValue
      }

      value = mapToArray(value)

      value = value.map((v) => {
        if (defaultDatePredicate(key, v) && isValidDate(v)) return new Date(v)

        return isNaN(parseInt(v)) ? v : +v
      })

      if (!isArray) {
        const [first] = value
        value = first !== void 0 ? first : defaultValue
      }

      return {
        ...acc,
        [key]: value,
      }
    }, {})
  }, [initialValues, searchParams])

  return (
    <FiltersWrapper filterRef={filterRef}>
      {outFormElem}
      <Formik
        initialValues={_initialValues}
        onSubmit={applyFilters}
        validationSchema={validationSchema}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <PageTracker
              submitForm={submitForm}
              limit={limit}
              offset={offset}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <div className="p-d-flex">
              {ErrorComponent && <ErrorComponent />}
              <Error errorMsg={errorMsg} />
              {children}
              <div className="p-col-12 col-md-3 p-d-flex p-dir-row">
                <ApplyFilters isLoading={isLoading} label={applyLabel} />
                <Clear
                  dirty={!compare(values, initialValues)}
                  resetForm={resetForm}
                  initialValues={initialValues}
                  submitForm={submitForm}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </FiltersWrapper>
  )
}

export { DateRangeField }

export default React.memo(CommonFiltersForm)
