import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'

import useToggler from '../../../../../hooks/useToggler'
import { bindClassName } from '../../../../../utils/react'
import useLabel from '../../../../../hooks/form/useLabel'

const FiltersWrapper = ({ filterRef, children, titleKey = 'filters' }) => {
  const [mobileFilterIsOpen, toggleMobileFilter] = useToggler()

  const { labelText } = useLabel({ labelKey: titleKey })

  return (
    <div
      {...bindClassName('p-col-12 common-filter-wrapper', [
        mobileFilterIsOpen,
        'active',
      ])}
      ref={filterRef}
    >
      <button
        className="mobile-close-filter-button"
        onClick={toggleMobileFilter}
      >
        {labelText}
        <span>
          <MdKeyboardArrowDown />
        </span>
      </button>
      {children}
    </div>
  )
}

export default FiltersWrapper
