import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useSearchParams from '../../hooks/useSearchParams'
import { buildQuery } from '../../helpers/http'
import usePrevious from '../../hooks/usePrevious'

const PageTabs = ({
  tabs,
  section,
  className = 'common-profile-tab-wrapper',
  defaultActiveIndex,
  beforeTabChange,
  afterTabChange,
  ...props
}) => {
  defaultActiveIndex = defaultActiveIndex || 0
  const history = useHistory()
  const { pathname } = history.location

  const { t } = useTranslation()
  const { tab, ...searchParams } = useSearchParams()

  const push = useCallback(
    (tab) => history.push(`${pathname}${buildQuery({ ...searchParams, tab })}`),
    [history, pathname, searchParams],
  )

  const filteredTabs = useMemo(
    () => tabs.filter(({ isActive = true }) => isActive),
    [tabs],
  )

  const findTabIndex = useCallback(
    (tab) => tabs.findIndex(({ tabName }) => tabName && tabName == tab),
    [tabs],
  )

  const [activeIndex, setActiveIndex] = useState(() => {
    if (tab) {
      const idx = findTabIndex(tab)
      if (~idx) return idx
    }
    return defaultActiveIndex
  })

  const prev = usePrevious({ tab })

  useEffect(() => {
    if (tab != prev.tab && tab) {
      const idx = findTabIndex(tab)
      if (~idx && idx != activeIndex) setActiveIndex(idx)
    }
  }, [tab, activeIndex, filteredTabs, findTabIndex, setActiveIndex, push])

  const onTabChange = useCallback(
    (e) => {
      const { index } = e
      const { tabName } = filteredTabs[index] || {}

      if (beforeTabChange) beforeTabChange(e)

      if (tabName) push(tabName)
      setActiveIndex(index)

      if (afterTabChange) afterTabChange(e)
    },
    [setActiveIndex, tab, push, filteredTabs],
  )

  return (
    <TabView
      className={className}
      activeIndex={activeIndex}
      onTabChange={onTabChange}
      {...props}
    >
      {filteredTabs.map(
        ({ tabName, header = tabName, Component, key = header }) => {
          const title = t(`${section}.tab_labels.${header}`)

          return (
            <TabPanel
              key={key}
              header={title}
              headerClassName="profile-tab-headline"
            >
              <Component title={title} {...props} />
            </TabPanel>
          )
        },
      )}
    </TabView>
  )
}

export default React.memo(PageTabs)
