import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Error from '../../components/messages/Error'
import { findValue } from '../../utils/object'

const useError = (errorMsg) => {
  const location = useLocation()
  const { t } = useTranslation()

  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const errorFromQuery = urlSearchParams.get('errorMsg')
    const provider = urlSearchParams.get('provider')
    const error = errorMsg || errorFromQuery

    return error ? t(`errors.${error}`, error, { provider }) : null
  }, [location.search, errorMsg])
}

export const useFormError = (form, field) => {
  const errorMsg =
    findValue(form, `touched.${field.name}`) &&
    findValue(form, `errors.${field.name}`)

  const error = <Error errorMsg={errorMsg} />

  return { error, errorMsg, showError: !!errorMsg }
}

export default useError
