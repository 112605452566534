import * as yup from 'yup'
import { isNil } from './object'

export const devicesGetErrorKey = (key) =>
  key
    .replace(/^Device/, '')
    .replace(/Id$/, '')
    .replace(/^_/, '')
    .toUpperCase()

export const createShape = (defaultValues, propsMapping, getErrorMsg) =>
  Object.entries(defaultValues).reduce((acc, [key, value]) => {
    const { isRequired = true } = propsMapping[key] || {}

    const errorMsg = getErrorMsg(key)

    let config

    if (value === null) {
      config = yup.number().typeError(errorMsg).nullable()
    } else {
      config = yup.string().trim()
    }

    if (isRequired) {
      config = config.required(errorMsg)
    }

    return { ...acc, [key]: config }
  }, {})

export const createInitialValues = (defaultValues, item) =>
  Object.entries(defaultValues).reduce((acc, [key, value]) => {
    const iValue = item?.[key]
    if (!isNil(iValue)) value = iValue

    return {
      ...acc,
      [key]: value,
    }
  }, {})

export const formDataAppendArray = (key, arr, formData = new FormData()) => {
  arr.forEach((item) => formData.append(key, item))
  return formData
}
