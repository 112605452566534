import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import useInitialValues from '../../hooks/form/useInitialValues'
import useCommonSubmit from '../../hooks/form/useCommonSubmit'
import { defaultValues, validationSchema, propsMapping } from './config'
import Loadable from '../hoc/Loadable'
import ButtonSubmit from '../buttons/ButtonSubmit'
import FormFields from '../common/FormFields'
import { ApplicationPlatformsError } from '../messages/Error'

const ApplicationPlatformPage = (props) => {
  const { getOne, submitOne, cleanUp, item, isLoading } = props

  const { id } = useParams()

  useEffect(() => {
    if (id) getOne(id)

    return cleanUp
  }, [id])

  const initialValues = useInitialValues(defaultValues, item)
  const onSubmit = useCommonSubmit(item, submitOne)
  return (
    <>
      <CustomBreadCrumb model={Model.ApplicationPlatform} />
      <div className="p-fluid page-content-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ dirty }) => (
            <Form className="p-grid p-col-12">
              <Loadable loading={isLoading}>
                <ApplicationPlatformsError />
                <FormFields
                  values={defaultValues}
                  propsMapping={propsMapping}
                />
                <ButtonSubmit dirty={dirty} isLoading={isLoading} />
              </Loadable>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  getOne: stores.ApplicationPlatformsStore.getOne,
  submitOne: stores.ApplicationPlatformsStore.submitOne,
  cleanUp: stores.ApplicationPlatformsStore.cleanUp,
  item: stores.ApplicationPlatformsStore.item,
  isLoading: stores.ApplicationPlatformsStore.isLoading,
}))

export default withData(observer(ApplicationPlatformPage))
