import React from 'react'

import Description from './Description'
import Instructions from './Instructions'

import './Documentation.scss'

const Documents = ({ id }) => (
  <div className="p-col-12 p-md-6 p-d-flex p-dir-col">
    <Description />
    <Instructions id={id} />
  </div>
)

export default Documents
