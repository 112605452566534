import { observable, action } from 'mobx'

import Http, { buildQuery } from '../../../helpers/http'
import api from '../../../configs/api'

class DeviceCategoriesStore {
  resource = `${api.devices}/v1/devices/categories`

  @observable isLoading = false
  @observable errorMsg = null
  @observable list = []
  @observable count = 0

  @action
  getAll = async (fields = {}) => {
    this.isLoading = true
    fields = { isActive: true, ...fields }
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.errorMsg = null
    this.list = []
    this.count = 0
  }
}

export default new DeviceCategoriesStore()
