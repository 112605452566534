import { action, observable, computed } from 'mobx'
import Http, {
  buildQuery,
  getFileNameFromContentDisposition,
} from '../../helpers/http'
import {
  error,
  notifyErrorOccurred,
  success,
} from '../../helpers/notifications'
import { injectIntoTemplate } from '../../utils/string'
import { bindAllMethods, pipe } from '../../utils/function'
import { formatFilters } from '../../hooks/table/useApplyFilters'
import { removePages } from '../../hooks/useSearchParams'
import { download } from '../../utils/react'
import { Notification } from '../../helpers/notifications'

/**@abstract*/
class CrudStore {
  resource = ''
  itemUrlTemplate = ''

  fallbackCsvExportName = 'data'

  NOTIFICATION_CREATED = ''
  NOTIFICATION_UPDATED = ''
  NOTIFICATION_DELETED = ''

  @observable isLoading = false
  @observable isExporting = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable item = null
  @observable lastGetAllQuery = {}

  constructor() {
    bindAllMethods(this)
  }

  processSubmitData(values) {
    return values
  }

  processItem(values) {
    return values
  }

  processList({ result }) {
    return result
  }

  prepareQueryObject(query) {
    return query
  }

  get csvResource() {
    return `${this.resource}/export/csv`
  }

  @computed get hasList() {
    return !!this.list.length
  }

  @action
  async getAll(query = {}) {
    this.isLoading = true

    const initialQuery = (this.lastGetAllQuery = query)
    query = pipe(formatFilters, this.prepareQueryObject, buildQuery)(query)

    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = this.processList({
      result,
      query,
      initialQuery,
      count,
      message,
    })
    this.count = count

    this.isLoading = false
  }

  @action
  async deleteOne(id) {
    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      error(response.message, { path: 'errors' })
    } else {
      success(this.NOTIFICATION_DELETED)
      this.list = this.list.filter((item) => item.id != id)
    }
    this.isLoading = false
  }

  @action
  async getOne(id) {
    this.isLoading = true
    const { message = null, result } = await Http.get(`${this.resource}/${id}`)
    this.errorMsg = message
    this.item = result ? this.processItem(result) : null
    this.isLoading = false
  }

  @action
  async submitOne({ id, ...values }, { resetForm }, history) {
    this.isLoading = true
    values = this.processSubmitData(values)

    let response, onSuccess

    if (id) {
      response = await Http.put(`${this.resource}/${id}`, values)
      onSuccess = (result) => {
        success(this.NOTIFICATION_UPDATED)
        this.item = this.processItem({ ...this.item, ...result })
        resetForm()
      }
    } else {
      response = await Http.post(this.resource, values)
      onSuccess = (result) => {
        success(this.NOTIFICATION_CREATED)
        history.push(this.getItemUrl(result))
      }
    }

    const { message = null, result } = response
    this.errorMsg = message
    if (result) {
      onSuccess(result)
    }
    this.isLoading = false
  }

  @action
  async exportData({ query = this.lastGetAllQuery } = {}) {
    if (this.isExporting) return
    this.isExporting = true

    query = pipe(removePages, buildQuery)(query)

    success(Notification.EXPORT_STARTED)

    const { response, message, parsed } = await Http.get(
      `${this.csvResource}${query}`,
      {
        isBlob: true,
        passResponse: true,
      },
    )

    if (message) {
      notifyErrorOccurred(message)
    } else {
      const name = getFileNameFromContentDisposition(
        response.headers.get('content-disposition'),
        `${this.fallbackCsvExportName}.csv`,
      )
      download(name, parsed)
    }

    this.isExporting = false
  }

  getItemUrl(item) {
    return injectIntoTemplate(this.itemUrlTemplate, item)
  }

  @action
  cleanUp() {
    this.isLoading = false
    this.isExporting = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
    this.lastGetAllQuery = {}
  }
}

export default CrudStore
