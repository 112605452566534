import { DesktopPlatform, platforms } from '../../../constants/desktop'

export const extractVersion = (name) => {
  const splitted = (name || '').split('-')
  return splitted[splitted.length - 2]
}

export const extractPlatform = (name) => {
  const platform = (name || '').split('-')?.pop()?.replace(/\..*/g, '') || ''

  if (/win(32|86)/.test(platform)) return DesktopPlatform.Windows32

  return platforms.find((p) => p === platform) || null
}

export const extractInfoFromName = (name) => ({
  version: extractVersion(name),
  platform: extractPlatform(name),
})
