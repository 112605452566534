import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AlternativeSignLink from '../SignLink/AlternativeSignLink'
import Condition, { Else, If } from '../../../hoc/Condition'

const altSignLinkPaths = ['/forgot-password', '/change-password']

const SignHeadLine = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const condition = useMemo(
    () => altSignLinkPaths.some((path) => pathname.startsWith(path)),
    [pathname],
  )

  return (
    <>
      <Condition condition={condition}>
        <If>
          <AlternativeSignLink condition={condition} />
        </If>
        <Else>
          <div className="p-d-flex p-dir-row p-mb-3 p-card-title">
            {t('auth.administrator')}
          </div>
        </Else>
      </Condition>
    </>
  )
}

export default SignHeadLine
