import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import './style.scss'
import LogInfo from './LogInfo'
import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import Loadable from '../hoc/Loadable'
import { LogsError } from '../messages/Error'

const LogPage = ({ getLog, match, cleanUp, isLoading, hasLog }) => {
  const { id } = match.params

  useEffect(() => {
    getLog(id)

    return cleanUp
  }, [id])

  return (
    <>
      <CustomBreadCrumb model={Model.Log} />
      <div className="p-fluid page-content-wrapper">
        <LogsError />
        <Loadable loading={isLoading}>{hasLog && <LogInfo />}</Loadable>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  getLog: stores.LogsStore.getLog,
  cleanUp: stores.LogsStore.cleanUp,
  isLoading: stores.LogsStore.isLoading,
  hasLog: !!stores.LogsStore.log,
}))

export default withData(observer(LogPage))
