import React from 'react'
import { CgImage } from 'react-icons/cg'

import './ProfilePhoto.scss'
import CommonCard from '../../common/CommonCard'
import Avatar from './Avatar'
import Actions from './Actions'
import { UsersAvatarError } from '../../messages/Error'
import CropperModal from './CropperModal'
import useToggler from '../../../hooks/useToggler'

const ProfilePhoto = ({ title }) => {
  const [isModalOpen, toggleModalOpen] = useToggler()

  return (
    <CommonCard
      title={title}
      icon={<CgImage />}
      className="user-profile-card p-mx-auto avatar-card"
    >
      <div className="p-grid p-fluid">
        <CropperModal
          isModalOpen={isModalOpen}
          toggleModalOpen={toggleModalOpen}
        />
        <UsersAvatarError />
        <div className="p-col-12 p-md-6 p-d-flex p-justify-center user-profile-avatar-block">
          <Avatar toggleCropperModal={toggleModalOpen} />
        </div>
        <Actions />
      </div>
    </CommonCard>
  )
}

export default ProfilePhoto
