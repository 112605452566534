import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import './ExpandedRow.scss'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const ExpandedRow = ({ rowData: { id, devices, name }, getOne }) => {
  useEffect(() => {
    getOne(id)
  }, [id, getOne])

  return (
    devices && (
      <div className="config-sub-table">
        <p className="heat-title-sub-table">
          Configuration for {name} device profile
        </p>
        <DataTable
          emptyMessage="No configuration found"
          value={devices}
          className="sup-table"
        >
          <Column
            field="Device.DeviceCategory.name"
            header="Device category"
            className="common-td-style"
          />
          <Column
            field="Device.model"
            header="Device model"
            className="common-td-style"
          />
        </DataTable>
      </div>
    )
  )
}
const withData = inject((stores) => ({
  getOne: stores.CommunityStore.getOne,
}))

export default withData(observer(ExpandedRow))
