import React from 'react'
import { renderIntoTable } from '../../../../utils/react'

const SignInInfo = ({ rowData: { signIns }, which }) => {
  if (!signIns) return <b>unknown</b>

  const { type = 'unknown', osPlatform = 'unknown' } = signIns?.[which] || {}
  return [osPlatform, type].join(' / ')
}

export const renderFirstSignIn = renderIntoTable(SignInInfo, { which: 'first' })
export const renderLastSignIn = renderIntoTable(SignInInfo, { which: 'last' })

export default SignInInfo
