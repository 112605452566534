import React, { useCallback } from 'react'
import { Button } from 'primereact/button'

import { bindClassName } from '../../../utils/react'
import { useTranslation } from 'react-i18next'

const Clear = ({
  resetForm,
  initialValues,
  submitForm,
  dirty = true,
  className,
  label = 'clear',
}) => {
  const handleClear = useCallback(() => {
    if (!dirty) return
    resetForm(initialValues ? { values: initialValues } : void 0)
    submitForm()
  }, [resetForm, submitForm, dirty, initialValues])

  const { t } = useTranslation()

  return (
    <Button
      {...bindClassName('p-button-secondary', [className])}
      disabled={!dirty}
      type="button"
      label={t(`actions.${label}`)}
      onClick={handleClear}
    />
  )
}

export default React.memo(Clear)
