import React from 'react'

import { createIdToValueMapper, createSelect } from '../../utils/react'

const storeName = 'ApplicationsStore'

export const ApplicationsSelect = createSelect({
  storeName,
})

export const MappedApplication = createIdToValueMapper({
  storeName,
  dataIdKey: 'ApplicationId',
  dataName: 'name',
  fallback: '-',
})
