import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../constants'
import { pipe } from '../../../utils/function'
import { formattedCreatedAt } from '../../../utils/react'

const View = ({
  limit,
  offset,
  onPage,
  list,
  isLoading,
  count,
  tableHeight,
}) => {
  const history = useHistory()
  const onRowDoubleClick = useCallback(
    (e) => history.push(`/logs/${e.data.id}`),
    [history],
  )

  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      lazy
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No logs found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} logs"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      totalRecords={count}
      scrollable
      scrollHeight={`${tableHeight}px`}
      onPage={onPage}
      onRowDoubleClick={onRowDoubleClick}
    >
      <Column field="userId" header="User id" className="created-at-style " />
      <Column field="login" header="Login" className="common-td-style" />
      <Column field="ip" header="Ip" className="middle-td-style" />
      <Column
        field="createdAt"
        header="Date"
        body={formattedCreatedAt}
        className="created-at-style"
      />
      <Column
        field="actionText"
        header="Action Text"
        className="common-td-style"
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  list: stores.LogsStore.list,
  count: stores.LogsStore.count,
  isLoading: stores.LogsStore.isLoading,
}))

export default pipe(observer, withData)(View)
