import React from 'react'

import { buildAppImageUrl } from '../../AppsPage/tabs/Applications/mobx'
import CustomAvatar from '../CustomAvatar'

const ApplicationAvatar = ({ rowData, application = rowData }) => {
  const image = buildAppImageUrl(application)

  const avatarImgProps = image ? { image } : { icon: 'pi pi-question' }

  return <CustomAvatar {...avatarImgProps} />
}

export default ApplicationAvatar
