import React, { useMemo } from 'react'
import { inject } from 'mobx-react'
import { GrLanguage } from 'react-icons/gr'
import { FaUserAlt, FaDesktop } from 'react-icons/fa'
import { MdExitToApp } from 'react-icons/md'
import { CgGames } from 'react-icons/cg'

import './SettingsMenu.scss'
import SettingsButton from './SettingsButton'
import { bindClassName } from '../../../../utils/react'
import useToggler from '../../../../hooks/useToggler'
import LanguageSelect from '../../../Language/LanguageSelect'
import useOutsideElementClick from '../../../../hooks/useOutsideElementClick'
import SettingsItem from './SettingsItem'

const ignoreSelectors = ['.settings-btn', '.language-dropdown']

const SettingsMenu = ({ logout }) => {
  const [isOpenMenu, toggleMenu, , close] = useToggler()

  useOutsideElementClick(
    '.common-settings-menu',
    close,
    'body',
    ignoreSelectors,
  )

  const config = useMemo(
    () => [
      {
        labelKey: 'your_profile',
        icon: FaUserAlt,
        onClick: close,
        to: '/profile',
      },
      {
        labelKey: 'desktop_version',
        icon: FaDesktop,
        onClick: close,
        to: '/desktop-version',
      },
      {
        labelKey: 'applications',
        icon: CgGames,
        onClick: close,
        to: '/applications',
      },
      {
        icon: GrLanguage,
        component: LanguageSelect,
      },
      {
        labelKey: 'logout',
        icon: MdExitToApp,
        onClick: logout,
      },
    ],
    [logout, close],
  )

  return (
    <div {...bindClassName('settings-wrapper', [isOpenMenu, 'active'])}>
      <SettingsButton toggleMenu={toggleMenu} />
      <div className="common-settings-menu">
        {isOpenMenu &&
          config.map((item, index) => (
            <SettingsItem {...item} close={close} key={index} />
          ))}
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  logout: stores.AuthStore.logout,
}))

export default withData(SettingsMenu)
