import React, { useMemo } from 'react'
import { Field, Formik, Form } from 'formik'
import * as yup from 'yup'

import Loadable from '../../hoc/Loadable'
import CommonCard from '../../common/CommonCard'
import CustomInput from '../../common/CustomInput'
import CustomTextarea from '../../common/CustomTextarea'
import Configurations from './Configurations'
import ButtonSubmit from '../../buttons/ButtonSubmit'
import useInitialValues from '../../../hooks/form/useInitialValues'
import { DeviceProfileContext } from '../../../context'
import { config } from './config'
import useCommonSubmit from '../../../hooks/form/useCommonSubmit'
import { ErrorMsg } from '../../../constants/errorMsg'
import { MAX_COMMUNITY_DESCRIPTION_LENGTH } from '../../../constants/community'

const gridClassName = 'p-col-12 p-lg-6'

const DeviceProfile = ({
  profile,
  isLoading,
  itemActionType,
  onItemClick,
  className,
  Error,
  FormEntities,
  onSubmit,
  submitLabelKey,
  values,
  shape,
  children,
}) => {
  const { defaultValues, ...rest } = config[itemActionType]

  const mergedDefaultValues = useMemo(
    () => ({ ...defaultValues, ...(values || {}) }),
    [defaultValues, values],
  )

  const initialValues = useInitialValues(mergedDefaultValues, profile)

  const ctx = useMemo(
    () => ({ profile, itemActionType, onItemClick, ...rest }),
    [profile, itemActionType, onItemClick],
  )

  const handleSubmit = useCommonSubmit(profile, onSubmit)

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(ErrorMsg.NAME_REQUIRED),
        desc: yup
          .string()
          .trim()
          .required(ErrorMsg.DESC_REQUIRED)
          .max(
            MAX_COMMUNITY_DESCRIPTION_LENGTH,
            ErrorMsg.COMMUNITY_DESCRIPTION_INVALID,
          ),
        ...(shape || {}),
      }),
    [shape],
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      className={className}
      validationSchema={validationSchema}
    >
      {({ dirty }) => (
        <DeviceProfileContext.Provider value={ctx}>
          <Form className="p-grid">
            {Error && <Error />}
            <Loadable loading={isLoading}>
              <CommonCard
                title="Device profile"
                className="p-grid p-device-card"
              >
                <div className={gridClassName}>
                  <Field
                    labelText="name"
                    name="name"
                    component={CustomInput}
                    isRequired
                  />
                </div>
                <div className={gridClassName}>
                  <Field
                    labelText="description"
                    name="desc"
                    component={CustomTextarea}
                  />
                </div>
                {FormEntities && <FormEntities />}
              </CommonCard>
              <CommonCard
                title="Device configurations"
                className="p-grid p-device-card"
              >
                <Configurations />
              </CommonCard>
              {children}
              <ButtonSubmit
                dirty={dirty}
                isLoading={isLoading}
                submitLabelKey={submitLabelKey}
              />
            </Loadable>
          </Form>
        </DeviceProfileContext.Provider>
      )}
    </Formik>
  )
}

export default DeviceProfile
