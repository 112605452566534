import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { expanderStyle, rowsPerPageOptions } from '../../../constants'
import { formattedAnsweredAt, formattedCreatedAt } from '../../../utils/date'
import { renderIntoTable } from '../../../utils/react'
import ActionBody from './ActionBody'
import Boolean from '../../table/columns/Boolean'
import useExpandableTable from '../../../hooks/table/useExpandableTable'
import ExpandedRow from './ExpandedRow'

const user = ({ User }) => {
  const { id, email } = User || {}
  return User ? (
    <Link to={`/users/user/${id}`}>{email}</Link>
  ) : (
    'user has been deleted'
  )
}

const renderIsProcessed = renderIntoTable(Boolean, {
  field: 'isProcessed',
})
const renderActionBody = renderIntoTable(ActionBody)
const rowExpansionTemplate = renderIntoTable(ExpandedRow)

const View = ({
  limit,
  offset,
  onPage,
  list,
  isLoading,
  count,
  tableHeight,
}) => {
  const { bind } = useExpandableTable()

  return (
    <DataTable
      {...bind}
      rowExpansionTemplate={rowExpansionTemplate}
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No feedback found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      lazy
      scrollable
      scrollHeight={`${tableHeight}px`}
      totalRecords={count}
      onPage={onPage}
    >
      <Column className="expander" expander style={expanderStyle} />
      <Column className="common-td-style" header="User" body={user} />
      <Column className="middle-td-style" field="subject" header="Subject" />
      <Column
        className="middle-td-style"
        header="Processed?"
        body={renderIsProcessed}
      />
      <Column
        className="created-at-style"
        header="Created"
        body={formattedCreatedAt}
      />
      <Column
        className="created-at-style"
        header="Answered"
        body={formattedAnsweredAt}
      />
      <Column
        className="common-td-style"
        header="Actions"
        body={renderActionBody}
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  list: stores.FeedbackStore.list,
  count: stores.FeedbackStore.count,
  isLoading: stores.FeedbackStore.isLoading,
}))

export default withData(observer(View))
