import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CommonFiltersForm, {
  DateRangeField,
} from '../../table/CommonFiltersForm'
import CustomSelect from '../../common/CustomSelect'
import { UserType } from '../../../constants/user'
import useSelectOptions from '../../../hooks/form/useSelectOptions'
import CustomTriStateCheckbox from '../../common/CustomTriStateCheckbox'
import DeviceAndUserFilters, {
  deviceAndUserFiltersIntitalValues,
} from '../../table/DeviceAndUserFilters'
import { makeSelectOptionsFromEnum } from '../../../utils/react'
import { SignInType } from '../../../constants'
import CustomInput from '../../common/CustomInput'

const { model, ...rest } = deviceAndUserFiltersIntitalValues

const signInValues = () => ({ type: null, osPlatform: '' })

const initialValues = {
  dateRange: null,
  ...rest,
  deviceModel: '',
  isDeleted: false,
  signIns: { first: signInValues(), last: signInValues() },
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const signInTypeOptions = makeSelectOptionsFromEnum(SignInType)

const FilterForm = ({
  limit,
  offset,
  isLoading,
  getUsers,
  filterRef,
  getDeviceCategories,
  deviceCategoriesCleanUp,
}) => {
  const typeOptions = useSelectOptions(UserType)

  useEffect(() => {
    getDeviceCategories()

    return deviceCategoriesCleanUp
  }, [])

  return (
    <CommonFiltersForm
      getAll={getUsers}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      <DeviceAndUserFilters className={className} />
      <div className={className}>
        <Field
          name="type"
          placeholder="user_type"
          component={CustomSelect}
          options={typeOptions}
        />
      </div>
      <div className={className}>
        <Field
          name="isDeleted"
          placeholder="isDeleted"
          component={CustomTriStateCheckbox}
        />
      </div>
      <div className={className}>
        <Field
          name="signIns.first.type"
          placeholder="signIns.first_type"
          component={CustomSelect}
          options={signInTypeOptions}
        />
      </div>
      <div className={className}>
        <Field
          name="signIns.first.osPlatform"
          placeholder="signIns.first_platform"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="signIns.last.type"
          placeholder="signIns.last_type"
          component={CustomSelect}
          options={signInTypeOptions}
        />
      </div>
      <div className={className}>
        <Field
          name="signIns.last.osPlatform"
          placeholder="signIns.last_platform"
          component={CustomInput}
        />
      </div>
    </CommonFiltersForm>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.UsersStore.isLoading,
  getUsers: stores.UsersStore.getUsers,
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
}))

export default withData(observer(FilterForm))
