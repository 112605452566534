import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Card } from 'primereact/card'

import { pipe } from '../../../../utils/function'
import { formattedCreatedAt } from '../../../../utils/react'
import Items from '../Items'

const LogDetail = ({ log }) => {
  const config = useMemo(
    () => [
      {
        label: 'Log id',
        key: 'id',
      },
      {
        label: 'Ip',
        key: 'ip',
      },
      {
        label: 'Event log',
        key: 'actionText',
      },
      {
        label: 'Date log',
        key: 'createdAt',
        value: formattedCreatedAt(log),
      },
      {
        label: 'Target id',
        key: 'targetId',
      },
    ],
    [log.createdAt],
  )

  return (
    <Card title="Log detail" className="p-col-12 p-md-6 p-xl-4">
      <Items config={config} entity={log} />
    </Card>
  )
}

const withData = inject((stores) => ({
  log: stores.LogsStore.log,
}))

export default pipe(observer, withData)(LogDetail)
