import React from 'react'

import Item from './Item'

const Items = ({ config, entity }) =>
  config.map(({ label, value, key }) => (
    <Item key={key} label={label} value={value || entity?.[key] || ''} />
  ))

export default React.memo(Items)
