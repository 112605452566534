import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { addListener } from '../../utils/function'
import { CalcTableHeightContext } from '../../context'

const MIN_HEIGHT_SCREEN = 500
const SUM_HEIGHT_HEAD_FOOT_TABLE = 155

export default (Component) => {
  const CalcTableHeight = (props) => {
    const [height, setHeight] = useState(MIN_HEIGHT_SCREEN)
    const wrapperRef = useRef()
    const filterRef = useRef()

    useLayoutEffect(() => {
      const resizeListener = () => {
        if (!wrapperRef.current || !filterRef.current) return

        const tabPanelHeight =
          document.querySelector('.p-tabview-nav')?.clientHeight || 0

        const height =
          wrapperRef.current?.clientHeight -
          filterRef.current?.clientHeight -
          tabPanelHeight -
          SUM_HEIGHT_HEAD_FOOT_TABLE

        if (window.innerHeight > MIN_HEIGHT_SCREEN) {
          setHeight(height)
        }
      }
      resizeListener()
      return addListener(window, 'resize', resizeListener)
    }, [])

    const ctx = useMemo(() => ({ height, wrapperRef, filterRef }), [height])

    return (
      <CalcTableHeightContext.Provider value={ctx}>
        <Component
          {...props}
          height={height}
          wrapperRef={wrapperRef}
          filterRef={filterRef}
        />
      </CalcTableHeightContext.Provider>
    )
  }

  return CalcTableHeight
}
