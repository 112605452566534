import React, { useCallback, useMemo } from 'react'

import { humanizeSize } from '../../../utils/size'
import { instanceOf } from 'prop-types'

const defaultRenderItem = ({ img, fileName, size, removeIcon }) => (
  <>
    {img}
    {fileName}
    {size}
    {removeIcon}
  </>
)

const Item = ({ file, handleRemove, renderItem = defaultRenderItem }) => {
  const { originalname, imageUrl, name = originalname, size, type } = file

  const remove = useCallback(
    (e) => {
      e.stopPropagation()
      handleRemove(file)
    },
    [handleRemove, file],
  )

  const src = useMemo(
    () =>
      imageUrl ||
      (type?.startsWith('image/') &&
        file instanceof File &&
        window.URL.createObjectURL(file)),
    [file, imageUrl],
  )

  const formattedSize = useMemo(() => humanizeSize(size), [size])

  const img = src && (
    <div className="img-wrapper">
      <img src={src} alt={name} />
    </div>
  )

  const fileName = <div className="file-name">{name}</div>
  const iSize = <div className="size">{formattedSize}</div>
  const removeIcon = (
    <div>
      <i className="pi pi-times icon--remove" onClick={remove} />
    </div>
  )

  return (
    <li className="dropzone__list-item">
      {renderItem({ img, fileName, size: iSize, removeIcon, file })}
    </li>
  )
}

export default Item
