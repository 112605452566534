import React from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const ApplyFilters = ({ isLoading, label = 'apply_filters' }) => {
  const { t } = useTranslation()

  return (
    <Button
      className="p-mr-2 p-mb-2 action-btn"
      type="submit"
      label={t(`actions.${label}`)}
      disabled={isLoading}
    />
  )
}

export default React.memo(ApplyFilters)
