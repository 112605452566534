import React from 'react'
import './GamingSetDevice.scss'

const className = 'p-col-12 p-md-5 p-lg-3'

const GamingSetDevice = ({ index, el }) => {
  return (
    <>
      <div className="device-settings-item">
        <div className="p-col-1">
          <span className="device-category">{`${index + 1})`}</span>
        </div>
        <div className={className}>
          <span className="device-category">
            {el.Device?.DeviceCategory?.name}
          </span>
        </div>
        <div className={className}>
          <span className="device-category">{el.Device?.model}</span>
        </div>
      </div>
    </>
  )
}

export default GamingSetDevice
