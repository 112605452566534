import React from 'react'

import './HeadLine.scss'

const Headline = ({ item }) => {
  const { company, model, DeviceCategory } = item || {}

  return (
    <div className="common-device-headline">
      <span className="device-category">{DeviceCategory?.name} </span>
      <span className="device-company">{company}</span>
      <span className="device-model">{model}</span>
    </div>
  )
}

export default Headline
