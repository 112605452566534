import React from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { bindClassName } from '../../../utils/react'
import RenderIf from '../../hoc/RenderIf'

export const style = {
  minWidth: '60px',
}

const CommonActionBody = ({
  successRender = true,
  successLabel,
  successIcon,
  successOnClick,
  successClassName,
  successDisabled,
  dangerRender = true,
  dangerLabel,
  dangerIcon,
  dangerOnClick,
  dangerClassName,
  dangerDisabled,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className="settings-button-block">
      <RenderIf condition={successRender}>
        <Button
          {...bindClassName('tertiary-button success-tertiary-button', [
            successClassName,
          ])}
          type="button"
          label={successLabel && t(`actions.${successLabel}`)}
          icon={successIcon}
          onClick={successOnClick}
          disabled={successDisabled}
        />
      </RenderIf>
      <RenderIf condition={dangerRender}>
        <Button
          {...bindClassName('tertiary-button danger-tertiary-button ml-2', [
            dangerClassName,
          ])}
          type="button"
          style={style}
          label={dangerLabel && t(`actions.${dangerLabel}`)}
          icon={dangerIcon}
          onClick={dangerOnClick}
          disabled={dangerDisabled}
        />
      </RenderIf>
      {children}
    </div>
  )
}

export default CommonActionBody
