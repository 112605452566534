import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { InputText } from 'primereact/inputtext'

import Error from '../../../messages/Error'
import { bindClassName } from '../../../../utils/react'
import useLabel from '../../../../hooks/form/useLabel'

const Value = ({ setValue, dialogProps }) => {
  const { labelKey, value, error } = dialogProps || {}

  const handleChange = useCallback((e) => setValue(e.target.value), [setValue])

  const { label } = useLabel({ labelKey })

  return (
    <div className="value-wrapper">
      <div className="p-grid">
        <div className="p-col-12">
          {label}
          <InputText
            {...bindClassName([!!error, 'p-error'])}
            onChange={handleChange}
            value={value || ''}
          />
        </div>
        <Error errorMsg={error} />
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  dialogProps: stores.ConfirmDialogStore.dialogProps,
  setValue: stores.ConfirmDialogStore.setValue,
}))

export default withData(observer(Value))
