import { curry } from './function'
import { isNil } from './object'

export const filesComparator = (item, _item) => item.path === _item.path
export const unique = (arr, comparator) => {
  if (!comparator) return [...new Set(arr)]
  return arr.filter(
    (item, index) =>
      index ===
      arr.findIndex((_item, _index) => comparator(item, _item, index, _index)),
  )
}

export const mapToArray = (data) => {
  return Array.isArray(data) ? data : !isNil(data) ? [data] : []
}

export const sizeExtractor = (item) => item.size
export const sum = (arr, valueExtractor = (value) => value) =>
  arr.reduce((acc, curr) => acc + +valueExtractor(curr), 0)

export const forkFilter = curry((predicate, arr) => {
  const first = []
  const second = []
  arr.forEach((item, index, arr) => {
    if (predicate(item, index, arr)) first.push(item)
    else second.push(item)
  })
  return [first, second]
})

export const findInCollection = curry(
  (prop, collection, value, strongCompare = false) =>
    collection.find((item) =>
      strongCompare ? item[prop] === value : item[prop] == value,
    ),
)

export const findById = findInCollection('id')

export const findIndexInCollection = curry(
  (prop, collection, value, strongCompare = false) =>
    collection.findIndex((item) =>
      strongCompare ? item[prop] === value : item[prop] == value,
    ),
)

export const findIndexById = findIndexInCollection('id')

export const extract = curry((key, data) => {
  return data.map(({ [key]: value }) => value)
})
export const extractId = extract('id')
