import {
  PromotionFileTypes,
  PromotionMediaResourceType,
  PromotionMediaType,
} from '../../constants/promotions'
import api from '../../configs/api'

export const serializeTargetEmails = (targets) => {
  return (targets || '')
    .split(/,|,\s+|\n+|\n+\s+/)
    .map((v) => (v || '').trim())
    .filter((v) => !!v)
}

export const deserializeTargetEmails = (targets) => (targets || []).join(', ')

export const isMediaLinkRequired = (mediaType, mediaResourceType) => {
  return (
    mediaType !== PromotionMediaType.None &&
    (mediaType === PromotionMediaType.Video ||
      mediaResourceType === PromotionMediaResourceType.Url)
  )
}

export const getMediaLink = ({ id, mediaLink, mediaFileInfo }) => {
  return (
    mediaLink ||
    `${api.devices}/v1/promotions/${id}/${PromotionFileTypes.Images}/${mediaFileInfo?.filename}`
  )
}

export const getYoutubeEmbedUrl = (url) => {
  if (url && typeof url === 'string') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    const name = ((match && match[2]) || '').replace(/('|"|\s).*$/, '')
    if (name.length == 11) return 'https://www.youtube.com/embed/' + name
  }

  return ''
}
