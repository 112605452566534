import { observable, action } from 'mobx'

import { noop } from '../../../utils/function'

class GlobalConfirm {
  @observable isOpen = false
  @observable onConfirm = noop
  @observable props = {}

  @action
  open = (onConfirm, props = {}) => {
    this.onConfirm = onConfirm
    this.isOpen = true
    this.props = props
  }

  @action
  close = () => {
    this.isOpen = false
    this.onConfirm = noop
    this.props = {}
  }
}

export default new GlobalConfirm()
