import React, { useEffect, useMemo } from 'react'
import { useField } from 'formik'
import { inject, observer } from 'mobx-react'

import CustomSelect from '../../common/CustomSelect'
import usePrevious from '../../../hooks/usePrevious'
import RenderIf from '../../hoc/RenderIf'
import { defaultGridClassName } from '../../common/FormFields'

const DeviceProfileId = ({ list, ...rest }) => {
  const [{ value: DeviceCategoryId }] = useField('DeviceCategoryId')
  const [
    { value: DeviceProfileId },
    ,
    { setValue: setDeviceProfileId },
  ] = useField('DeviceProfileId')

  const prev = usePrevious({ DeviceCategoryId })

  useEffect(() => {
    if (
      DeviceCategoryId !== prev.DeviceCategoryId &&
      prev.DeviceCategoryId &&
      DeviceProfileId
    ) {
      setDeviceProfileId(null)
    }
  }, [DeviceCategoryId, setDeviceProfileId, DeviceProfileId])

  const profiles = useMemo(
    () => list.filter((item) => item.DeviceCategoryId == DeviceCategoryId),
    [list, DeviceCategoryId],
  )

  return (
    <RenderIf condition={!!DeviceCategoryId}>
      <div className={defaultGridClassName}>
        <CustomSelect
          options={profiles}
          optionLabel="name"
          optionValue="id"
          {...rest}
        />
      </div>
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  list: stores.DeviceProfilesStore.list,
}))

export default withData(observer(DeviceProfileId))
