import React from 'react'
import { MdSecurity } from 'react-icons/md'

import ResetPasswordEmail from './ResetPasswordEmail'
import TwoFA from './TwoFA'
import ToggleUser from './ToggleUser'
import { UsersSettingsError } from '../../messages/Error'
import CommonCard from '../../common/CommonCard'

const Settings = ({ title }) => (
  <CommonCard
    title={title}
    icon={<MdSecurity />}
    className="user-profile-card p-mx-auto"
  >
    <div className="p-grid">
      <UsersSettingsError />
      <ResetPasswordEmail />
      <TwoFA />
      <ToggleUser />
    </div>
  </CommonCard>
)

export default React.memo(Settings)
