import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../../../constants'
import { useCalcTableHeightContext } from '../../../../../context'
import {
  DeviceKeys,
  deviceKeysLabelsMapping,
} from '../../../../../constants/devices'
import { findInCollection } from '../../../../../utils/array'
import renderStaticCols, {
  statisticsCountClassName,
} from '../../../../../helpers/device-statistics/render-static-cols'

const distance = ({ detailed }) => Math.round(detailed[0].distance || 0) || '-'

const View = ({ limit, offset, onPage, list, isLoading, count }) => {
  const { height: tableHeight } = useCalcTableHeightContext()

  const deviceKeysColumns = useMemo(() => {
    return Object.values(DeviceKeys).map((keyName) => {
      const body = ({ detailed }) => {
        return (
          findInCollection('keyName', detailed[0].keys, keyName)?.count || '-'
        )
      }

      return (
        <Column
          header={deviceKeysLabelsMapping[keyName]}
          field={keyName}
          key={keyName}
          body={body}
          className={statisticsCountClassName}
        />
      )
    })
  }, [])

  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No statistics found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} statistics"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      {renderStaticCols()}
      <Column
        header="Total distance (m)"
        body={distance}
        className={statisticsCountClassName}
      />
      {deviceKeysColumns}
    </DataTable>
  )
}

const withData = inject((stores) => ({
  count: stores.DevicesStatisticsStore.count,
  list: stores.DevicesStatisticsStore.list,
  isLoading: stores.DevicesStatisticsStore.isLoading,
}))

export default withData(observer(View))
