import React from 'react'
import { observer, inject } from 'mobx-react'

import { curry } from '../../../utils/function'
import CustomMessage from '../CustomMessage'

const Error = ({ errorMsg, ...rest }) => (
  <CustomMessage {...rest} msgPath="errors" msg={errorMsg} severity="error" />
)

export const createError = curry((Error, storeName, errorKey = 'errorMsg') => {
  const withData = inject((stores) => ({
    errorMsg: stores[storeName][errorKey],
  }))

  return withData(observer(Error))
})

const _createError = createError(Error)
export const LogsError = _createError('LogsStore')
export const ChangePasswordError = _createError(
  'ProfileStore',
  'passwordErrorMsg',
)
export const UsersError = _createError('UsersStore')
export const UsersSettingsError = _createError('UsersStore', 'settingsErrorMsg')
export const UsersAvatarError = _createError('UsersStore', 'avatarErrorMsg')
export const UsersDeviceCategoriesError = _createError(
  'UsersStore',
  'deviceCategoriesErrorMsg',
)
export const DevicesError = _createError('DevicesStore')
export const DeviceProfilesError = _createError('DeviceProfilesStore')
export const FeedbackError = _createError('FeedbackStore')
export const CommunityError = _createError('CommunityStore')
export const FeedbackAnswerError = _createError(
  'FeedbackStore',
  'answerErrorMsg',
)
export const PromotionCardError = _createError('PromotionCardStore')
export const DesktopApplicationError = _createError('DesktopApplicationStore')
export const DesktopApplicationUploadError = _createError(
  'DesktopApplicationStore',
  'uploadErrorMsg',
)
export const ApplicationPlatformsError = _createError(
  'ApplicationPlatformsStore',
)
export const ApplicationsError = _createError('ApplicationsStore')

export default Error
