import React, { useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import useInitialValues from '../../hooks/form/useInitialValues'
import useCommonSubmit from '../../hooks/form/useCommonSubmit'
import {
  defaultValues,
  validationSchema,
  propsMapping as _propsMapping,
} from './config'
import Loadable from '../hoc/Loadable'
import ButtonSubmit from '../buttons/ButtonSubmit'
import FormFields from '../common/FormFields'
import { ApplicationsError } from '../messages/Error'
import ImageUpload from './ImageUpload'

const ApplicationPage = (props) => {
  const {
    getOne,
    submitOne,
    cleanUp,
    item,
    isImgDirty,
    isLoading,
    platforms,
    getApplicationPlatforms,
    applicationPlatformsCleanUp,
  } = props

  const { id } = useParams()

  useEffect(() => {
    if (id) getOne(id)
    getApplicationPlatforms()

    return () => {
      cleanUp()
      applicationPlatformsCleanUp()
    }
  }, [id])

  const initialValues = useInitialValues(defaultValues, item)
  const onSubmit = useCommonSubmit(item, submitOne)

  const propsMapping = useMemo(() => {
    const result = { ..._propsMapping }
    result.ApplicationPlatformId.options = platforms

    return result
  }, [platforms])

  return (
    <>
      <CustomBreadCrumb model={Model.ApplicationPlatform} />
      <div className="p-fluid page-content-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ dirty }) => (
            <Form className="p-grid p-col-12">
              <Loadable loading={isLoading}>
                <ApplicationsError />
                <ImageUpload />
                <FormFields
                  values={defaultValues}
                  propsMapping={propsMapping}
                  withWrapper={false}
                />
                <ButtonSubmit
                  dirty={dirty || isImgDirty}
                  isLoading={isLoading}
                />
              </Loadable>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  getOne: stores.ApplicationsStore.getOne,
  submitOne: stores.ApplicationsStore.submitOne,
  cleanUp: stores.ApplicationsStore.cleanUp,
  item: stores.ApplicationsStore.item,
  isImgDirty:
    !!stores.ApplicationsStore.imageToUpload ||
    stores.ApplicationsStore.shouldRemoveImage,
  isLoading:
    stores.ApplicationsStore.isLoading ||
    stores.ApplicationPlatformsStore.isLoading,
  platforms: stores.ApplicationPlatformsStore.list,
  getApplicationPlatforms: stores.ApplicationPlatformsStore.getAll,
  applicationPlatformsCleanUp: stores.ApplicationPlatformsStore.cleanUp,
}))

export default withData(observer(ApplicationPage))
