export const PromotionMediaType = {
  Image: 'image',
  Video: 'video',
  None: 'none',
}

export const PromotionMediaResourceType = {
  Url: 'url',
  File: 'file',
}

export const PromotionFileTypes = {
  Images: 'images',
}

export const MIN_PROMOTION_CONTENT_LENGTH = 40
export const MAX_PROMOTION_CONTENT_LENGTH = 500
export const MIN_PROMOTION_SHORT_TEXT_LENGTH = 10
export const MAX_PROMOTION__SHORT_TEXT_LENGTH = 50
export const MIN_PROMOTION_LINK_TEXT_LENGTH = 5
export const MAX_PROMOTION_LINK_TEXT_LENGTH = 35
