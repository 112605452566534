import platform from 'platform'
import i18next from 'i18next'
import { ErrorMsg } from '../constants/errorMsg'
import api from '../configs/api'
import { isNil } from '../utils/object'

export const osPlatformName = 'x-os-platform'
export const languageName = 'x-language'

let csrfToken

if (platform.os.family === 'OS X') {
  platform.os.family = 'Mac ' + platform.os.family
}

const flatten = (obj, parentKey = '') => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const nestedPropStr = [parentKey, key].filter(Boolean).join('.')
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return { ...acc, ...flatten(value, nestedPropStr) }
    }

    return { ...acc, [nestedPropStr]: value }
  }, {})
}

/**
 * @example buildQuery({ a: 2, b:3, c:[1,2,3] }) --> '?a=2&b=3&c=1&c=2&c=3'
 * @example buildQuery({ a: '', b: '   ', c: [] }) --> ''
 * */
export const buildQuery = (queryObj = {}) => {
  const query = Object.entries(flatten(queryObj))
    .filter(([, value = '']) =>
      Array.isArray(value)
        ? !!value.filter((v) => !isNil(v)).length
        : !!(!isNil(value) ? value : '').toString().trim().length,
    )
    .map(([key, value]) => {
      const build = (value) => {
        return `${window.encodeURIComponent(key)}=${window.encodeURIComponent(
          value,
        )}`
      }

      if (!Array.isArray(value)) return build(value)

      return value
        .filter((v) => v !== void 0)
        .map(build)
        .join('&')
    })
    .join('&')

  return query ? `?${query}` : ''
}

export const getFileNameFromContentDisposition = (
  contentDisposition,
  fallback,
) => {
  return (
    contentDisposition.match(/filename=(.*)/)?.[1]?.replace(/"/g, '') ||
    fallback
  )
}

export default class Http {
  static HEADERS = {
    'Content-Type': 'application/json',
    [osPlatformName]: platform.os.toString(),
  }

  static async get(url, config) {
    try {
      return await request(url, 'GET', null, config)
    } catch (e) {
      return e
    }
  }

  static async post(url, data = {}, config) {
    try {
      return await request(url, 'POST', data, config)
    } catch (e) {
      return e
    }
  }

  static async patch(url, data = {}, config) {
    try {
      return await request(url, 'PATCH', data, config)
    } catch (e) {
      return e
    }
  }

  static async put(url, data = {}, config) {
    try {
      return await request(url, 'PUT', data, config)
    } catch (e) {
      return e
    }
  }

  static async delete(url, data = null, config) {
    try {
      return await request(url, 'DELETE', data, config)
    } catch (e) {
      return e
    }
  }
}

async function request(
  url,
  method = 'GET',
  data,
  {
    isJson = true,
    isBlob,
    isText,
    isArrayBuffer,
    isFormData,
    isCacheable = true,
    withCredentials = true,
    passResponse = false,
  } = {},
) {
  const config = {
    method,
    headers: { ...Http.HEADERS },
  }

  config.headers[languageName] = i18next.language
  if (withCredentials) config.credentials = 'include'
  if (csrfToken) config.headers['X-CSRF-Token'] = csrfToken
  if (!isCacheable) config.headers['Cache-Control'] = 'no-cache'

  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
    if (data instanceof FormData) {
      config.body = data
      delete config.headers['Content-Type']
    } else if (data) {
      config.body = JSON.stringify(data)
    }
  }

  try {
    const response = await fetch(url, config)

    const handleResponse = async (response) => {
      if (response.status === 204) return { response }

      const parsed = await (() => {
        switch (true) {
          case isBlob:
            return response.blob()
          case isText:
            return response.text()
          case isJson:
            return response.json()
          case isArrayBuffer:
            return response.arrayBuffer()
          case isFormData:
            return response.formData()
        }
      })()

      return passResponse ? { response, parsed } : parsed
    }

    if (response.ok) return handleResponse(response)

    const parsed = await response.json()
    const { message } = parsed
    if (response.status === 401 && message !== ErrorMsg.EMPTY_TOKEN) {
      try {
        await refreshTokenRequest()
        return fetch(url, config).then(handleResponse)
      } catch (e) {
        document.location.href = '/'
      }
    }
    if (message) parsed.message = message
    return passResponse ? { response, parsed } : parsed
  } catch (e) {
    return Promise.reject(e)
  }
}

const refreshTokenRequest = () => {
  return fetch(`${api.auth}/v1/token/refresh`, {
    credentials: 'include',
    headers: Http.HEADERS,
  })
}

export const csrfTokenRequest = async () => {
  const data = await Http.get(`${api.auth}/v1/csrf-token`, {
    headers: Http.HEADERS,
  })
  csrfToken = data.csrfToken
}
