import React from 'react'
import { inject, observer } from 'mobx-react'

import { Field } from 'formik'

import CommonFiltersForm from '../../table/CommonFiltersForm'
import CustomSelect from '../../common/CustomSelect'
import useSelectOptions from '../../../hooks/form/useSelectOptions'
import { PromotionMediaType } from '../../../constants/promotions'
import CustomTriStateCheckbox from '../../common/CustomTriStateCheckbox'
import { bindClassName } from '../../../utils/react'
import CustomInput from '../../common/CustomInput'
import CustomCalendarInput from '../../common/CustomCalendarInput'

const initialValues = {
  startDate: null,
  endDate: null,
  mediaType: null,
  showTextInTray: null,
  isArchived: false,
  isActive: null,
  content: '',
  shortText: '',
}

const className = 'p-col-12 p-md-6 p-lg-3'
const Filter = ({ getAll, limit, offset, isLoading }) => {
  const typeOptions = useSelectOptions(PromotionMediaType)

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      sLoading={isLoading}
      initialValues={initialValues}
      applyLabel="find"
    >
      <div className={className}>
        <Field
          name="startDate"
          placeholder="start_date"
          component={CustomCalendarInput}
        />
      </div>
      <div className={className}>
        <Field
          name="endDate"
          placeholder="end_date"
          component={CustomCalendarInput}
        />
      </div>
      <div className={className}>
        <Field
          name="mediaType"
          placeholder="media_type"
          component={CustomSelect}
          options={typeOptions}
        />
      </div>
      <div className={className}>
        <Field
          name="content"
          component={CustomInput}
          placeholder="promo_content"
        />
      </div>
      <div className={className}>
        <Field
          name="shortText"
          component={CustomInput}
          placeholder="short_content"
        />
      </div>
      <div {...bindClassName('p-d-flex ml-4', [className])}>
        <Field
          className="mr-4"
          name="isActive"
          labelText="isActive"
          component={CustomTriStateCheckbox}
        />
        <Field
          className="mr-4"
          name="isArchived"
          labelText="archived"
          component={CustomTriStateCheckbox}
        />
        <Field
          name="showTextInTray"
          labelText="show_tray"
          component={CustomTriStateCheckbox}
        />
      </div>
    </CommonFiltersForm>
  )
}

const withData = inject((stores) => ({
  getAll: stores.PromotionsStore.getAll,
  isLoading: stores.PromotionsStore.isLoading,
}))

export default withData(observer(Filter))
