export const Notification = {
  ERROR_OCCURRED: 'ERROR_OCCURRED',

  EXPORT_STARTED: 'EXPORT_STARTED',
  EXPORT_FINISHED: 'EXPORT_FINISHED',

  COPIED: 'COPIED',

  VERIFY_ACCOUNT_EMAIL_SENT: 'VERIFY_ACCOUNT_EMAIL_SENT',
  FAILED_TO_CROP_IMAGE: 'FAILED_TO_CROP_IMAGE',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  NEW_AVATAR_UPLOADED: 'NEW_AVATAR_UPLOADED',
  AVATAR_REMOVED: 'AVATAR_REMOVED',
  RESET_PASSWORD_LINK_SENT: 'RESET_PASSWORD_LINK_SENT',
  FEEDBACK_ANSWERED: 'FEEDBACK_ANSWERED',

  INFO_UPDATED: 'INFO_UPDATED',
  SETTINGS_UPDATED: 'SETTINGS_UPDATED',
  ADMIN_DEVICE_CATEGORIES_UPDATED: 'ADMIN_DEVICE_CATEGORIES_UPDATED',

  USER_REMOVED: 'USER_REMOVED',
  USER_RESTORED: 'USER_RESTORED',
  USER_CREATED: 'USER_CREATED',

  DEVICE_CREATED: 'DEVICE_CREATED',
  DEVICE_UPDATED: 'DEVICE_UPDATED',
  DEVICE_DELETED: 'DEVICE_DELETED',

  DEVICE_PROFILE_CREATED: 'DEVICE_PROFILE_CREATED',
  DEVICE_PROFILE_UPDATED: 'DEVICE_PROFILE_UPDATED',
  DEVICE_PROFILE_DELETED: 'DEVICE_PROFILE_DELETED',

  GAMING_SET_CREATED: 'GAMING_SET_CREATED',
  GAMING_SET_UPDATED: 'GAMING_SET_UPDATED',
  GAMING_SET_DELETED: 'GAMING_SET_DELETED',

  FEEDBACK_MARKED_AS_PROCESSED: 'FEEDBACK_MARKED_AS_PROCESSED',
  FEEDBACK_MARKED_AS_NOT_PROCESSED: 'FEEDBACK_MARKED_AS_NOT_PROCESSED',

  COMMUNITY_PROFILE_DELETED: 'COMMUNITY_PROFILE_DELETED',
  COMMUNITY_PROFILE_LIKED: 'COMMUNITY_PROFILE_LIKED',
  COMMUNITY_PROFILE_UN_LIKED: 'COMMUNITY_PROFILE_UN_LIKED',
  COMMUNITY_PROFILE_DOWNLOADED: 'COMMUNITY_PROFILE_DOWNLOADED',
  COMMUNITY_PROFILE_APPROVED: 'COMMUNITY_PROFILE_APPROVED',
  COMMUNITY_PROFILE_REJECTED: 'COMMUNITY_PROFILE_REJECTED',

  PROMOTION_CREATED: 'PROMOTION_CREATED',
  PROMOTION_UPDATED: 'PROMOTION_UPDATED',
  PROMOTION_DELETED: 'PROMOTION_DELETED',
  PROMOTION_ARCHIVED: 'PROMOTION_ARCHIVED',

  DESKTOP_FILES_UPLOADED: 'DESKTOP_FILES_UPLOADED',
  DESKTOP_FILE_DELETED: 'DESKTOP_FILE_DELETED',

  APPLICATION_CREATED: 'APPLICATION_CREATED',
  APPLICATION_UPDATED: 'APPLICATION_UPDATED',
  APPLICATION_DELETED: 'APPLICATION_DELETED',

  APPLICATION_PLATFORM_CREATED: 'APPLICATION_PLATFORM_CREATED',
  APPLICATION_PLATFORM_UPDATED: 'APPLICATION_PLATFORM_UPDATED',
  APPLICATION_PLATFORM_DELETED: 'APPLICATION_PLATFORM_DELETED',
}
