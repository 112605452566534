import { action, computed, observable } from 'mobx'

import AuthStore from '../Auth/mobx'
import UsersStore from '../UsersPage/mobx'
import Http from '../../helpers/http'
import api from '../../configs/api'
import { ErrorMsg } from '../../constants/errorMsg'
import getCroppedImg from './cropImage'
import { success, error, Notification } from '../../helpers/notifications'

class ProfileStore {
  @observable isPasswordLoading = false
  @observable passwordErrorMsg = null

  @observable uploadingAvatar = null

  @computed get avatarUrl() {
    if (this.uploadingAvatar) {
      return window.URL.createObjectURL(this.uploadingAvatar)
    }

    return UsersStore.currentUserAvatarUrl || null
  }

  @action
  saveAvatar = async () => {
    const { uploadAvatar, setAvatarError } = UsersStore

    if (!this.uploadingAvatar) {
      return setAvatarError(ErrorMsg.USER_AVATAR_HAS_NOT_BEEN_CHANGED)
    }

    await uploadAvatar(this.uploadingAvatar)
    this.reinitAvatarState()
  }

  @action
  removeAvatar = async () => {
    this.uploadingAvatar = null
    if (!UsersStore.currentUserAvatarUrl) return

    await UsersStore.removeAvatar()
    this.reinitAvatarState()
  }

  @action
  setCroppedImage = async (croppedAreaPixels, closeModal) => {
    try {
      const type = this.uploadingAvatar.type
      const croppedImageBlob = await getCroppedImg(
        this.avatarUrl,
        croppedAreaPixels,
        type,
      )
      this.uploadingAvatar = new File(
        [croppedImageBlob],
        this.uploadingAvatar.name,
        {
          type,
          lastModified: Date.now(),
        },
      )
      closeModal()
    } catch (e) {
      error(Notification.FAILED_TO_CROP_IMAGE)
    }
  }

  @action
  setAvatar = (file) => {
    this.uploadingAvatar = file
  }

  @action
  changePassword = async (data, { resetForm }) => {
    const { updateCurrentUserInfo } = AuthStore
    this.isPasswordLoading = true
    const { message = null, result } = await Http.put(
      `${api.auth}/v1/users/current/password`,
      data,
    )
    this.passwordErrorMsg = message
    if (result) {
      updateCurrentUserInfo(result)
      success(Notification.PASSWORD_CHANGED)
      resetForm()
    }
    this.isPasswordLoading = false
  }

  @action
  cleanUp = () => {
    UsersStore.profileCleanUp()
    this.reinitAvatarState()
    this.isPasswordLoading = false
    this.passwordErrorMsg = null
  }

  @action
  reinitAvatarState = () => {
    this.uploadingAvatar = null
    UsersStore.setAvatarError(null)
  }
}

export default new ProfileStore()
