export const DEFAULT_LIMIT = 20

export const body = document.querySelector('body')

export const OAuth = {
  Google: 'google',
  Facebook: 'facebook',
}

export const SignInType = {
  ...OAuth,
  Email: 'email',
}

export const rowsPerPageOptions = [DEFAULT_LIMIT, 50, 100, 150]

export const expanderStyle = { width: '3em' }

export const NOTIFICATION_FAST_TIMEOUT = 750
export const SHORT_NOTIFICATION_TIMEOUT = 2000
export const LONG_NOTIFICATION_TIMEOUT = 3000

export const authHomeRoute = '/profile'
export const notAuthHomeRoute = '/'

export const ProfileDeviceActionType = {
  Delete: 'delete',
  Select: 'select',
}

export const ProfileTab = {
  Avatar: 'avatar',
  Personal: 'personal',
  Password: 'password',
  Security: 'security',
}

export const UserTab = {
  Personal: 'personal',
  Settings: 'settings',
  DeviceCategories: 'device-categories',
}

export const Order = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const tableOrderMapping = {
  1: Order.ASC,
  '-1': Order.DESC,
}
