export const initialValues = {
  byApplication: false,
  ApplicationId: [],
  showTotal: true,
}

export const byApplicationData = [
  { value: false, labelText: 'by_application_total' },
  { value: true, labelText: 'by_application_detailed' },
]
