import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../../../common/CustomInput'
import CommonFiltersForm, {
  DateRangeField,
} from '../../../../table/CommonFiltersForm'
import { useCalcTableHeightContext } from '../../../../../context'

const initialValues = {
  dateRange: null,
  name: '',
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll }) => {
  const { filterRef } = useCalcTableHeightContext()

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      <div className={className}>
        <Field
          type="text"
          name="name"
          placeholder="name"
          component={CustomInput}
        />
      </div>
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.ApplicationPlatformsStore.isLoading,
  getAll: stores.ApplicationPlatformsStore.getAll,
}))

export default withData(observer(FilterForm))
