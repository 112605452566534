import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'

import { pipe } from '../../../../utils/function'
import Item from '../Item'
import RenderIf from '../../../hoc/RenderIf'
import useLabel from '../../../../hooks/form/useLabel'

const ResetPasswordEmail = ({ resetPasswordEmail, isLoading, user }) => {
  const { labelText } = useLabel({ labelKey: 'send_link_email' })

  const { email, isDeleted, isRestorable } = user || {}

  return (
    <RenderIf condition={!!email}>
      <Item label="reset_password_email">
        <Button
          type="button"
          label={labelText}
          onClick={resetPasswordEmail}
          disabled={isLoading || (isDeleted && !isRestorable)}
        />
      </Item>
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  resetPasswordEmail: stores.UsersStore.resetPasswordEmail,
  isLoading: stores.UsersStore.isLoading,
  user: stores.UsersStore.user,
}))

export default pipe(observer, withData)(ResetPasswordEmail)
