import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useLocation, Link } from 'react-router-dom'
import { Button } from 'primereact/button'

import Auth from '../Auth'
import Error from '../messages/Error'
import SuccessMessage from '../messages/SuccessMessage'
import { authHomeRoute, notAuthHomeRoute } from '../../constants'
import useLabel from '../../hooks/form/useLabel'

const CallbackPage = ({ isLogin }) => {
  const { search } = useLocation()

  const { errorMsg, successMsg, ...replacers } = useMemo(() => {
    const obj = {}

    for (const [key, value] of new URLSearchParams(search).entries()) {
      obj[key] = value
    }

    return obj
  }, [search])

  const { labelKey, to } = isLogin
    ? { labelKey: 'go_home', to: authHomeRoute }
    : { labelKey: 'sign_in', to: notAuthHomeRoute }

  const { labelText } = useLabel({ labelKey })

  return (
    <Auth withHeadline={false}>
      <Error errorMsg={errorMsg} replacers={replacers} />
      <SuccessMessage msg={successMsg} replacers={replacers} />
      <div className="p-col-12">
        <Link to={to}>
          <Button label={labelText} />
        </Link>
      </div>
    </Auth>
  )
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
}))

export default withData(observer(CallbackPage))
