import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ForgotPasswordLink = () => {
  const { t } = useTranslation()
  return (
    <div className="p-col-12 p-ml-auto action-link-btn">
      <Link to="/forgot-password">{t('labels.forgot_password')}?</Link>
    </div>
  )
}

export default ForgotPasswordLink
