import React from 'react'
import { inject, observer } from 'mobx-react'

import './CommunityProfileActivity.scss'
import LightningEffectsTag from './LightningEffectsTag'

const CommunityProfileActivity = ({ item }) => {
  const { likes = 0, downloads = 0 } = item || {}

  return (
    <div className="community-profile-activity">
      <div className="community-profile-activity__col">
        <i className="pi pi-thumbs-up" />
        <span className="count">{likes}</span>
      </div>
      <div className="community-profile-activity__col">
        <i className="pi pi-cloud-download" />
        <span className="count">{downloads}</span>
      </div>
      <LightningEffectsTag />
    </div>
  )
}

const withData = inject((stores) => ({
  getIsLiked: stores.CommunityStore.getIsLiked,
  item: stores.CommunityStore.item,
}))

export default withData(observer(CommunityProfileActivity))
