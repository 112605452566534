import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ProgressSpinner } from 'primereact/progressspinner'

import './utils/yup-expansion'
import stores from './stores'
import App from './App'
import initI18n from './i18n'

void initI18n()

ReactDOM.render(
  <Suspense fallback={<ProgressSpinner />}>
    <Provider {...stores}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root'),
)
