import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

import './SecondaryButton.scss'
import { bindClassName } from '../../../utils/react'
import Condition, { If, Else } from '../../hoc/Condition'
import PageActionsWrapper from '../../common/PageActionsWrapper'
import useRedirect from '../../../hooks/useRedirect'

const SecondaryButton = ({
  labelKey,
  to,
  isAbsolute = true,
  className,
  overrideClassName = 'secondary-btn',
  onClick,
  ...props
}) => {
  const { t } = useTranslation()

  const label = labelKey ? t(`actions.${labelKey}`) : void 0

  const redirect = useRedirect(to)

  const handleClick = useCallback(
    (e) => {
      if (to) {
        redirect()
      } else if (onClick) {
        onClick(e)
      }
    },
    [redirect, to, onClick],
  )

  const button = (
    <Button
      {...bindClassName('btn', [overrideClassName], [className])}
      label={label}
      onClick={handleClick}
      {...props}
    />
  )

  return (
    <Condition condition={isAbsolute}>
      <If>
        <PageActionsWrapper>{button}</PageActionsWrapper>
      </If>
      <Else>{button}</Else>
    </Condition>
  )
}

export default SecondaryButton
