import React from 'react'
import { inject, observer } from 'mobx-react'

import Item from './Item'

import './DeviceDocumentation.scss'
import AddItemGroup from './AddItemGroup'

const DeviceDocumentation = ({ i18n }) => (
  <div className="col-md-12">
    <table className="device-docs-table">
      <thead>
        <tr>
          <th className="text-center first-th-item">Language</th>
          <th className="text-center">Description</th>
          <th className="text-center">Instruction</th>
          <th className="last-th-item" />
        </tr>
      </thead>
      <tbody>
        {i18n.map((item) => (
          <Item key={item.language} item={item} />
        ))}
      </tbody>
    </table>
    <AddItemGroup />
  </div>
)

const withData = inject((stores) => ({
  i18n: stores.DevicesStore.i18n,
}))

export default withData(observer(DeviceDocumentation))
