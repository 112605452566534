import { observable, action } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { findIndexById } from '../../utils/array'
import { success, Notification } from '../../helpers/notifications'

class FeedbackStore {
  resource = `${api.devices}/v1/feedback`

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable answerErrorMsg = null
  @observable answerFeedbackId = null

  @action
  getAll = async (fields) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  answerFeedback = async ({ answer }, { resetForm }) => {
    const id = this.answerFeedbackId
    this.isLoading = true
    const {
      message = null,
      result,
    } = await Http.post(`${this.resource}/${id}/answer`, { answer })
    this.answerErrorMsg = message
    if (result) {
      resetForm()

      const idx = findIndexById(this.list, id)
      if (~idx) {
        this.list[idx] = {
          ...this.list[idx],
          ...result,
        }
      }
      this.closeAnswerFeedbackModal()
      success(Notification.FEEDBACK_ANSWERED)
    }

    this.isLoading = false
  }

  @action
  setAnswerFeedbackId = (id) => {
    this.answerFeedbackId = id
  }

  @action
  closeAnswerFeedbackModal = () => {
    this.setAnswerFeedbackId(null)
    this.answerErrorMsg = null
  }

  @action
  setProcessed = async (id, isProcessed) => {
    this.isLoading = true
    const {
      message = null,
      result,
    } = await Http.patch(`${this.resource}/${id}`, { isProcessed })
    this.errorMsg = message
    if (result) {
      const idx = findIndexById(this.list, id)
      if (~idx) {
        this.list[idx] = {
          ...this.list[idx],
          ...result,
        }
      }
      success(
        isProcessed
          ? Notification.FEEDBACK_MARKED_AS_PROCESSED
          : Notification.FEEDBACK_MARKED_AS_NOT_PROCESSED,
      )
    }
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.answerErrorMsg = null
    this.answerFeedbackId = null
  }
}

export default new FeedbackStore()
