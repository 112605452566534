import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { bindClassName } from '../../../../utils/react'
import { CommunityProfileStatus } from '../../../../constants/profiles'

const config = {
  [CommunityProfileStatus.Published]: {
    action: 'publish',
  },
  [CommunityProfileStatus.Rejected]: {
    action: 'reject',
  },
}

const tooltipOptions = {
  position: 'bottom',
}

const ChangeStatusButton = ({
  className,
  id,
  status,
  changeStatus,
  getIsStatusChangeable,
}) => {
  const { t } = useTranslation()
  const isChangeable = getIsStatusChangeable(id)

  const handleChange = useCallback(
    () => isChangeable && changeStatus(id, status),
    [isChangeable, changeStatus, id, status],
  )

  const { action } = config[status]

  return (
    <Button
      {...bindClassName(
        'change-status-group__button tertiary-button profile-status',
        [status],
        [className],
      )}
      label={t(`actions.${action}`)}
      tooltipOptions={tooltipOptions}
      type="button"
      onClick={handleChange}
      disabled={!isChangeable}
    />
  )
}

const withData = inject((stores) => ({
  changeStatus: stores.CommunityStore.changeStatus,
  getIsStatusChangeable: stores.CommunityStore.getIsStatusChangeable,
}))

export default withData(observer(ChangeStatusButton))
