import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const Actions = ({
  isLoading,
  saveAvatar,
  removeAvatar,
  hasUploadingAvatar,
  hasAvatar,
}) => {
  const { t } = useTranslation()

  return (
    <div className="p-col-12 p-md-6">
      <Button
        className="p-mr-2 p-mb-2 p-button-secondary full-width-btn"
        label={t('actions.save')}
        type="button"
        disabled={isLoading || !hasUploadingAvatar}
        onClick={saveAvatar}
      />
      <Button
        className="p-button-secondary full-width-btn"
        type="button"
        label={t('actions.remove')}
        disabled={isLoading || !hasAvatar}
        onClick={removeAvatar}
      />
    </div>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.UsersStore.isLoading,
  saveAvatar: stores.ProfileStore.saveAvatar,
  removeAvatar: stores.ProfileStore.removeAvatar,
  hasUploadingAvatar: !!stores.ProfileStore.uploadingAvatar,
  hasAvatar: !!stores.ProfileStore.avatarUrl,
}))

export default withData(observer(Actions))
