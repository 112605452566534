import { action, observable, computed } from 'mobx'
import moment from 'moment'

import api from '../../configs/api'
import { Notification } from '../../constants/notification'
import Http, { buildQuery } from '../../helpers/http'
import { error, success } from '../../helpers/notifications'
import { findById, findIndexById } from '../../utils/array'
import { pipe } from '../../utils/function'
import ConfirmDialogStore from '../dialogs/CustomConfirmDialog/mobx'
import { formatFilters } from '../../hooks/table/useApplyFilters'

class PromotionsStore {
  resource = `${api.events}/v1/promotions`

  NOTIFICATION_DELETED = Notification.PROMOTION_DELETED
  NOTIFICATION_ARCHIVED = Notification.PROMOTION_ARCHIVED

  @observable isLoading = false
  @observable isFetched = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable promotionViewId = null

  @computed get promotionView() {
    return this.promotionViewId && findById(this.list, this.promotionViewId)
  }

  @action
  unsetPromotionViewId = () => {
    this.setPromotionViewId(null)
  }

  @action
  setPromotionViewId = (id) => {
    this.promotionViewId = id
  }

  @action
  getAll = async (fields) => {
    this.isFetched = false
    this.isLoading = true
    const query = pipe(formatFilters, buildQuery)(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
    this.isFetched = true
  }

  @action
  deleteOne = async (id) => {
    if (!pipe(this.getItem, this.isDeletable)(id)) return

    const { isConfirmed } = await ConfirmDialogStore.open({
      header: 'promotion_delete_header',
      message: 'promotion_delete_message',
    })

    if (!isConfirmed) return

    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      error(response.message, { path: 'errors' })
    } else {
      this.list = this.list.filter((item) => item.id != id)
      success(this.NOTIFICATION_DELETED)
    }
    this.isLoading = false
  }

  @action
  archiveOne = async (id) => {
    if (!pipe(this.getItem, this.isArchivable)(id)) return

    const { isConfirmed } = await ConfirmDialogStore.open({
      header: 'promotion_archive_header',
      message: 'promotion_archive_message',
    })

    if (!isConfirmed) return

    this.isLoading = true
    const { message, result } = await Http.patch(
      `${this.resource}/${id}/archive`,
    )
    if (message) {
      error(message, { path: 'errors' })
    } else {
      const idx = findIndexById(this.list, id)
      if (~idx) {
        this.list[idx] = { ...this.list[idx], ...result }
      }
      success(this.NOTIFICATION_ARCHIVED)
    }
    this.isLoading = false
  }

  getItem = (id) => findById(this.list, id)

  isDeletable = ({ isArchived, startDate }) => {
    return !isArchived && new Date(startDate).getTime() > Date.now()
  }

  isArchivable = ({ isArchived, startDate }) => {
    return !isArchived && new Date(startDate).getTime() <= Date.now()
  }

  isUpdatable = ({ isArchived, endDate }) => {
    return !isArchived || moment(endDate).isSame(new Date(), 'day')
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.isFetched = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.promotionViewId = null
  }
}

export default new PromotionsStore()
