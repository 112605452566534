import React from 'react'
import { useTranslation } from 'react-i18next'

import './PromotionDatesView.scss'
import { dateFormats, formattedDate } from '../../../utils/date'

const startDateFormat = formattedDate(
  'startDate',
  dateFormats.fullDateAndTimeNoSecs,
)
const endDateFormat = formattedDate(
  'endDate',
  dateFormats.fullDateAndTimeNoSecs,
)

const PromotionDatesView = (props) => {
  const { t } = useTranslation()

  return (
    <div className="promotion-active-date">
      {t('labels.promotion_is_active')} {startDateFormat(props)} -{' '}
      {endDateFormat(props)}
    </div>
  )
}

export default PromotionDatesView
