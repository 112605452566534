import { observable, action } from 'mobx'

import Http, {
  buildQuery,
  getFileNameFromContentDisposition,
} from '../../helpers/http'
import api from '../../configs/api'
import { pipe } from '../../utils/function'
import { mapSearchParamsToObj, removePages } from '../../hooks/useSearchParams'
import { notifyErrorOccurred } from '../../helpers/notifications'
import { download } from '../../utils/react'

class LogsStore {
  static resource = `${api.logs}/v1/logs`

  @observable isLoading = false
  @observable isExporting = false
  @observable errorMsg = null
  @observable list = []
  @observable log = null
  @observable count = 0

  @action
  exportData = async () => {
    if (this.isExporting) return
    this.isExporting = true

    const query = pipe(
      mapSearchParamsToObj,
      removePages,
      buildQuery,
    )(window.location.search)

    const { response, message, parsed } = await Http.get(
      `${LogsStore.resource}/export/csv${query}`,
      {
        isBlob: true,
        passResponse: true,
      },
    )

    if (message) {
      notifyErrorOccurred(message)
    } else {
      const name = getFileNameFromContentDisposition(
        response.headers.get('content-disposition'),
        'logs.csv',
      )
      download(name, parsed)
    }

    this.isExporting = false
  }

  @action
  getLogs = async (fields) => {
    const query = buildQuery(fields)
    this.isLoading = true
    const {
      message = null,
      result = [],
      count = 0,
    } = await Http.get(`${LogsStore.resource}${query}`)
    this.errorMsg = message
    if (!message) {
      this.list = result
      this.count = count
    }
    this.isLoading = false
  }

  @action
  getLog = async (id) => {
    this.isLoading = true
    const { message = null, result = null } = await Http.get(
      `${LogsStore.resource}/${id}`,
    )
    this.errorMsg = message
    this.log = result
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.isExporting = false
    this.errorMsg = null
    this.log = null
    this.list = []
    this.count = 0
  }
}

export default new LogsStore()
