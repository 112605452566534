import React, { useEffect } from 'react'
import { inject } from 'mobx-react'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import calcTableHeight from '../hoc/calcTableHeight'
import FilterForm from './FilterForm'
import View from './View'
import UploadGroup from './UploadGroup'

const DesktopVersionPage = ({ cleanUp, wrapperRef, filterRef, height }) => {
  useEffect(() => cleanUp, [])

  return (
    <>
      <CustomBreadCrumb model={Model.DesktopVersion} />
      <div className="p-fluid page-content-wrapper" ref={wrapperRef}>
        <UploadGroup />
        <FilterForm filterRef={filterRef} />
        <View tableHeight={height} />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.DesktopApplicationStore.cleanUp,
}))

export default withData(calcTableHeight(DesktopVersionPage))
