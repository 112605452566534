import React, { useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import PersonalInfo from './PersonalInfo'
import ProfilePhoto from './ProfilePhoto'
import ChangePassword from './ChangePassword'
import SecuritySettings from './SecuritySettings'
import PageTabs from '../PageTabs'
import { ProfileTab } from '../../constants'

const ProfilePage = ({ cleanUp, hasPassword }) => {
  useEffect(() => cleanUp, [])

  const tabs = useMemo(
    () => [
      {
        header: 'profile_photo',
        Component: ProfilePhoto,
        tabName: ProfileTab.Avatar,
      },
      {
        header: 'personal_info',
        Component: PersonalInfo,
        tabName: ProfileTab.Personal,
      },
      {
        key: 'password',
        header: hasPassword ? 'change_your_password' : 'set_your_password',
        Component: ChangePassword,
        tabName: ProfileTab.Password,
      },
      {
        header: 'security_settings',
        Component: SecuritySettings,
        tabName: ProfileTab.Security,
      },
    ],
    [hasPassword],
  )

  return <PageTabs section="profile" tabs={tabs} />
}

const withData = inject((stores) => ({
  cleanUp: stores.ProfileStore.cleanUp,
  hasPassword: stores.AuthStore.user.hasPassword,
}))

export default withData(observer(ProfilePage))
