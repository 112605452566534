import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import ImgPreview from '../../../../../../ImgPreview'

const Item = ({ file: { filename }, id, resource, markImageForRemove }) => {
  const handleRemove = useCallback(() => markImageForRemove(filename), [
    markImageForRemove,
    filename,
  ])

  return (
    <div className="p-col-4" key={filename}>
      <div className="device-images-list__item">
        <div className="img-wrapper">
          <ImgPreview
            src={`${resource}/${id}/images/${filename}`}
            alt={filename}
          />
        </div>
        <i className="pi pi-times icon--remove" onClick={handleRemove} />
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  id: stores.DevicesStore.item?.id,
  resource: stores.DevicesStore.resource,
  markImageForRemove: stores.DevicesStore.markImageForRemove,
}))

export default withData(observer(Item))
