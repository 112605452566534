import React from 'react'
import { inject, observer } from 'mobx-react'

import UploadedBefore from './UploadedBefore'
import Dropzone from '../../../../Dropzone'

const DeviceImages = ({ addImages, removeImage, imagesToUpload }) => (
  <>
    <UploadedBefore />
    <Dropzone
      files={imagesToUpload}
      accept="image/*"
      handleAddFiles={addImages}
      handleRemove={removeImage}
    />
  </>
)

const withData = inject((stores) => ({
  addImages: stores.DevicesStore.addImages,
  removeImage: stores.DevicesStore.removeImage,
  imagesToUpload: stores.DevicesStore.imagesToUpload,
}))

export default withData(observer(DeviceImages))
