import React, { useEffect } from 'react'
import usePagination from '../../hooks/table/usePagination'
import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import SecondaryButton from '../buttons/SecondaryButton'
import FilterForm from './FilterForm'
import View from './View'
import { inject } from 'mobx-react'

const GamingSets = ({
  cleanUp,
  wrapperRef,
  filterRef,
  height,
  getDeviceCategories,
  deviceCategoriesCleanUp,
}) => {
  const { limit, offset, onPage } = usePagination(cleanUp)

  useEffect(() => {
    getDeviceCategories()

    return deviceCategoriesCleanUp
  }, [])

  return (
    <>
      <CustomBreadCrumb model={Model.GamingSets} />
      <div className="p-fluid page-content-wrapper" ref={wrapperRef}>
        <SecondaryButton
          labelKey="create_new_set"
          to="/devices/gaming-sets/set"
        />
        <FilterForm limit={limit} offset={offset} filterRef={filterRef} />
        <View
          limit={limit}
          offset={offset}
          onPage={onPage}
          tableHeight={height}
        />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.DeviceProfilesStore.cleanUp,
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
}))

export default withData(GamingSets)
