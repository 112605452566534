import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import MediaItem from '../../../promotion/MediaItem'
import Links from '../../../promotion/Links'
import PromotionDatesView from '../../../promotion/PromotionDatesView'
import { getMediaLink } from '../../../../helpers/promotions'
import useKeyboard, { KEYBOARD } from '../../../../hooks/useKeyboard'

const ModalContent = ({ unsetPromotionViewId, promotionView }) => {
  const { links, mediaType, content } = promotionView || {}

  const keyboardConfig = useMemo(
    () => [
      {
        keys: KEYBOARD.ESCAPE,
        handler: unsetPromotionViewId,
      },
    ],
    [unsetPromotionViewId],
  )

  useKeyboard(keyboardConfig)

  return (
    <>
      <div className="promotion-view-header">
        <PromotionDatesView {...promotionView} />
        <i className="pi pi-times" onClick={unsetPromotionViewId} />
      </div>
      <div className="common-promotion-info p-grid">
        <div className="promotion-view-media p-col-12 p-md-7">
          <MediaItem
            mediaType={mediaType}
            mediaLink={getMediaLink(promotionView)}
          />
        </div>
        <div className="promotion-view-content p-col-12 p-md-5">{content}</div>
      </div>
      <div className="promotion-view-footer">
        <Links links={links} isLg />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  unsetPromotionViewId: stores.PromotionsStore.unsetPromotionViewId,
  promotionView: stores.PromotionsStore.promotionView,
}))

export default withData(observer(ModalContent))
