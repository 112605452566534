import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Form, Formik } from 'formik'
import { GiSettingsKnobs } from 'react-icons/gi'

import CustomBreadCrumb, { Model } from '../../breadcrumb/CustomBreadCrumb'
import { defaultValues, validationSchema, propsMapping } from './config'
import { DeviceProfilesError } from '../../messages/Error'
import Loadable from '../../hoc/Loadable'
import CommonCard from '../../common/CommonCard'
import ButtonSubmit from '../../buttons/ButtonSubmit'
import useInitialValues from '../../../hooks/form/useInitialValues'
import useCommonSubmit from '../../../hooks/form/useCommonSubmit'
import FormFields from '../../common/FormFields'

const DeviceProfilePage = ({
  getOne,
  cleanUp,
  getDeviceCategories,
  deviceCategoriesCleanUp,
  submitOne,
  item,
  isLoading,
}) => {
  const { id } = useParams()
  const initialValues = useInitialValues(defaultValues, item)
  const onSubmit = useCommonSubmit(item, submitOne)

  useEffect(() => {
    if (id) getOne(id)

    getDeviceCategories()

    return () => {
      cleanUp()
      deviceCategoriesCleanUp()
    }
  }, [id])

  return (
    <>
      <CustomBreadCrumb model={Model.DeviceProfile} />
      <div className="p-fluid page-content-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ dirty }) => (
            <Form className="p-grid p-col-12">
              <DeviceProfilesError />
              <Loadable loading={isLoading}>
                <CommonCard
                  title="Device Profile"
                  icon={<GiSettingsKnobs />}
                  className="p-grid p-device-card"
                >
                  <FormFields
                    values={defaultValues}
                    propsMapping={propsMapping}
                  />
                </CommonCard>
                <ButtonSubmit dirty={dirty} isLoading={isLoading} />
              </Loadable>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  item: stores.DeviceProfilesStore.item,
  isLoading: stores.DeviceProfilesStore._isLoading,
  submitOne: stores.DeviceProfilesStore.submitOne,
  getOne: stores.DeviceProfilesStore.getOne,
  cleanUp: stores.DeviceProfilesStore.cleanUp,
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
}))

export default withData(observer(DeviceProfilePage))
