import React from 'react'
import { inject } from 'mobx-react'

import './PromotionsPage.scss'
import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import SecondaryButton from '../buttons/SecondaryButton'
import Filter from './Filter'
import Pagination from './Pagination'
import usePagination from '../../hooks/table/usePagination'
import PromotionItems from './PromotionItems'
import PromotionView from './PromotionView'

const PromotionsPage = ({ cleanUp }) => {
  const paginationProps = usePagination(cleanUp)

  return (
    <>
      <CustomBreadCrumb model={Model.Promotions} />
      <SecondaryButton to="/promotions/promotion" labelKey="create_promotion" />
      <div className="p-fluid page-content-wrapper promotion-content-wrapper">
        <Filter {...paginationProps} />
        <Pagination className="free-pagination-item" {...paginationProps} />
        <PromotionItems />
        <PromotionView />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.PromotionsStore.cleanUp,
}))

export default withData(PromotionsPage)
