import React from 'react'
import { inject } from 'mobx-react'

import FilterForm from './FilterForm'
import View from './View'
import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import usePagination from '../../hooks/table/usePagination'
import calcTableHeight from '../hoc/calcTableHeight'
import ButtonsGroup from './ButtonsGroup'

const UsersPage = ({ cleanUp, wrapperRef, filterRef, height }) => {
  const { limit, offset, onPage } = usePagination(cleanUp)

  return (
    <>
      <CustomBreadCrumb model={Model.Users} />
      <div className="p-fluid page-content-wrapper" ref={wrapperRef}>
        <ButtonsGroup />
        <FilterForm limit={limit} offset={offset} filterRef={filterRef} />
        <View
          limit={limit}
          offset={offset}
          onPage={onPage}
          tableHeight={height}
        />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.UsersStore.cleanUp,
}))

export default withData(calcTableHeight(UsersPage))
