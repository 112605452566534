import React from 'react'

const Item = ({ label, value }) => (
  <p>
    <b>{label} - </b>
    {value}
  </p>
)

export default React.memo(Item)
