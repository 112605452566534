import React from 'react'

import PageActionsWrapper from '../../common/PageActionsWrapper'
import SecondaryButton from '../../buttons/SecondaryButton'
import Export from './Export'

const ButtonsGroup = () => (
  <PageActionsWrapper>
    <SecondaryButton
      labelKey="add_new_user"
      to="/users/user"
      isAbsolute={false}
    />
    <Export />
  </PageActionsWrapper>
)

export default ButtonsGroup
