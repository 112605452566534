import React from 'react'
import { inject } from 'mobx-react'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import calcTableHeight from '../hoc/calcTableHeight'
import usePagination from '../../hooks/table/usePagination'
import FilterForm from './FilterForm'
import View from './View'
import AnswerModal from './AnswerModal'
import { FeedbackError } from '../messages/Error'

const FeedbackPage = ({ cleanUp, wrapperRef, filterRef, height }) => {
  const { limit, offset, onPage } = usePagination(cleanUp)

  return (
    <>
      <CustomBreadCrumb model={Model.Feedback} />
      <div className="page-content-wrapper" ref={wrapperRef}>
        <FilterForm limit={limit} offset={offset} filterRef={filterRef} />
        <FeedbackError />
        <View
          limit={limit}
          offset={offset}
          onPage={onPage}
          tableHeight={height}
        />
        <AnswerModal />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.FeedbackStore.cleanUp,
}))

export default withData(calcTableHeight(FeedbackPage))
