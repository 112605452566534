import { useState, useCallback } from 'react'

const useExpandableTable = ({ rowExpansionTemplate } = {}) => {
  const [expandedRows, setExpandedRows] = useState(null)

  const onRowToggle = useCallback((e) => setExpandedRows(e.data), [
    setExpandedRows,
  ])

  const bind = { expandedRows, onRowToggle, rowExpansionTemplate }

  return {
    bind,
    setExpandedRows,
    ...bind,
  }
}

export default useExpandableTable
