import * as yup from 'yup'

import DeviceCategoryId from '../../devices/DeviceCategoryId'
import { ErrorMsg } from '../../../constants/errorMsg'
import { createShape, devicesGetErrorKey } from '../../../utils/form'

export const defaultValues = {
  name: '',
  DeviceCategoryId: null,
  sensor: '',
  config: '{}',
}

export const propsMapping = {
  DeviceCategoryId: {
    component: DeviceCategoryId,
  },
  config: {
    labelText: 'default_configuration',
    skip: true,
  },
}

const getErrorMsg = (key) =>
  ErrorMsg[`DEVICE_PROFILE_${devicesGetErrorKey(key)}_NOT_VALID`]

const configError = getErrorMsg('config')

export const validationSchema = yup.object().shape({
  ...createShape(defaultValues, propsMapping, getErrorMsg),
  config: yup.string().test('valid-object', configError, (value) => {
    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  }),
})
