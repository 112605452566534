import React from 'react'
import { inject, observer } from 'mobx-react'

import SecondaryButton from '../../../buttons/SecondaryButton'

const Export = ({ isExporting, exportData }) => (
  <SecondaryButton
    labelKey="export"
    isAbsolute={false}
    disabled={isExporting}
    onClick={exportData}
  />
)

const withData = inject((stores) => ({
  isExporting: stores.LogsStore.isExporting,
  exportData: stores.LogsStore.exportData,
}))

export default withData(observer(Export))
