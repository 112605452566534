import React from 'react'
import { Field, Form } from 'formik'
import Loadable from '../../hoc/Loadable'
import CommonCard from '../../common/CommonCard'
import CustomInput from '../../common/CustomInput'
import CustomTextarea from '../../common/CustomTextarea'
import ButtonSubmit from '../../buttons/ButtonSubmit'
import useToggler from '../../../hooks/useToggler'
import ImportProfileModal from '../ImportProfileModal'
import GamingSetDevicesBlock from './GamingSetDevicesBlock'

const gridClassName = 'p-col-12 p-lg-6'

const FormGroup = ({
  dirty,
  isLoading,
  Error,
  FormEntities,
  submitLabelKey,
  children,
}) => {
  const [isOpen, toggleOpen] = useToggler()
  return (
    <Form className="p-grid">
      {Error && <Error />}
      <Loadable loading={isLoading}>
        <CommonCard title="Gaming Set" className="p-grid p-device-card">
          <div className={gridClassName}>
            <Field
              labelText="name"
              name="name"
              component={CustomInput}
              isRequired
            />
          </div>
          <div className={gridClassName}>
            <Field
              labelText="description"
              name="desc"
              component={CustomTextarea}
            />
          </div>
          {FormEntities && <FormEntities />}
        </CommonCard>
        <GamingSetDevicesBlock toggleOpen={toggleOpen} />
        <ImportProfileModal isOpen={isOpen} toggleOpen={toggleOpen} />
        {children}
        <ButtonSubmit
          dirty={dirty}
          isLoading={isLoading}
          submitLabelKey={submitLabelKey}
        />
      </Loadable>
    </Form>
  )
}

export default FormGroup
