import React from 'react'
import { inject, observer } from 'mobx-react'
import { Paginator } from 'primereact/paginator'

import { rowsPerPageOptions } from '../../../constants'
import { bindClassName } from '../../../utils/react'

const Pagination = ({ limit, offset, onPage, count, className }) => {
  return (
    <div {...bindClassName([className])}>
      <Paginator
        first={offset}
        rows={limit}
        totalRecords={count}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={onPage}
      />
    </div>
  )
}

const withData = inject((stores) => ({
  count: stores.PromotionsStore.count,
}))

export default withData(observer(Pagination))
