import React, { useContext } from 'react'

const createHook = (Context) => () => useContext(Context)

export const DeviceProfileContext = React.createContext()
export const useDeviceProfileContext = createHook(DeviceProfileContext)

export const CommunityProfilesContext = React.createContext()
export const useCommunityProfilesContext = createHook(CommunityProfilesContext)

export const CalcTableHeightContext = React.createContext()
export const useCalcTableHeightContext = createHook(CalcTableHeightContext)

export const GamingSetContext = React.createContext()
export const useGamingSetContext = createHook(GamingSetContext)
