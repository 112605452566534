import React from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../constants'
import { formattedCreatedAt, renderIntoTable } from '../../../utils/react'
import ActionBody from './ActionBody'
import Description from '../../CommunityPage/Profiles/View/Description'
import DeviceItem from './DeviceItem'
import './DeviceItem/DeviceItem.scss'

const renderDeviceCategory = (rowData) => {
  const list = rowData.devices.map((item) => {
    return (
      <DeviceItem
        value={item?.Device?.DeviceCategory?.name}
        key={item?.Device?.id}
      />
    )
  })
  return <div className="set-devise-wrapper">{list}</div>
}

const renderDeviceName = (rowData) => {
  const list = rowData.devices.map((item) => {
    return <DeviceItem value={item?.Device?.model} key={item?.Device?.id} />
  })
  return <div className="set-devise-wrapper">{list}</div>
}

const renderActionBody = renderIntoTable(ActionBody)
const descriptionTemplate = renderIntoTable(Description)

const View = ({
  limit,
  offset,
  onPage,
  list,
  isLoading,
  count,
  tableHeight,
}) => {
  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No gaming sets found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} device profiles"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      <Column className="middle-td-style" field="name" header="Set Name" />
      <Column
        field="desc"
        header="Description"
        className="lg-td-style"
        body={descriptionTemplate}
      />
      <Column
        className="created-at-style"
        header="Created"
        field="createdAt"
        body={formattedCreatedAt}
      />
      <Column
        className="middle-td-style"
        field="DeviceCategory.name"
        header="Devices category"
        body={renderDeviceCategory}
      />
      <Column
        className="middle-td-style"
        field="DeviceCategory.name"
        header="Devices model"
        body={renderDeviceName}
      />
      <Column
        className="common-td-style"
        header="Actions"
        body={renderActionBody}
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  list: stores.GamingSetsStore.list,
  count: stores.GamingSetsStore.count,
  isLoading: stores.GamingSetsStore.isLoading,
}))

export default withData(observer(View))
