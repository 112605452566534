import React, { useCallback } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { inject, observer } from 'mobx-react'

import { selectionLng } from '../../../constants/languages'
import { bindClassName } from '../../../utils/react'

const LanguageSelect = ({
  className,
  language,
  changeLanguage,
  panelClassName,
}) => {
  const handleChange = useCallback(
    (e) => {
      changeLanguage(e.target.value)
    },
    [changeLanguage],
  )

  return (
    <Dropdown
      {...bindClassName([className])}
      panelClassName={
        bindClassName('language-dropdown', [panelClassName]).className
      }
      name="language"
      value={language}
      options={selectionLng}
      onChange={handleChange}
      appendTo={document.body}
    />
  )
}

const withData = inject((stores) => ({
  language: stores.LanguageStore.language,
  changeLanguage: stores.LanguageStore.changeLanguage,
}))

export default withData(observer(LanguageSelect))
