import React, { useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { MdImportantDevices } from 'react-icons/md'
import { Field, Form, Formik } from 'formik'

import CommonCard from '../../common/CommonCard'
import Loadable from '../../hoc/Loadable'
import { UsersDeviceCategoriesError } from '../../messages/Error'
import ButtonSubmit from '../../buttons/ButtonSubmit'
import CustomMultiSelect from '../../common/CustomMultiSelect'
import { extract } from '../../../utils/array'

const DeviceCategories = ({
  title,
  deviceCategories,
  isDeviceCategoriesLoading,
  isLoading,
  user,
  updateAdminDeviceCategories,
}) => {
  const initialValues = useMemo(
    () => ({
      ids: extract('DeviceCategoryId', user?.AdminDeviceCategories || []),
    }),
    [user?.AdminDeviceCategories],
  )

  const onSubmit = useCallback(
    (...args) => updateAdminDeviceCategories(user?.id, ...args),
    [updateAdminDeviceCategories, user?.id],
  )

  const loading = isDeviceCategoriesLoading || isLoading

  return (
    <CommonCard
      title={title}
      icon={<MdImportantDevices />}
      className="user-profile-card p-mx-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty }) => (
          <Form>
            <div className="p-grid p-nogutter">
              <UsersDeviceCategoriesError />
              <Loadable loading={loading}>
                <div className="p-col-12">
                  <Field
                    component={CustomMultiSelect}
                    name="ids"
                    options={deviceCategories}
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <ButtonSubmit dirty={dirty} isLoading={loading} />
              </Loadable>
            </div>
          </Form>
        )}
      </Formik>
    </CommonCard>
  )
}

const withData = inject((stores) => ({
  deviceCategories: stores.DeviceCategoriesStore.list,
  isDeviceCategoriesLoading: stores.DeviceCategoriesStore.isLoading,
  user: stores.UsersStore.user,
  isLoading: stores.UsersStore.isLoading,
  updateAdminDeviceCategories: stores.UsersStore.updateAdminDeviceCategories,
}))

export default withData(observer(DeviceCategories))
