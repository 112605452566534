import React, { useMemo } from 'react'
import GamingSetDevice from '../GamingSetDevice'

const DeviceBlock = ({ GamingSetDevices }) => {
  const arrToRender = GamingSetDevices?.devices

  const deviseBlock = useMemo(() => {
    return arrToRender.map((el, inx) => (
      <GamingSetDevice key={inx} index={inx} el={el} />
    ))
  }, [arrToRender])

  return <div>{deviseBlock}</div>
}

export default DeviceBlock
