import React, { useCallback, useEffect } from 'react'
import { inject } from 'mobx-react'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import Profiles, { publicProfilesConfig } from './Profiles'

const CommunityPage = (props) => {
  const {
    getAll,
    getDeviceCategories,
    deviceCategoriesCleanUp,
    getApplications,
    applicationsCleanUp,
  } = props

  const customizedGetAll = useCallback(
    (fields) => getAll({ ...fields, withUsers: true }),
    [getAll],
  )

  useEffect(() => {
    getDeviceCategories()
    getApplications()

    return () => {
      deviceCategoriesCleanUp()
      applicationsCleanUp()
    }
  }, [])

  return (
    <>
      <CustomBreadCrumb model={Model.Community} />
      <Profiles getAll={customizedGetAll} {...publicProfilesConfig} />
    </>
  )
}

const withData = inject((stores) => ({
  getAll: stores.CommunityStore.getAll,
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
  getApplications: stores.ApplicationsStore.getAll,
  applicationsCleanUp: stores.ApplicationsStore.cleanUp,
}))

export default withData(CommunityPage)
