import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../common/CustomInput'
import { pipe } from '../../../utils/function'
import CommonFiltersForm, {
  DateRangeField,
} from '../../table/CommonFiltersForm'
import CustomSelect from '../../common/CustomSelect'
import { platformLabelsMapping } from '../../../constants/desktop'
import { DesktopApplicationError } from '../../messages/Error'

const initialValues = {
  dateRange: null,
  platform: null,
  version: '',
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const platformOptions = Object.entries(
  platformLabelsMapping,
).map(([value, label]) => ({ label, value }))

const FilterForm = ({ limit, offset, isLoading, getAll, filterRef }) => {
  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
      errorComponent={DesktopApplicationError}
    >
      <DateRangeField className={className} />
      <div className={className}>
        <Field
          type="text"
          name="version"
          placeholder="version"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="platform"
          placeholder="platform"
          component={CustomSelect}
          options={platformOptions}
        />
      </div>
    </CommonFiltersForm>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.DesktopApplicationStore.isLoading,
  getAll: stores.DesktopApplicationStore.getAll,
}))

export default pipe(observer, withData)(FilterForm)
