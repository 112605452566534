import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import CommonActionBody from '../../../table/CommonActionBody'
import { copyTextToClipboard } from '../../../../utils/react'
import { pipe } from '../../../../utils/function'

const ActionBody = ({ id, deleteOne, getDownloadLink }) => {
  const handleCopy = useCallback(() => {
    pipe(getDownloadLink, copyTextToClipboard)(id)
  }, [getDownloadLink, id])

  const handleDownload = useCallback(() => {
    deleteOne(id)
  }, [deleteOne, id])

  return (
    <CommonActionBody
      successOnClick={handleCopy}
      successLabel="copy_link"
      dangerOnClick={handleDownload}
      dangerLabel="delete"
    />
  )
}

const withData = inject((stores) => ({
  getDownloadLink: stores.DesktopApplicationStore.getDownloadLink,
  deleteOne: stores.DesktopApplicationStore.deleteOne,
}))

export default withData(observer(ActionBody))
