import React from 'react'

import './ExpandedRow.scss'
import useLabel from '../../../../hooks/form/useLabel'

const ExpandedRow = ({ rowData: { body } }) => {
  const { labelText } = useLabel({ labelKey: 'body' })

  return (
    <div className="feedback-expanded-row">
      <h3 className="feedback-expanded-row__title">{labelText}</h3>
      <p className="feedback-expanded-row__body">{body}</p>
    </div>
  )
}

export default ExpandedRow
