const api = {
  auth: process.env.REACT_APP_API_DOMAIN_NAME,
  logs: process.env.REACT_APP_API_DOMAIN_NAME,
  devices: process.env.REACT_APP_API_DOMAIN_NAME,
  events: process.env.REACT_APP_API_DOMAIN_NAME,
  desktop: process.env.REACT_APP_API_DOMAIN_NAME,
  statistics: process.env.REACT_APP_API_DOMAIN_NAME,
  applications: process.env.REACT_APP_API_DOMAIN_NAME,
}

export default api
