import Mouse from '../../assets/images/device-categories-defaults/mouse.png'
import Keyboard from '../../assets/images/device-categories-defaults/keyboard.png'

const config = {
  Mouse,
  Keyboard,
  /*Headset,
  "Web-camera":WebCamera,
  Mousepad,
  Bungie,*/
}

export const detectDefaultDeviceImage = (category) => {
  return { filename: config[category] }
}
