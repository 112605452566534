import { observable, action } from 'mobx'

import api from '../../configs/api'
import Http, { csrfTokenRequest } from '../../helpers/http'
import { toRawNumber } from '../common/CustomInputPhone/helpers'
import { isValidEmail } from '../../utils/string'
import { ErrorMsg } from '../../constants/errorMsg'
import { success, Notification } from '../../helpers/notifications'
import Publisher from '../../utils/Publisher'

class AuthStore {
  resource = api.auth

  @observable isInitting = true

  @observable isLoading = false

  @observable user = {}

  @observable isLogin = false

  @observable login = ''

  @observable errorMsg = null

  @observable enabled2fa = false

  @observable enabledSms = false

  @observable geoInfo = {}

  payload = null

  @action
  init = async () => {
    await Promise.all([
      csrfTokenRequest(),
      this.currentAuthenticatedUser(),
      this.sendCurrentCountryLookup(),
    ])
    this.isInitting = false
  }

  @action
  sendEmailVerificationLink = async (email = this.user?.email) => {
    if (email === this.user?.email && this.user?.isVerified) {
      this.errorMsg = ErrorMsg.USER_ACCOUNT_ALREADY_VERIFIED
      return
    }

    const data = this.processAuthData({ email })

    this.isLoading = true
    const response = await Http.post(
      `${this.resource}/v1/auth/email/verify/send`,
      data,
    )
    if (response?.message) {
      this.errorMsg = response.message
    } else {
      success(Notification.VERIFY_ACCOUNT_EMAIL_SENT, { replacers: { email } })
    }
    this.isLoading = false
  }

  @action
  tryAuth = async (data) => {
    data = this.processAuthData(data)
    this.payload = data
    this.isLoading = true
    const response = await Http.post(
      `${this.resource}/v1/admin/auth/login`,
      data,
    )
    this.handleAuthResponse(response)
  }

  @action
  generateSMS2FA = async () => {
    this.isLoading = true
    const response = await Http.post(
      `${this.resource}/v1/2fa/generate-sms`,
      this.payload,
    )
    this.commonHandleResponse(response)
  }

  @action
  verifySMSCode = async (data, history) => {
    const { login, code } = this.processAuthData(data)
    this.isLoading = true
    const response = await Http.post(
      `${this.resource}/v1/reset-password/sms/verify`,
      { phone: login, code },
    )
    this.commonHandleResponse(response, () => {
      history.push(`/change-password/${response.forgotPassToken}`)
    })
  }

  @action
  forgotPassword = async (data, { resetForm } = {}) => {
    const { login } = this.processAuthData(data)
    const isEmail = isValidEmail(login)
    this.isLoading = true

    const response = await (isEmail
      ? this.resetPasswordEmail(login)
      : this.resetPasswordSMS(login))

    this.commonHandleResponse(response, () => {
      this.login = login
      if (isEmail) return resetForm()
      this.enabledSms = true
    })
  }

  @action
  resetPasswordEmail = (email) =>
    Http.post(`${this.resource}/v1/reset-password/email`, { email })

  @action
  resetPasswordSMS = (phone) =>
    Http.post(`${this.resource}/v1/reset-password/sms`, { phone })

  @action
  forgotPasswordResendSMSCode = async () => {
    this.isLoading = true
    const response = await this.resetPasswordSMS(this.login)
    this.commonHandleResponse(response)
  }

  @action
  changePassword = async (data, history) => {
    this.isLoading = true
    const response = await Http.post(
      `${this.resource}/v1/reset-password/approve`,
      data,
    )
    this.commonHandleResponse(response, () => history.push('/'))
  }

  @action
  register = async (data) => {
    data = this.processAuthData(data)
    this.isLoading = true
    const response = await Http.post(`${this.resource}/v1/auth/register`, data)
    this.handleAuthResponse(response)
  }

  @action
  logout = async () => {
    await Http.get(`${this.resource}/v1/token/reject`)
    this.isLogin = false
  }

  @action
  currentAuthenticatedUser = async () => {
    const { result } = await Http.get(
      `${this.resource}/v1/admin/users/current`,
      {
        isCacheable: false,
      },
    )
    if (result) {
      this.userLoggedIn(result)
    } else {
      this.isLogin = false
    }
    return this.isLogin
  }

  @action
  updateCurrentUserInfo = ({ permissionTemplates, ...info }) => {
    if (!info.permissions && permissionTemplates?.permissions)
      info.permissions = permissionTemplates.permissions
    this.user = { ...this.user, ...info }
  }

  @action
  handleAuthResponse(response) {
    const { message, enabled2fa, enabledSms, result } = response
    if (message) {
      this.responseError(response)
    } else if (enabled2fa || enabledSms) {
      this.enabled2fa = enabled2fa
      this.enabledSms = enabledSms
    } else {
      this.payload = null
      this.enabled2fa = false
      this.enabledSms = false
      this.userLoggedIn(result)
    }
    this.isLoading = false
  }

  @action
  userLoggedIn = (user) => {
    this.user = user
    this.isLogin = true
    Publisher.publish(Publisher.Login)
  }

  @action
  commonHandleResponse(response, onSuccess) {
    if (response?.message) return this.responseError(response)

    this.errorMsg = null
    if (onSuccess) onSuccess()
    this.isLoading = false
  }

  @action
  responseError(response) {
    this.errorMsg = response.message
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.errorMsg = null
    this.login = ''
    this.enabled2fa = false
    this.enabledSms = false
    this.payload = null
  }

  @action
  sendCurrentCountryLookup = async () => {
    const { result } = await Http.get(`${this.resource}/v1/geo`)
    if (result) this.geoInfo = result
  }

  checkHierarchy = (target) => {
    const currentUser = this.user

    return (
      currentUser &&
      target &&
      !(
        (target.isAdmin && !currentUser.isSuperAdmin) ||
        (target.isSuperAdmin && !currentUser.isPrimaryAdmin)
      )
    )
  }

  hasPrivilegeOver = (target) => {
    const currentUser = this.user
    return this.checkHierarchy(target) || target.id === currentUser.id
  }

  processAuthData = (data) => {
    const keysToTrim = ['login', 'email', 'phone', 'firstName', 'lastName']
    const segmentedInputKeys = ['code', 'twoFactorAuthenticationCode']

    return Object.entries(data).reduce((acc, [key, value]) => {
      if (keysToTrim.includes(key)) value = (value || '').trim()

      if (key === 'email' || (key === 'login' && value?.includes('@'))) {
        value = (value || '').toLowerCase()
      }

      if (segmentedInputKeys.includes(key)) value = (value || []).join('')

      if (key === 'phone' || (key === 'login' && !isValidEmail(value))) {
        value = toRawNumber(value)
      }

      return { ...acc, [key]: value }
    }, {})
  }
}

export default new AuthStore()
