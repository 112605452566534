import React, { useCallback } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import Condition, { Else, If } from './components/hoc/Condition'
import { pipe } from './utils/function'
import authInitControl from './components/hoc/authInitControl'
import RenderIf from './components/hoc/RenderIf'
import { notAuthHomeRoute } from './constants'

const PrivateRoute = ({
  component: Component,
  isLogin,
  user,
  title,
  ...rest
}) => {
  const render = useCallback(
    (props) => (
      <Condition condition={isLogin && user?.isAdmin}>
        <If>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <RenderIf condition={isLogin}>
            <main className="main-page-wrapper">
              <Component {...props} />
            </main>
          </RenderIf>
        </If>
        <Else>
          <Redirect to={notAuthHomeRoute} />
        </Else>
      </Condition>
    ),
    [isLogin, title, Component],
  )

  return <Route {...rest} render={render} />
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
  user: stores.AuthStore.user,
}))

export default pipe(
  observer,
  withData,
  withRouter,
  authInitControl,
)(PrivateRoute)
