import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, Formik } from 'formik'

import CustomInput from '../../common/CustomInput'
import CustomInputPhone from '../../common/CustomInputPhone'
import { defaultSchema, defaultValues, inputList } from './config'
import { UsersError } from '../../messages/Error'
import AuthError from '../../Auth/Form/Error'
import ButtonSubmit from '../../buttons/ButtonSubmit'
import CountriesSelect from '../../common/CountriesSelect'
import useInitialValues from '../../../hooks/form/useInitialValues'
import useCommonSubmit from '../../../hooks/form/useCommonSubmit'

export * from './config'

const PersonalInfo = ({
  values,
  user,
  children,
  validationSchema = defaultSchema,
  isLoading,
  onSubmit,
  submitLabelKey = 'update',
}) => {
  const inputListRender = inputList.map(({ label, value, ...rest }, i) => (
    <div key={i} className="p-col-12 p-md-6">
      <Field
        type="text"
        name={value}
        labelText={label}
        component={CustomInput}
        {...rest}
      />
    </div>
  ))

  const _defaultValues = useMemo(
    () => ({ ...defaultValues, ...(values || {}) }),
    [values],
  )

  const initialValues = useInitialValues(_defaultValues, user)
  const handleSubmit = useCommonSubmit(user, onSubmit)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty, ...rest }) => (
        <Form>
          <div className="p-grid">
            <UsersError />
            <AuthError />
            {inputListRender}
            <div className="p-col-12 p-md-6">
              <Field
                type="text"
                name="phone"
                labelText="phone_number"
                component={CustomInputPhone}
              />
            </div>
            <div className="p-col-12 p-md-6">
              <Field name="country" component={CountriesSelect} />
            </div>
            {typeof children === 'function' ? children(rest) : children}
            <ButtonSubmit
              submitLabelKey={submitLabelKey}
              dirty={dirty}
              isLoading={isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.UsersStore.isLoading,
}))

export default withData(observer(PersonalInfo))
