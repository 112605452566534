import React from 'react'
import { Field } from 'formik'

import CustomInput from '../../common/CustomInput'
import CustomMultiSelect from '../../common/CustomMultiSelect'
import { mappedCountries } from '../../common/CountriesSelect'
import DeviceCategoryId from '../../devices/DeviceCategoryId'

export const deviceAndUserFiltersIntitalValues = {
  email: '',
  nickname: '',
  country: [],
  DeviceCategoryId: [],
  model: '',
}

const DeviceAndUserFilters = ({ className }) => {
  return (
    <>
      <div className={className}>
        <Field
          type="text"
          name="email"
          placeholder="email"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="nickname"
          placeholder="nickname"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          placeholder="country"
          name="country"
          component={CustomMultiSelect}
          options={mappedCountries}
          filter
          filterBy="label"
        />
      </div>
      <div className={className}>
        <Field
          name="DeviceCategoryId"
          placeholder="DeviceCategoryId"
          component={DeviceCategoryId}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="model"
          placeholder="model"
          component={CustomInput}
        />
      </div>
    </>
  )
}

export default DeviceAndUserFilters
