import React from 'react'

import './Links.scss'
import { bindClassName } from '../../../utils/react'

const Links = ({ links, isLg }) => {
  if (!links?.length) return null

  const [{ to, text } = {}] = links || []

  return (
    <div
      {...bindClassName('promotion-item-link', [
        isLg,
        'promotion-item-link--lg',
      ])}
    >
      <a href={to} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
      <i className="pi pi-arrow-right" />
    </div>
  )
}

export default Links
