import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import { pipe } from '../../../utils/function'
import UserInfo from './UserInfo'
import LogDetail from './LogDetail'
import ActionData from './ActionData'

const LogInfo = ({ getUser, userId }) => {
  useEffect(() => {
    if (userId) getUser(userId)
  }, [userId])

  return (
    <div className="p-fluid p-grid">
      {userId && <UserInfo />}
      <LogDetail hasUser={!!userId} />
      <ActionData />
    </div>
  )
}

const withData = inject((stores) => ({
  userId: stores.LogsStore.log?.userId,
  getUser: stores.UsersStore.getUser,
}))

export default pipe(observer, withData)(LogInfo)
