import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../../../common/CustomInput'
import CommonFiltersForm, {
  DateRangeField,
} from '../../../../table/CommonFiltersForm'
import { useCalcTableHeightContext } from '../../../../../context'
import CustomSelect from '../../../../common/CustomSelect'

const initialValues = {
  dateRange: null,
  name: '',
  ApplicationPlatformId: null,
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll, platforms }) => {
  const { filterRef } = useCalcTableHeightContext()

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      <div className={className}>
        <Field
          type="text"
          name="name"
          placeholder="name"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="ApplicationPlatformId"
          placeholder="platform"
          component={CustomSelect}
          options={platforms}
          optionLabel="name"
          optionValue="id"
        />
      </div>
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.ApplicationsStore.isLoading,
  getAll: stores.ApplicationsStore.getAll,
  platforms: stores.ApplicationPlatformsStore.list,
}))

export default withData(observer(FilterForm))
