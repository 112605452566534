import React, { useCallback } from 'react'
import { MultiSelect } from 'primereact/multiselect'

import { bindClassName } from '../../../utils/react'
import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'

const CustomMultiSelect = ({
  form,
  placeholder,
  field,
  labelText,
  className,
  display = 'chip',
  ...rest
}) => {
  const { options } = rest
  const { error, showError } = useFormError(form, field)

  const { label, _placeholder } = useLabel({
    labelKey: labelText,
    field,
    placeholder,
  })

  const onBlur = useCallback(
    () => form.setFieldTouched(field.name, true, true),
    [form.setFieldTouched, field.name],
  )

  return (
    options.length && (
      <div className="p-grid p-dir-col p-nogutter p-my-2">
        {label}
        <MultiSelect
          {...bindClassName([className], [showError, 'p-error'])}
          resetFilterOnHide
          showClear
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          placeholder={_placeholder}
          onBlur={onBlur}
          display={display}
          {...rest}
        />
        {error}
      </div>
    )
  )
}

export default CustomMultiSelect
