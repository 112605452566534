import React from 'react'
import { inject, observer } from 'mobx-react'

import Confirm from '../Confirm'

const GlobalConfirm = ({ isOpen, close, onConfirm, additionalProps }) => (
  <Confirm
    isOpen={isOpen}
    close={close}
    confirm={onConfirm}
    {...additionalProps}
  />
)

const withData = inject((stores) => ({
  isOpen: stores.GlobalConfirm.isOpen,
  close: stores.GlobalConfirm.close,
  onConfirm: stores.GlobalConfirm.onConfirm,
  additionalProps: stores.GlobalConfirm.props,
}))

export default withData(observer(GlobalConfirm))
