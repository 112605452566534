export const DeviceKeys = {
  Keystroke: 'keystroke',
  Left: 'left',
  Right: 'right',
  Middle: 'middle',
  Scroll: 'scroll',
  Mouse_4_5: 'mouse_4_5',
}

export const deviceKeysLabelsMapping = {
  [DeviceKeys.Keystroke]: 'Keystroke',
  [DeviceKeys.Left]: 'Left clicks',
  [DeviceKeys.Right]: 'Right clicks',
  [DeviceKeys.Middle]: 'Middle clicks',
  [DeviceKeys.Scroll]: 'Scroll',
  [DeviceKeys.Mouse_4_5]: 'Mouse 4/5',
}

export const afkDevicesCommonDays = [30, 60, 90, 180, 360]

export const afkDevicesMaxCommonDay =
  afkDevicesCommonDays[afkDevicesCommonDays.length - 1]
