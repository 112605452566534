import * as yup from 'yup'

import {
  MIN_PROMOTION_CONTENT_LENGTH,
  MAX_PROMOTION_CONTENT_LENGTH,
  MIN_PROMOTION_SHORT_TEXT_LENGTH,
  MAX_PROMOTION__SHORT_TEXT_LENGTH,
  MIN_PROMOTION_LINK_TEXT_LENGTH,
  MAX_PROMOTION_LINK_TEXT_LENGTH,
  PromotionMediaResourceType,
  PromotionMediaType,
} from '../../constants/promotions'
import { ErrorMsg } from '../../constants/errorMsg'
import { isMediaLinkRequired } from '../../helpers/promotions'

export const defaultValues = {
  content: '',
  shortText: '',
  endDate: null,
  startDate: null,
  visualizationTime: 5,
  mediaLink: '',
  mediaType: PromotionMediaType.None,
  mediaResourceType: PromotionMediaResourceType.Url,
  isAllUsers: true,
  showTextInTray: true,
  links: [],
  targets: '',
  hasTargetFiles: false,
}

export const validationSchema = yup.object().shape({
  content: yup
    .string()
    .required(ErrorMsg.PROMOTION_CONTENT_REQUIRED)
    .min(MIN_PROMOTION_CONTENT_LENGTH, ErrorMsg.PROMOTION_CONTENT_INVALID)
    .max(MAX_PROMOTION_CONTENT_LENGTH, ErrorMsg.PROMOTION_CONTENT_INVALID),
  shortText: yup
    .string()
    .min(MIN_PROMOTION_SHORT_TEXT_LENGTH, ErrorMsg.PROMOTION_SHORT_TEXT_INVALID)
    .max(
      MAX_PROMOTION__SHORT_TEXT_LENGTH,
      ErrorMsg.PROMOTION_SHORT_TEXT_INVALID,
    )
    .required(ErrorMsg.PROMOTION_SHORT_TEXT_REQUIRED),
  startDate: yup
    .date()
    .nullable()
    .required(ErrorMsg.PROMOTION_START_DATE_REQUIRED),
  endDate: yup.date().nullable().required(ErrorMsg.PROMOTION_END_DATE_REQUIRED),
  visualizationTime: yup
    .number()
    .required(ErrorMsg.PROMOTION_VISUALIZATION_TIME_REQUIRED)
    .moreThan(0, ErrorMsg.PROMOTION_VISUALIZATION_TIME_INVALID),
  links: yup.array().of(
    yup.object().shape({
      text: yup
        .string()
        .min(
          MIN_PROMOTION_LINK_TEXT_LENGTH,
          ErrorMsg.PROMOTION_LINK_TEXT_INVALID,
        )
        .max(
          MAX_PROMOTION_LINK_TEXT_LENGTH,
          ErrorMsg.PROMOTION_LINK_TEXT_INVALID,
        )
        .required(ErrorMsg.PROMOTION_LINK_TEXT_REQUIRED),
      to: yup
        .string()
        .required(ErrorMsg.PROMOTION_LINK_TO_REQUIRED)
        .httpOrHttps(ErrorMsg.PROMOTION_LINK_TO_INVALID),
    }),
  ),
  targets: yup.string().when(['hasTargetFiles', 'isAllUsers'], {
    is: (hasTargetFiles, isAllUsers) => hasTargetFiles || isAllUsers,
    then: yup
      .string()
      .notRequired()
      .emailList(ErrorMsg.PROMOTION_TARGETS_INVALID),
    otherwise: yup
      .string()
      .required(ErrorMsg.PROMOTION_TARGETS_REQUIRED)
      .emailList(ErrorMsg.PROMOTION_TARGETS_INVALID),
  }),
  mediaLink: yup
    .string()
    .nullable()
    .when(['mediaType', 'mediaResourceType'], {
      is: isMediaLinkRequired,
      then: yup
        .string()
        .nullable()
        .when('mediaType', {
          is: (mediaType) => mediaType === PromotionMediaType.Image,
          then: yup
            .string()
            .nullable()
            .required(ErrorMsg.PROMOTION_MEDIA_LINK_REQUIRED)
            .httpOrHttps(ErrorMsg.PROMOTION_MEDIA_LINK_INVALID),
          otherwise: yup
            .string()
            .nullable()
            .required(ErrorMsg.PROMOTION_MEDIA_LINK_REQUIRED)
            .isYoutubeUrl(ErrorMsg.PROMOTION_MEDIA_LINK_VIDEO_INVALID),
        }),
    }),
})

export const mediaConfig = [
  {
    value: PromotionMediaType.None,
    labelText: 'none',
    className: 'ml-4',
  },
  {
    value: PromotionMediaType.Video,
    labelText: 'video',
    className: 'ml-4',
  },
  {
    value: PromotionMediaType.Image,
    labelText: 'image',
    className: 'ml-4',
  },
]

export const userConfig = [
  {
    value: true,
    labelText: 'all_users',
    className: 'mr-3',
  },
  {
    value: false,
    labelText: 'add_users_list',
  },
]

export const mediaResourceConfig = [
  {
    value: PromotionMediaResourceType.Url,
    labelText: 'url_to_image',
    className: 'mb-3',
  },
  {
    value: PromotionMediaResourceType.File,
    labelText: 'add_image',
  },
]
