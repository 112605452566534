import React from 'react'
import { useTranslation } from 'react-i18next'

import './StatusTemplate.scss'
import { bindClassName } from '../../../utils/react'

const StatusTemplate = ({ status }) => {
  const { t } = useTranslation()

  return (
    <div {...bindClassName('common-status-template profile-status', [status])}>
      {t(`labels.${status}`)}
    </div>
  )
}

export default StatusTemplate
