import React from 'react'

import './CommonModalWindow.scss'
import { bindClassName } from '../../../utils/react'
import RenderIf from '../../hoc/RenderIf'
import CommonHeader from './CommonHeader'
import CommonBody from './CommonBody'

const CommonModalWindow = ({
  isActive,
  children,
  className,
  isMd,
  isLg,
  toggleModal,
  headerKey,
}) => (
  <div
    {...bindClassName(
      'common-modal-wrapper',
      [isActive, 'active'],
      [isMd, 'modal--md'],
      [isLg, 'modal--lg'],
      [className],
    )}
  >
    <div className="common-modal-content">
      <RenderIf condition={isActive}>
        <CommonHeader toggleModal={toggleModal} labelKey={headerKey} />
        <CommonBody>{children}</CommonBody>
      </RenderIf>
    </div>
  </div>
)

export default CommonModalWindow
