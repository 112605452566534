import { useRef, useEffect } from 'react'

const usePrevious = (props) => {
  const ref = useRef(props)
  useEffect(() => {
    ref.current = props
  })
  return ref.current
}

export default usePrevious
