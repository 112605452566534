import { observable, action } from 'mobx'

import Deferred from '../../../helpers/Deferred'

let deferred

class ConfirmDialogStore {
  @observable dialogProps = null

  @action
  accept = () => {
    this.resolve(true)
  }

  @action
  reject = () => {
    this.resolve(false)
  }

  @action
  open = async (dialogProps = {}) => {
    deferred = new Deferred()
    if (dialogProps.isTwoStepped) dialogProps.value = ''
    this.dialogProps = dialogProps
    return deferred.promise
  }

  @action
  showValue = () => {
    this.setProp('isOpen', true)
  }

  @action
  setError = (error) => {
    this.setProp('error', error)
  }

  @action
  setValue = (value) => {
    this.setProp('value', value)
  }

  @action
  setProp = (key, value) => {
    if (!this.dialogProps) return

    this.dialogProps[key] = value
  }

  /**@private*/
  resolve = (isConfirmed) => {
    if (!deferred) return
    const { value = null } = this.dialogProps
    deferred.resolve({ isConfirmed, value })
    deferred = null
    this.dialogProps = null
  }
}

export default new ConfirmDialogStore()
