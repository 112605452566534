import React from 'react'
import { Tooltip } from 'primereact/tooltip'

import './Description.scss'

const Description = ({ rowData: { desc } }) => {
  return (
    <>
      <Tooltip
        target=".description-device-profiles"
        mouseTrack
        mouseTrackLeft={10}
      />
      <div className="description-device-profiles" data-pr-tooltip={desc}>
        {desc}
      </div>
    </>
  )
}

export default Description
