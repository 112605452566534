import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { MdVpnKey } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import CustomInput from '../../common/CustomInput'
import { noPassConfig, withPassConfig } from './config'
import { ChangePasswordError } from '../../messages/Error'
import CommonCard from '../../common/CommonCard'
import RenderIf from '../../hoc/RenderIf'
import { bindClassName } from '../../../utils/react'

const ChangePassword = ({ isLoading, changePassword, hasPassword, title }) => {
  const { t } = useTranslation()

  const { initialValues, validationSchema, submitKey } = hasPassword
    ? withPassConfig
    : noPassConfig

  return (
    <CommonCard
      title={title}
      icon={<MdVpnKey />}
      className="user-profile-card p-mx-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={changePassword}
        enableReinitialize
      >
        {({ dirty, values }) => (
          <Form>
            <div className="p-grid">
              <ChangePasswordError />
              <RenderIf condition={hasPassword && 'currentPassword' in values}>
                <div className="p-col-12 p-md-6">
                  <Field
                    type="password"
                    name="currentPassword"
                    labelText="current_password"
                    component={CustomInput}
                  />
                </div>
              </RenderIf>
              <div className="p-col-12 p-md-6">
                <Field
                  type="password"
                  name="newPassword"
                  labelText="new_password"
                  component={CustomInput}
                />
              </div>
              <div
                {...bindClassName('p-col-12 p-md-6', [
                  hasPassword,
                  'p-md-offset-6',
                ])}
              >
                <Field
                  type="password"
                  name="confirmPassword"
                  labelText="confirm_new_password"
                  component={CustomInput}
                />
              </div>
              <div className="p-col-12">
                <Button
                  type="submit"
                  label={t(`actions.${submitKey}`)}
                  disabled={!(dirty && !isLoading)}
                  className="p-button-secondary"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CommonCard>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.ProfileStore.isPasswordLoading,
  changePassword: stores.ProfileStore.changePassword,
  hasPassword: stores.AuthStore.user.hasPassword,
}))

export default withData(observer(ChangePassword))
