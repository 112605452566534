import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useField } from 'formik'
import { InputText } from 'primereact/inputtext'

import './CustomInputPhone.scss'
import { bindClassName } from '../../../utils/react'
import Selected from './Selected'
import CountriesSelect from './CountriesSelect'
import useToggler from '../../../hooks/useToggler'
import RenderIf from '../../hoc/RenderIf'
import { pipe } from '../../../utils/function'
import usePrevious from '../../../hooks/usePrevious'
import {
  processMask,
  DEFAULT_COUNTRY_ISO,
  detectCountry,
  find,
  toRawNumber,
} from './helpers'
import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'

const CustomInputPhone = ({ geoInfo, form, labelText, field }) => {
  const [, , { setValue }] = useField(field.name)

  const initialValue = useMemo(
    () =>
      field.value
        ? detectCountry(field.value)
        : find(geoInfo?.country || DEFAULT_COUNTRY_ISO) ||
          find(DEFAULT_COUNTRY_ISO),
    [],
  )

  const [countryInfo, setCountryInfo] = useState(initialValue)

  const [isSelectOpen, toggleSelectOpen] = useToggler()
  const { error, showError } = useFormError(form, field)

  const setMask = useCallback((value) => processMask(value, countryInfo.mask), [
    countryInfo.mask,
  ])

  const updateValue = useMemo(() => pipe(setMask, toRawNumber, setValue), [
    setValue,
    setMask,
  ])

  const handleChange = useCallback((e) => updateValue(e.target.value), [
    updateValue,
  ])

  const value = useMemo(() => setMask(field.value), [setMask, field.value])

  const prevProps = usePrevious({ value, mask: countryInfo.mask })

  useEffect(() => {
    if (value !== prevProps.value && countryInfo.mask !== prevProps.mask) {
      updateValue(value)
    }
  }, [updateValue, value, countryInfo.mask])

  const setTouched = useCallback(
    () => form.setFieldTouched(field.name, true, true),
    [form.setFieldTouched, field.name],
  )

  const { label } = useLabel({ labelKey: labelText, htmlFor: field.name })

  return (
    <div className="p-grid p-dir-col p-nogutter p-my-2">
      <div className="custom-input-phone-group">
        {label}
        <div className="input-wrapper">
          <RenderIf condition={isSelectOpen}>
            <CountriesSelect
              selectedCountryInfo={countryInfo}
              toggleSelectOpen={toggleSelectOpen}
              setCountryInfo={setCountryInfo}
            />
          </RenderIf>
          <Selected {...countryInfo} toggleSelectOpen={toggleSelectOpen} />
          <InputText
            {...bindClassName([showError, 'p-error'])}
            name={field.name}
            value={value}
            onChange={handleChange}
            onBlur={setTouched}
          />
        </div>
      </div>
      {error}
    </div>
  )
}

const withData = inject((stores) => ({
  geoInfo: stores.AuthStore.geoInfo,
}))

export default pipe(observer, withData)(CustomInputPhone)
