import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../../../constants'
import { useCalcTableHeightContext } from '../../../../../context'
import { formattedCreatedAt } from '../../../../../utils/date'
import { findById } from '../../../../../utils/array'
import ActionBody from '../../../ActionBody'
import ApplicationsStore from '../mobx'
import { renderIntoTable } from '../../../../../utils/react'
import ApplicationAvatar from '../../../../avatars/ApplicationAvatar'

const renderActionBody = renderIntoTable(ActionBody, {
  Store: ApplicationsStore,
})

const renderAvatar = renderIntoTable(ApplicationAvatar)

const View = ({ limit, offset, onPage, list, isLoading, count, platforms }) => {
  const { height: tableHeight } = useCalcTableHeightContext()

  const platformBody = useCallback(
    ({ ApplicationPlatformId }) => {
      return findById(platforms, ApplicationPlatformId)?.name || ''
    },
    [platforms],
  )

  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No applications found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      <Column header="Image" body={renderAvatar} />
      <Column header="Name" field="name" />
      <Column header="Platform" body={platformBody} />
      <Column header="Id in platform" field="realId" />
      <Column header="Created" field="createdAt" body={formattedCreatedAt} />
      <Column
        body={renderActionBody}
        header="Settings"
        className="common-td-style"
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  count: stores.ApplicationsStore.count,
  list: stores.ApplicationsStore.list,
  platforms: stores.ApplicationPlatformsStore.list,
  isLoading:
    stores.ApplicationsStore.isLoading ||
    stores.ApplicationPlatformsStore.isLoading,
}))

export default withData(observer(View))
