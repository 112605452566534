import React, { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import './Dropzone.scss'
import Item from './Item'
import Error from '../messages/Error'
import { ErrorMsg } from '../../constants/errorMsg'
import RenderIf from '../hoc/RenderIf'
import { validateFiles } from '../../helpers/files/validation'
import DropzoneInvalidFilesException from '../../exceptions/DropzoneInvalidFilesException'
import { bindClassName } from '../../utils/react'

const Dropzone = (props) => {
  const {
    files,
    handleAddFiles,
    handleRemove,
    multiple = true,
    isSingleMode = false,
    renderItem,
    filesValidationData,
    titleKey = 'default',
    ...rest
  } = props

  const [errorState, setErrorState] = useState({})
  const { t } = useTranslation()

  const hasFiles = !!files.length

  const rootProps = useMemo(() => {
    return {
      ...bindClassName(
        'dropzone',
        [isSingleMode, 'single-mode'],
        [hasFiles, 'not-empty'],
      ),
    }
  }, [isSingleMode, hasFiles])

  const hasError = useMemo(() => !!Object.keys(errorState).length, [errorState])

  const onDrop = useCallback(
    (newFiles) => {
      if (filesValidationData) {
        try {
          validateFiles({
            files: newFiles,
            currFiles: files,
            ...(filesValidationData || {}),
          })
        } catch (e) {
          if (e instanceof DropzoneInvalidFilesException) {
            return setErrorState({
              errorMsg: e.message,
              replacers: e.replacers,
            })
          } else {
            return setErrorState({
              errorMsg: ErrorMsg.ERROR_OCCURRED,
              replacers: { error: e.message },
            })
          }
        }
      }

      if (hasError) setErrorState({})

      handleAddFiles(newFiles)
    },
    [handleAddFiles, hasError, files, filesValidationData],
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop, ...rest })

  return (
    <>
      <div {...getRootProps(rootProps)}>
        <input {...getInputProps()} />
        <RenderIf condition={multiple || !hasFiles}>
          <h4 className="dropzone__message">{t(`dropzone.${titleKey}`)}</h4>
        </RenderIf>
        <RenderIf condition={hasFiles}>
          <ul className="dropzone__list">
            {files.map((file) => (
              <Item
                key={file.path || file.name || file.filename}
                file={file}
                handleRemove={handleRemove}
                renderItem={renderItem}
              />
            ))}
          </ul>
        </RenderIf>
      </div>
      <Error {...errorState} />
    </>
  )
}

export default Dropzone
