import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'

import Item from '../Item'
import RenderIf from '../../../hoc/RenderIf'
import useLabel from '../../../../hooks/form/useLabel'

const ToggleUser = ({
  user,
  isLoading,
  toggleUser,
  isUserDeletable,
  currentUser,
}) => {
  user = user || {}
  const { isDeleted, isRestorable } = user
  const toggle = useCallback(() => toggleUser(), [toggleUser])

  const isDeletable = currentUser.id !== user.id && isUserDeletable(user)

  const { labelText } = useLabel({
    labelKey: isDeleted ? 'restore_user' : 'delete_user',
  })

  return (
    <RenderIf condition={isDeletable && (!isDeleted || isRestorable)}>
      <Item label={labelText}>
        <Button
          type="button"
          label={labelText}
          onClick={toggle}
          disabled={isLoading}
          className="p-button-danger"
        />
      </Item>
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.UsersStore.isLoading,
  toggleUser: stores.UsersStore.toggleUser,
  user: stores.UsersStore.user,
  isUserDeletable: stores.UsersStore.isUserDeletable,
  currentUser: stores.AuthStore.user,
}))

export default withData(observer(ToggleUser))
