import React from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const PreviewBtn = ({ onclick }) => {
  const { t } = useTranslation()
  return (
    <Button
      className="tertiary-button success-tertiary-button ml-2"
      type="button"
      label={t(`actions.preview`)}
      onClick={onclick}
    />
  )
}

export default PreviewBtn
