import React from 'react'
import { inject, observer } from 'mobx-react'

import './CommunityProfileStatus.scss'
import CommonCard from '../../common/CommonCard'
import StatusTemplate from '../../devices/StatusTemplate'
import ChangeStatus from '../../devices/ChangeStatus'
import RenderIf from '../../hoc/RenderIf'
import { CommunityProfileStatus as CommunityProfileStatusEnum } from '../../../constants/profiles'

const CommunityProfileStatus = ({ item }) => {
  const { id, status } = item || {}
  const isPublished = status !== CommunityProfileStatusEnum.Published

  return (
    <CommonCard
      title="Status"
      className="p-grid p-device-card community-profile-status"
    >
      <div className="mr-5">
        <StatusTemplate status={status} />
      </div>
      <RenderIf condition={isPublished}>
        <div>
          <ChangeStatus id={id} />
        </div>
      </RenderIf>
    </CommonCard>
  )
}

const withData = inject((stores) => ({
  item: stores.CommunityStore.item,
}))

export default withData(observer(CommunityProfileStatus))
