import React, { useMemo } from 'react'
import { MdClear } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import RenderIf from '../../hoc/RenderIf'
import useKeyboard, { KEYBOARD } from '../../../hooks/useKeyboard'

const CommonHeader = ({ icon, toggleModal, labelKey }) => {
  const { t } = useTranslation()

  const label = t(`modals.${labelKey}`)

  const keyboardConfig = useMemo(
    () => [
      {
        keys: KEYBOARD.ESCAPE,
        handler: toggleModal,
      },
    ],
    [toggleModal],
  )

  useKeyboard(keyboardConfig)

  return (
    <div className="common-modal-header p-grid">
      <div className="p-card-title p-grid p-dir-row">
        <RenderIf condition={icon}>
          <div className="p-mb-3">{icon}</div>
        </RenderIf>
        <span>{label}</span>
      </div>
      <button
        className="p-button-icon-only button-close-dialog"
        onClick={toggleModal}
      >
        <MdClear />
      </button>
    </div>
  )
}

export default CommonHeader
