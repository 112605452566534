import React from 'react'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AlternativeSignLink = () => {
  const { t } = useTranslation()

  return (
    <Link className="p-col-12 p-d-flex p-mb-3" to="/">
      <Button
        type="button"
        label={t('labels.remembered_password') + '? ' + t('labels.sign_in')}
        className="secondary-btn full-width-btn"
      />
    </Link>
  )
}
export default AlternativeSignLink
