import React from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../../../constants'
import { dateFormats, formattedDate } from '../../../../../utils/date'
import { useCalcTableHeightContext } from '../../../../../context'

const formattedStartDate = formattedDate(
  'startDate',
  dateFormats.fullDateAndTimeNoSecs,
)
const formattedEndDate = formattedDate(
  'endDate',
  dateFormats.fullDateAndTimeNoSecs,
)

const View = ({ limit, offset, onPage, list, isLoading, count }) => {
  const { height: tableHeight } = useCalcTableHeightContext()

  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No statistics found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} statistics"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      <Column
        className="common-td-style"
        field="shortText"
        header="Short Text"
      />
      <Column
        className="created-at-style"
        field="history"
        header="Start Date"
        body={formattedStartDate}
      />
      <Column
        className="created-at-style"
        field="history"
        header="End Date"
        body={formattedEndDate}
      />
      <Column
        className="middle-td-style"
        field="delivered"
        header="Delivered"
      />
      <Column className="middle-td-style" field="opened" header="Opened" />
      <Column
        className="middle-td-style"
        field="clicked"
        header="Clicked on link"
      />
      <Column
        className="middle-td-style"
        field="totalTargets"
        header="Total users"
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  count: stores.PromotionsStatisticsStore.count,
  list: stores.PromotionsStatisticsStore.list,
  isLoading: stores.PromotionsStatisticsStore.isLoading,
}))

export default withData(observer(View))
