import { observable, action } from 'mobx'
import i18next from 'i18next'
import Publisher from '../../utils/Publisher'
import AuthStore from '../Auth/mobx'
import UsersStore from '../UsersPage/mobx'

class LanguageStore {
  @observable language = i18next.language

  constructor() {
    i18next.on('languageChanged', (lng) => {
      this.language = lng
    })

    Publisher.subscribe(Publisher.Login, () => {
      const { language } = AuthStore.user
      void this.changeLanguage(language)
    })
  }

  @action
  changeLanguage = async (language) => {
    if (language === this.language) return

    await Promise.all([
      i18next.changeLanguage(language),
      UsersStore.setCurrentUserLanguage(language),
    ])
  }
}

export default new LanguageStore()
