import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import useRedirect from '../../../hooks/useRedirect'
import CommonActionBody from '../../table/CommonActionBody'

const ActionBody = ({ rowData, Store, open }) => {
  const { id } = rowData
  const redirect = useRedirect(Store.getItemUrl(rowData))

  const handleDelete = useCallback(() => open(() => Store.deleteOne(id)), [
    open,
    id,
  ])

  return (
    <CommonActionBody
      successLabel="edit"
      successOnClick={redirect}
      dangerLabel="delete"
      dangerOnClick={handleDelete}
    />
  )
}

const withData = inject((stores) => ({
  open: stores.GlobalConfirm.open,
}))

export default withData(observer(ActionBody))
