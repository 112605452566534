import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'

import { pipe } from '../../../../utils/function'
import Item from '../Item'
import useLabel from '../../../../hooks/form/useLabel'

const TwoFA = ({ disable2fa, isLoading, enabled2fa, enabledSms }) => {
  const { labelText } = useLabel({ labelKey: 'disable' })

  return (
    <Item label="2fa">
      <Button
        type="button"
        label={labelText}
        onClick={disable2fa}
        disabled={isLoading || (!enabled2fa && !enabledSms)}
      />
    </Item>
  )
}

const withData = inject(({ UsersStore }) => ({
  disable2fa: UsersStore.disable2fa,
  isLoading: UsersStore.isLoading,
  enabled2fa: UsersStore.user?.enabled2fa,
  enabledSms: UsersStore.user?.enabledSms,
}))

export default pipe(observer, withData)(TwoFA)
