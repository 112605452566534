import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { inject, observer } from 'mobx-react'

import ExpandedRow from './ExpandedRow'
import { expanderStyle, rowsPerPageOptions } from '../../../../constants'
import { renderIntoTable } from '../../../../utils/react'
import useExpandableTable from '../../../../hooks/table/useExpandableTable'
import { useCommunityProfilesContext } from '../../../../context'
import { columns } from '../config'

const rowExpansionTemplate = renderIntoTable(ExpandedRow)

const View = ({
  limit,
  offset,
  onPage,
  onSort,
  sortField,
  sortOrder,
  list,
  isLoading,
  count,
  tableHeight,
}) => {
  const { bind } = useExpandableTable({ rowExpansionTemplate })
  const { columnList, columnPropsMapping } = useCommunityProfilesContext()

  return (
    <div>
      <div className="card">
        <DataTable
          {...bind}
          onSort={onSort}
          onPage={onPage}
          sortField={sortField}
          sortOrder={sortOrder}
          first={offset}
          rows={limit}
          removableSort
          value={list}
          dataKey="id"
          className="datatable"
          rowHover
          paginator
          emptyMessage="No profiles found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={rowsPerPageOptions}
          loading={isLoading}
          lazy
          scrollable
          scrollHeight={`${tableHeight}px`}
          totalRecords={count}
        >
          <Column className="expander" expander style={expanderStyle} />
          {columnList.map((column) =>
            columns[column]({
              key: column,
              ...(columnPropsMapping[column] || {}),
            }),
          )}
        </DataTable>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  list: stores.CommunityStore.list,
  getOne: stores.CommunityStore.getOne,
  item: stores.CommunityStore.item,
  count: stores.CommunityStore.count,
  isLoading: stores.CommunityStore.isLoading,
}))

export default withData(observer(View))
