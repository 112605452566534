import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import Empty from '../Empty'
import CommonInfo from './CommonInfo'
import MediaContent from './MediaContent'
import UserTarget from './UserTarget'
import { defaultValues, validationSchema } from './config'
import { PromotionCardError } from '../messages/Error'
import Loadable from '../hoc/Loadable'
import ButtonSubmit from '../buttons/ButtonSubmit'
import useInitialValues from '../../hooks/form/useInitialValues'
import useCommonSubmit from '../../hooks/form/useCommonSubmit'

const PromotionPage = ({ item, submitOne, isLoading, getOne, cleanUp }) => {
  const { id } = useParams()

  useEffect(() => {
    if (id) getOne(id)

    return cleanUp
  }, [id])

  const initialValues = useInitialValues(defaultValues, item)
  const onSubmit = useCommonSubmit(item, submitOne)

  return (
    <>
      <CustomBreadCrumb model={Model.Promotion} />
      <div className="p-fluid page-content-wrapper promotion-content-wrapper">
        <Empty
          className="common-pages-title-info"
          labelKey={id ? 'update_promotion' : 'new_promotion'}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty }) => (
            <Form>
              <Loadable loading={isLoading}>
                <PromotionCardError />
                <MediaContent />
                <CommonInfo />
                <UserTarget />
                <ButtonSubmit
                  submitLabelKey={id ? 'update' : 'create'}
                  dirty={dirty}
                  isLoading={isLoading}
                />
              </Loadable>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const withData = inject((store) => ({
  item: store.PromotionCardStore.item,
  isLoading: store.PromotionCardStore.isLoading,
  submitOne: store.PromotionCardStore.submitOne,
  getOne: store.PromotionCardStore.getOne,
  cleanUp: store.PromotionCardStore.cleanUp,
}))

export default withData(observer(PromotionPage))
