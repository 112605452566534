import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../../common/CustomInput'
import CommonFiltersForm, {
  DateRangeField,
} from '../../../table/CommonFiltersForm'
import { useCommunityProfilesContext } from '../../../../context'
import DeviceCategoryId from '../../../devices/DeviceCategoryId'
import { ApplicationsSelect } from '../../../applications'

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({
  limit,
  offset,
  sortField,
  sortOrder,
  isLoading,
  filterRef,
}) => {
  const { getAll, Filters, values } = useCommunityProfilesContext()

  const initialValues = useMemo(
    () => ({
      dateRange: null,
      name: '',
      desc: '',
      DeviceCategoryId: null,
      deviceModel: '',
      ApplicationId: null,
      ...(values || {}),
    }),
    [values],
  )

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      sortField={sortField}
      sortOrder={sortOrder}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      <div className={className}>
        <Field
          type="text"
          name="name"
          placeholder="name"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="desc"
          placeholder="description"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="DeviceCategoryId"
          placeholder="DeviceCategoryId"
          component={DeviceCategoryId}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="deviceModel"
          placeholder="model"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="ApplicationId"
          placeholder="application"
          component={ApplicationsSelect}
        />
      </div>
      {Filters && <Filters gridClassName={className} />}
    </CommonFiltersForm>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.CommunityStore.isLoading,
}))

export default withData(observer(FilterForm))
