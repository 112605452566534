import React from 'react'
import { inject, observer } from 'mobx-react'

import PromotionItem from './PromotionItem'
import Loadable from '../../hoc/Loadable'
import Condition, { If, ElseIf } from '../../hoc/Condition'
import Empty from '../../Empty'

const PromotionItems = ({ list, isLoading, isFetched }) => (
  <Loadable loading={isLoading}>
    <Condition condition={!!list.length}>
      <If>
        <div className="common-promotion-body">
          <div className="grid-promotion">
            {list.map((promotion) => (
              <PromotionItem key={promotion.id} promotion={promotion} />
            ))}
          </div>
        </div>
      </If>
      <ElseIf condition={isFetched}>
        <Empty labelKey="no_promotions_found" className="center" />
      </ElseIf>
    </Condition>
  </Loadable>
)

const withData = inject((stores) => ({
  list: stores.PromotionsStore.list,
  isLoading: stores.PromotionsStore.isLoading,
  isFetched: stores.PromotionsStore.isFetched,
}))

export default withData(observer(PromotionItems))
