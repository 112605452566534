import React, { useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { MdPersonPin } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'

import UserPersonalInfo, {
  createValidationSchema,
  defaultSchema,
} from '../../user/PersonalInfo'
import CommonCard from '../../common/CommonCard'
import IsAdmin from './IsAdmin'
import IsSuperAdmin from './IsSuperAdmin'
import RenderIf from '../../hoc/RenderIf'
import AuthForm, { confirmPasswordShape, passwordShape } from '../../Auth/Form'

const validationSchema = createValidationSchema({
  ...passwordShape,
  ...confirmPasswordShape,
})

const PersonalInfo = ({
  user,
  updateUserInfo,
  title,
  currentUser,
  addUser,
}) => {
  const { id } = useParams()
  const history = useHistory()

  const onSubmit = useCallback(
    (values, helpers) => {
      if (id) {
        updateUserInfo(values, helpers)
      } else {
        addUser(values, history)
      }
    },
    [updateUserInfo, addUser, history, id],
  )

  const values = useMemo(
    () => ({
      isAdmin: user?.isAdmin || false,
      isSuperAdmin: user?.isSuperAdmin || false,
      ...(!id
        ? {
            password: '',
            confirmPassword: '',
          }
        : {}),
    }),
    [user, id],
  )

  const disabled = user?.isPrimaryAdmin

  return (
    <CommonCard
      title={title}
      icon={<MdPersonPin />}
      className="user-profile-card p-mx-auto"
    >
      <UserPersonalInfo
        user={user}
        onSubmit={onSubmit}
        values={values}
        validationSchema={id ? defaultSchema : validationSchema}
        submitLabelKey={id ? 'update' : 'create'}
      >
        {({ setFieldValue }) => (
          <>
            <RenderIf condition={!id}>
              <AuthForm.Password className="p-col-12 p-md-6" />
              <AuthForm.Password
                name="confirmPassword"
                labelText="confirm_password"
                className="p-col-12 p-md-6"
              />
            </RenderIf>
            <RenderIf condition={currentUser.isSuperAdmin}>
              <div className="p-col-12 p-md-6 p-flex p-dir-col p-justify-end">
                <IsAdmin setFieldValue={setFieldValue} disabled={disabled} />
                <RenderIf condition={currentUser.isPrimaryAdmin}>
                  <IsSuperAdmin
                    setFieldValue={setFieldValue}
                    disabled={disabled}
                  />
                </RenderIf>
              </div>
            </RenderIf>
          </>
        )}
      </UserPersonalInfo>
    </CommonCard>
  )
}

const withData = inject((stores) => ({
  user: stores.UsersStore.user,
  currentUser: stores.AuthStore.user,
  updateUserInfo: stores.UsersStore.updateUserInfo,
  addUser: stores.UsersStore.addUser,
}))

export default withData(observer(PersonalInfo))
