import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'

import CustomBreadCrumb, { Model } from '../../breadcrumb/CustomBreadCrumb'
import { deviceCards } from './config'

const DevicesPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <CustomBreadCrumb model={Model.Devices} />
      <div className="p-fluid p-grid common-content-wrapper">
        {deviceCards.map(({ url, labelKey, icon: Icon }) => (
          <Link
            to={url}
            className="p-col-12 p-md-6 p-xl-4 p-xxl-3 common-device-item-wrapper"
            key={url}
          >
            <Card
              title={t(`devices.${labelKey}`)}
              className="common-device-card"
            >
              {Icon && <Icon />}
            </Card>
          </Link>
        ))}
      </div>
    </>
  )
}

export default DevicesPage
