import React from 'react'
import { inject, observer } from 'mobx-react'

import { renderIntoTable } from '../../../../utils/react'

const FileName = ({ id, fileInfo, getDownloadLink }) => {
  const { originalname } = fileInfo || {}

  return (
    <a
      href={getDownloadLink(id)}
      target="_blank"
      rel="noopener noreferred"
      download={originalname}
    >
      {originalname}
    </a>
  )
}

const withData = inject((stores) => ({
  getDownloadLink: stores.DesktopApplicationStore.getDownloadLink,
}))

const Enhanced = withData(observer(FileName))

export const renderFileName = renderIntoTable(Enhanced)

export default Enhanced
