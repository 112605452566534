import React, { useCallback } from 'react'
import { Field } from 'formik'

import CustomCheckbox from '../../../common/CustomCheckbox'

const IsAdmin = ({ setFieldValue, isSuperAdmin, ...rest }) => {
  const onChange = useCallback(
    (e) => {
      const { checked } = e

      if (!checked) setFieldValue('isSuperAdmin', false)
      setFieldValue('isAdmin', checked)
    },
    [setFieldValue, isSuperAdmin],
  )

  return (
    <Field
      labelText="grant_admin_access"
      name="isAdmin"
      component={CustomCheckbox}
      onChange={onChange}
      {...rest}
    />
  )
}

export default IsAdmin
