import { curry } from './function'
import { mapToArray } from './array'
import { formatDate, isValidDate } from './date'

/**
 * @param currentTarget {object}
 * @param fields {Array<string> | string}; ["foo", "bar", "baz"] or "foo.bar.baz"
 * @param notFoundResponse? {any}
 * */
export const findValue = (currentTarget, fields, notFoundResponse) => {
  if (currentTarget === void 0) return notFoundResponse
  if (!fields) return notFoundResponse
  if (!Array.isArray(fields)) fields = fields.split('.')
  if (!fields.length) return currentTarget

  currentTarget =
    typeof currentTarget === 'object' && currentTarget
      ? currentTarget[fields.shift()]
      : void 0

  return findValue(currentTarget, fields, notFoundResponse)
}

/**
 * @param fields {string | string[]}
 * @param source {object}
 * @param target? {object}
 * @desc copy fields from source object to target
 * */
export const copy = curry((fields, source, target = {}) => {
  mapToArray(fields).forEach((field) => {
    target[field] = source[field]
  })

  return target
})

export const defaultDatePredicate = (k, value) => {
  return (
    k.endsWith('At') ||
    (value && value instanceof Date) ||
    k.toLowerCase().includes('date')
  )
}
export const formatQueryFields = (
  fields = {},
  datePredicate = defaultDatePredicate,
) => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    if (datePredicate(key, value) && isValidDate(value)) {
      value = formatDate(value)
    }
    return { ...acc, [key]: value }
  }, {})
}

export const merge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target[key], source[key]))
  }

  Object.assign(target || {}, source)
  return target
}

export const compare = (v1, v2) => {
  if (!v1 || !v2) return v1 === v2

  if (typeof v1 !== typeof v2) return false

  const check = (predicate) => predicate(v1) && predicate(v2)

  if (check((obj) => typeof obj === 'function')) {
    return v1.toString() === v2.toString()
  }

  if (check((obj) => Array.isArray(obj))) {
    return (
      v1.length === v2.length &&
      v1.every((value, index) => compare(value, v2[index]))
    )
  }

  if (check((obj) => obj instanceof Date)) {
    return v1.getTime() === v2.getTime()
  }

  if (check((obj) => typeof obj === 'object')) {
    const len = (obj) => Object.keys(obj).length

    return (
      len(v1) === len(v2) &&
      Object.keys(v1).every((key) => compare(v1[key], v2[key]))
    )
  }

  return v1 === v2
}

export const isNil = (value) => value === void 0 || value === null
