import { observable, action, computed } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { error, Notification, success } from '../../helpers/notifications'
import { injectIntoTemplate } from '../../utils/string'
import DeviceCategoriesStore from '../device-pages/DeviceCategories/mobx'

class GamingSetsStore {
  resource = `${api.devices}/v1/gaming-sets`
  itemUrlTemplate = '/devices/gaming-sets/set/{id}'

  NOTIFICATION_CREATED = Notification.GAMING_SET_CREATED
  NOTIFICATION_UPDATED = Notification.GAMING_SET_UPDATED
  NOTIFICATION_DELETED = Notification.GAMING_SET_DELETED

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable item = null

  @computed get _isLoading() {
    return this.isLoading || DeviceCategoriesStore.isLoading
  }

  processItem = ({ config, ...values }) => ({
    ...values,
    config: JSON.stringify(config),
  })

  @action
  getAll = async (fields) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  deleteOne = async (id) => {
    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      error(response.message, { path: 'errors' })
    } else {
      success(this.NOTIFICATION_DELETED)
      this.list = this.list.filter((item) => item.id != id)
    }
    this.isLoading = false
  }

  @action
  getOne = async (id) => {
    this.isLoading = true
    const { message = null, result } = await Http.get(`${this.resource}/${id}`)
    this.errorMsg = message
    this.item = result ? this.processItem(result) : null
    this.isLoading = false
  }

  @action
  submitOne = async ({ id, ...values }, { resetForm }, history, item) => {
    this.isLoading = true

    let response, onSuccess

    if (id) {
      response = await Http.put(`${this.resource}/${id}`, values)
      onSuccess = (result) => {
        success(this.NOTIFICATION_UPDATED)
        this.item = this.processItem({ ...this.item, ...result })
        resetForm()
      }
    } else {
      response = await Http.post(this.resource, values)
      onSuccess = (result) => {
        success(this.NOTIFICATION_CREATED)
        history.push(this.getItemUrl(result))
        resetForm()
      }
    }

    const { message = null, result } = response
    this.errorMsg = message
    if (result) {
      onSuccess(result)
    }
    this.isLoading = false
  }

  getItemUrl = (item) => {
    return injectIntoTemplate(this.itemUrlTemplate, item)
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
  }
}

export default new GamingSetsStore()
