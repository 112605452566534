import * as columns from './columns'

import { renderIntoTable } from '../../../../utils/react'
import UsersProfilesActionBody from '../View/UsersProfilesActionBody'
import MyPublicationsFilters from '../View/MyPublicationsFilters'

const commonCols = [
  'name',
  'desc',
  'application',
  'isLightingEffectsOnly',
  'activeTo',
  'createdAt',
  'likes',
  'downloads',
]

export const publicProfilesConfig = {
  columnList: ['user', ...commonCols, 'status', 'actions'],
  columnPropsMapping: ['likes', 'downloads', 'createdAt'].reduce(
    (acc, key) => ({ ...acc, [key]: { sortable: true } }),
    {
      actions: {
        body: renderIntoTable(UsersProfilesActionBody),
        className: 'lg-td-style',
      },
    },
  ),
  Filters: MyPublicationsFilters,
  values: {
    status: null,
  },
}

export { columns }
