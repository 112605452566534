import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { bindClassName } from '../../../../utils/react'
import { Button } from 'primereact/button'

const processedConfig = {
  labelKey: 'unprocess',
  className: 'danger-tertiary-button',
  icon: 'pi pi-times',
}

const notProcessedConfig = {
  labelKey: 'process',
  className: 'success-tertiary-button',
  icon: 'pi pi-check',
}

const ActionBody = ({
  rowData: { id, isProcessed },
  setProcessed,
  setAnswerFeedbackId,
}) => {
  const { t } = useTranslation()

  const { labelKey, className, icon } = isProcessed
    ? processedConfig
    : notProcessedConfig

  const update = useCallback(() => setProcessed(id, !isProcessed), [
    id,
    isProcessed,
    setProcessed,
  ])

  const openAnswerModal = useCallback(() => setAnswerFeedbackId(id), [
    setAnswerFeedbackId,
    id,
  ])

  return (
    <div className="action-button-wrapper">
      <Button
        {...bindClassName('tertiary-button', [className])}
        type="button"
        label={t(`actions.${labelKey}`)}
        icon={icon}
        onClick={update}
      />
      <Button
        label={t('actions.answer')}
        className="tertiary-button success-tertiary-button"
        onClick={openAnswerModal}
      />
    </div>
  )
}

const withData = inject((stores) => ({
  setProcessed: stores.FeedbackStore.setProcessed,
  setAnswerFeedbackId: stores.FeedbackStore.setAnswerFeedbackId,
}))

export default withData(observer(ActionBody))
