import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import './Button.scss'
import { bindClassName } from '../../../../../utils/react'
import api from '../../../../../configs/api'
import { OAuth } from '../../../../../constants'
import { googleIcon, facebookIcon } from './icons'
import { buildQuery, osPlatformName } from '../../../../../helpers/http'
import { osDesktopPlatform } from '../../../../../helpers/platform'

export const Action = {
  SignIn: 'SignIn',
  Link: 'Link',
  SignUp: 'SignUp',
}

const templates = {
  [Action.SignIn]: 'sign_in',
  [Action.Link]: 'link',
  [Action.SignUp]: 'sign_up',
}

const icons = {
  [OAuth.Google]: googleIcon,
  [OAuth.Facebook]: facebookIcon,
}

const Button = ({
  type,
  action,
  wrapperClassName,
  className,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const { disabled } = rest

  const service = useMemo(
    () => Object.entries(OAuth).find(([, value]) => value === type)[0],
    [type],
  )
  const label = useMemo(
    () => t(`auth.oauth.${templates[action]}`, { service }),
    [service, action, t],
  )

  const url = useMemo(() => {
    let baseUrl = `${api.auth}/v1/auth/${type}/`

    switch (action) {
      case Action.SignIn:
        baseUrl += 'login'
        break
      case Action.SignUp:
        baseUrl += 'register'
        break
      case Action.Link:
        baseUrl += 'link'
        break
      default:
        return
    }

    baseUrl += buildQuery({ [osPlatformName]: osDesktopPlatform })
    return baseUrl
  }, [type, action])

  const handleClick = useCallback(() => {
    if (!disabled) window.location.href = url
  }, [disabled, url])

  return (
    <div {...bindClassName('p-col-12', [wrapperClassName])}>
      <button
        {...bindClassName('oauth-btn', [type], [className])}
        {...rest}
        onClick={handleClick}
        type="button"
      >
        {icons[type]}
        <span className="oauth-btn__label">{label}</span>
      </button>
      {children}
    </div>
  )
}

export default Button
