import React, { useCallback } from 'react'
import { BiFullscreen } from 'react-icons/bi'
import { inject, observer } from 'mobx-react'

import MediaItem from '../../../promotion/MediaItem'
import Links from '../../../promotion/Links'
import PromotionDatesView from '../../../promotion/PromotionDatesView'
import { getMediaLink } from '../../../../helpers/promotions'
import Actions from './Actions'

const PromotionItem = ({ promotion, setPromotionViewId }) => {
  const { links, shortText, mediaType, id } = promotion

  const openPromotion = useCallback(() => setPromotionViewId(id), [
    setPromotionViewId,
    id,
  ])

  return (
    <div className="promotion-item-wrapper">
      <div className="promotion-item">
        <div className="promotion-item-media">
          <PromotionDatesView {...promotion} />
          <MediaItem
            mediaType={mediaType}
            mediaLink={getMediaLink(promotion)}
          />
        </div>
        <div className="promotion-item-info">
          <div className="promotion-item-content">{shortText}</div>
          <div className="promotion-item-footer">
            <BiFullscreen onClick={openPromotion} />
            <Links links={links} />
          </div>
          <Actions promotion={promotion} />
        </div>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  setPromotionViewId: stores.PromotionsStore.setPromotionViewId,
}))

export default withData(observer(PromotionItem))
