import React from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import ActionBody from './ActionBody'
import { rowsPerPageOptions } from '../../../constants'
import { formattedCreatedAt, renderIntoTable } from '../../../utils/react'
import Type from './Type'
import Boolean from '../../table/columns/Boolean'
import { renderFirstSignIn, renderLastSignIn } from './SignInInfo'

const renderActionBody = renderIntoTable(ActionBody)
const renderType = renderIntoTable(Type)
const isVerifiedData = renderIntoTable(Boolean, {
  field: 'isVerified',
})

const View = ({
  limit,
  offset,
  onPage,
  usersList,
  isLoading,
  usersCount,
  tableHeight,
}) => {
  return (
    <DataTable
      value={usersList}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No users found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={usersCount}
      onPage={onPage}
    >
      <Column className="common-td-style" field="email" header="Email" />
      <Column className="common-td-style" field="country" header="Country" />
      <Column className="common-td-style" field="nickname" header="Nickname" />
      <Column
        className="middle-td-style"
        field="firstName"
        header="First Name"
      />
      <Column className="middle-td-style" field="lastName" header="Last Name" />
      <Column
        className="middle-td-style"
        header="Verified"
        body={isVerifiedData}
      />
      <Column
        className="middle-td-style"
        header="User type"
        body={renderType}
      />
      <Column
        className="created-at-style"
        field="createdAt"
        header="Created"
        body={formattedCreatedAt}
      />
      <Column
        className="middle-td-style"
        header="First sign in"
        body={renderFirstSignIn}
      />
      <Column
        className="middle-td-style"
        header="Last sign in"
        body={renderLastSignIn}
      />
      <Column
        body={renderActionBody}
        header="Settings"
        className="common-td-style"
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  usersList: stores.UsersStore.usersList,
  usersCount: stores.UsersStore.usersCount,
  isLoading: stores.UsersStore.isLoading,
}))

export default withData(observer(View))
