import React from 'react'
import { inject } from 'mobx-react'

import usePagination from '../../../../hooks/table/usePagination'
import View from './View'
import FilterForm from './FilterForm'

const DevicesAFK = ({ cleanUp }) => {
  const pagination = usePagination(cleanUp)

  return (
    <>
      <FilterForm {...pagination} />
      <View {...pagination} />
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.DevicesAFKStore.cleanUp,
}))

export default withData(DevicesAFK)
