export const availableLanguages = ['en', 'ru']
export const fallbackLng = 'en'
export const selectionLng = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
]
