import AuthStore from '../components/Auth/mobx'
import ProfileStore from '../components/ProfilePage/mobx'
import UsersStore from '../components/UsersPage/mobx'
import LogsStore from '../components/LogsPage/mobx'
import DevicesStore from '../components/device-pages/DevicesPage/mobx'
import DeviceProfilesStore from '../components/device-pages/DeviceProfilesPage/mobx'
import GlobalConfirm from '../components/confirm/GlobalConfirm/mobx'
import DeviceCategoriesStore from '../components/device-pages/DeviceCategories/mobx'
import PromotionsStatisticsStore from '../components/StatisticsPage/tabs/Promotions/mobx'
import FeedbackStore from '../components/FeedbackPage/mobx'
import CommunityStore from '../components/CommunityPage/mobx'
import LanguageStore from '../components/Language/mobx'
import InfoDialogStore from '../components/dialogs/InfoDialog/mobx'
import ConfirmDialogStore from '../components/dialogs/CustomConfirmDialog/mobx'
import PromotionCardStore from '../components/PromotionPage/mobx'
import PromotionsStore from '../components/PromotionsPage/mobx'
import DesktopApplicationStore from '../components/DesktopVersionPage/mobx'
import DevicesStatisticsStore from '../components/StatisticsPage/tabs/Devices/mobx'
import DevicesAFKStore from '../components/StatisticsPage/tabs/DevicesAFK/mobx'
import ApplicationPlatformsStore from '../components/AppsPage/tabs/ApplicationPlatforms/mobx'
import ApplicationsStore from '../components/AppsPage/tabs/Applications/mobx'
import GamingSetsStore from '../components/GamingSets/mobx'
import GamingDeviceProfilesStore from '../components/GamingSet/ImportProfileModal/mobx'

export default {
  AuthStore,
  ProfileStore,
  UsersStore,
  LogsStore,
  GlobalConfirm,
  DeviceCategoriesStore,
  DevicesStore,
  DeviceProfilesStore,
  PromotionsStatisticsStore,
  FeedbackStore,
  CommunityStore,
  LanguageStore,
  InfoDialogStore,
  ConfirmDialogStore,
  PromotionCardStore,
  PromotionsStore,
  DesktopApplicationStore,
  DevicesStatisticsStore,
  DevicesAFKStore,
  ApplicationPlatformsStore,
  ApplicationsStore,
  GamingSetsStore,
  GamingDeviceProfilesStore,
}
