import React, { useCallback } from 'react'

import './RemoveLink.scss'

const RemoveLink = ({ remove, index }) => {
  const handleRemove = useCallback(() => remove(index), [remove, index])

  return (
    <div className="p-col-2 relative">
      <div className="remove-link">
        <i className="pi pi-times icon--remove" onClick={handleRemove} />
      </div>
    </div>
  )
}

export default React.memo(RemoveLink)
