import React from 'react'
import { Field } from 'formik'
import * as yup from 'yup'

import CustomInput from '../../../common/CustomInput'
import { ErrorMsg } from '../../../../constants/errorMsg'
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEXP,
} from '../../../../constants/user'
import { bindClassName } from '../../../../utils/react'

export const passwordShape = {
  password: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
}

export const confirmPasswordShape = {
  confirmPassword: yup
    .string()
    .required(ErrorMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('password')], ErrorMsg.PASSWORD_NOT_MATCH),
}

const Password = ({
  name = 'password',
  labelText = 'password',
  isRequired = true,
  className,
  ...props
}) => {
  return (
    <div {...bindClassName('p-col-12', [className])}>
      <Field
        type="password"
        name={name}
        labelText={labelText}
        isRequired
        component={CustomInput}
        {...props}
      />
    </div>
  )
}

export default Password
