import React, { useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import Loadable from '../hoc/Loadable'
import { pipe } from '../../utils/function'
import PersonalInfo from './PersonalInfo'
import DeviceCategories from './DeviceCategories'
import Settings from './Settings'
import PageTabs from '../PageTabs'
import { UserTab } from '../../constants'

const UserPage = ({
  getUser,
  cleanUp,
  isUserLoading,
  user,
  getDeviceCategories,
  deviceCategoriesCleanUp,
  checkHierarchy,
}) => {
  const { id } = useParams()

  const withDeviceCategories = user?.isAdmin && checkHierarchy(user)

  useEffect(() => {
    if (id) getUser(id)

    return cleanUp
  }, [id])

  useEffect(() => {
    if (withDeviceCategories) {
      getDeviceCategories()
    }

    return () => {
      if (withDeviceCategories) deviceCategoriesCleanUp()
    }
  }, [withDeviceCategories])

  const tabs = useMemo(
    () => [
      {
        header: 'personal_info',
        Component: PersonalInfo,
        tabName: UserTab.Personal,
      },
      {
        header: 'settings',
        Component: Settings,
        isActive: !!id,
        tabName: UserTab.Settings,
      },
      {
        header: 'device_categories',
        Component: DeviceCategories,
        isActive: !!withDeviceCategories,
        tabName: UserTab.DeviceCategories,
      },
    ],
    [id, withDeviceCategories],
  )

  return (
    <>
      <CustomBreadCrumb model={Model.User} />
      <Loadable loading={isUserLoading}>
        <PageTabs section="user" tabs={tabs} />
      </Loadable>
    </>
  )
}

const withData = inject((stores) => ({
  isUserLoading: stores.UsersStore.isUserLoading,
  getUser: stores.UsersStore.getUser,
  cleanUp: stores.UsersStore.cleanUp,
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
  user: stores.UsersStore.user,
  currentUser: stores.AuthStore.user,
  checkHierarchy: stores.AuthStore.checkHierarchy,
}))

export default pipe(observer, withData)(UserPage)
