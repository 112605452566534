import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card } from 'primereact/card'

import Loadable from '../../../hoc/Loadable'
import { pipe } from '../../../../utils/function'
import Items from '../Items'

const config = [
  {
    label: 'User id',
    key: 'id',
  },
  {
    label: 'First name',
    key: 'firstName',
  },
  {
    label: 'Last name',
    key: 'lastName',
  },
  {
    label: 'Phone',
    key: 'phone',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Country',
    key: 'country',
  },
]

const UserInfo = ({ user, isLoading }) => (
  <Card title="User Info" className="p-col-12 p-md-6 p-xl-4">
    <Loadable loading={isLoading}>
      {user && <Items config={config} entity={user} />}
    </Loadable>
  </Card>
)

const withData = inject((stores) => ({
  user: stores.UsersStore.user,
  isLoading: stores.UsersStore.isUserLoading,
}))

export default pipe(observer, withData)(UserInfo)
