import React, { useCallback } from 'react'
import { inject } from 'mobx-react'
import { Redirect, Route } from 'react-router-dom'

import authInitControl from './components/hoc/authInitControl'
import { pipe } from './utils/function'
import { authHomeRoute } from './constants'
import LanguageSelect from './components/Language/LanguageSelect'

const NotAuthRoute = ({ isLogin, component: Component, ...rest }) => {
  const render = useCallback(
    (props) =>
      isLogin ? <Redirect to={authHomeRoute} /> : <Component {...props} />,
    [isLogin, Component],
  )

  return (
    <>
      <LanguageSelect className="not-auth-lng" />
      <Route {...rest} render={render} />
    </>
  )
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
}))

export default pipe(withData, authInitControl)(NotAuthRoute)
