import React, { useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import useRedirect from '../../../../hooks/useRedirect'
import CommonActionBody from '../../../table/CommonActionBody'

const ActionBody = ({
  rowData: user,
  currentUser,
  toggleUser,
  isUserDeletable,
  hasPrivilegeOver,
  open,
}) => {
  const { isDeleted, isRestorable } = user

  const redirect = useRedirect(`/users/user/${user.id}`)

  const toggle = useCallback(() => toggleUser(user), [toggleUser, user])

  const toggleUserOnClick = useCallback(() => open(toggle), [open, toggle])

  const isDeletable = useMemo(
    () => currentUser.id !== user.id && isUserDeletable(user),
    [user, currentUser, isUserDeletable],
  )

  const isEditable = useMemo(() => hasPrivilegeOver(user), [
    hasPrivilegeOver,
    user,
  ])

  const dangerProps = user.isDeleted
    ? { dangerIcon: 'pi pi-refresh' }
    : { dangerLabel: 'delete' }

  return (
    <CommonActionBody
      successLabel="edit"
      successOnClick={redirect}
      successDisabled={!isEditable}
      {...dangerProps}
      dangerOnClick={toggleUserOnClick}
      dangerDisabled={isDeleted ? !isRestorable : !isDeletable}
    />
  )
}

const withData = inject((stores) => ({
  toggleUser: stores.UsersStore.toggleUser,
  isUserDeletable: stores.UsersStore.isUserDeletable,
  hasPrivilegeOver: stores.AuthStore.hasPrivilegeOver,
  currentUser: stores.AuthStore.user,
  open: stores.GlobalConfirm.open,
}))

export default withData(observer(ActionBody))
