import React, { useCallback } from 'react'
import { inject } from 'mobx-react'

import useRedirect from '../../../../../hooks/useRedirect'
import CommonActionBody from '../../../../table/CommonActionBody'
import PreviewBtn from './PreviewBtn'

const ActionBody = ({
  rowData,
  open,
  getItemUrl,
  deleteOne,
  getPreviewItemUrl,
}) => {
  const { id } = rowData
  const redirect = useRedirect(getItemUrl(rowData))
  const redirectPreview = useRedirect(getPreviewItemUrl(rowData))

  const deleteDevice = useCallback(() => deleteOne(id), [deleteOne, id])
  const openConfirmDelete = useCallback(() => open(deleteDevice), [
    open,
    deleteDevice,
  ])

  return (
    <CommonActionBody
      successLabel="edit"
      successOnClick={redirect}
      dangerLabel="delete"
      dangerOnClick={openConfirmDelete}
    >
      <PreviewBtn onclick={redirectPreview} />
    </CommonActionBody>
  )
}

const withData = inject((stores) => ({
  open: stores.GlobalConfirm.open,
  getItemUrl: stores.DevicesStore.getItemUrl,
  getPreviewItemUrl: stores.DevicesStore.getPreviewItemUrl,
  deleteOne: stores.DevicesStore.deleteOne,
}))

export default withData(ActionBody)
