import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import { success, Notification } from '../helpers/notifications'
import { NOTIFICATION_FAST_TIMEOUT } from '../constants'
import { humanizeSize } from './size'
import { findValue, isNil } from './object'
import CustomSelect from '../components/common/CustomSelect'
import { findInCollection } from './array'

export { formattedDate, formattedCreatedAt } from './date'

/**
 * @example
 * <MyComponent
 *    {...bindClassName (
 *      "classNameThatAlwaysWillBeShown",
 *      [someCondition, "showThisClassNameIfThisConditionIsTrue", "showThisClassNameIfThisConditionIsFalse"],
 *      [someCondition2, "showThisClassNameIfThisConditionIsTrue", "showThisClassNameIfThisConditionIsFalse"],
 *      [someCondition3, "showThisClassNameIfThisConditionIsTrue"] // bind additional className only if condition is true, otherwise do nothing
 *    )}
 * />
 * @example
 * this component accepts optional prop 'className'
 * if 'className' has been passed then it will be shown, otherwise empty className will be bounded
 * <MyComponent {...bindClassName([className])} />
 * */
export const bindClassName = (baseClassName = '', ...config) => {
  if (typeof baseClassName !== 'string') {
    config = [baseClassName, ...config]
    baseClassName = ''
  }
  const className = config.reduce(
    (acc, [condition, ifMatch = condition, ifNotMatch = '']) => {
      return (acc + ` ${condition ? ifMatch : ifNotMatch}`).trim()
    },
    baseClassName,
  )
  return { className }
}

export const renderIntoTable = (Component, props = {}) => (rowData) => (
  <Component rowData={rowData} {...rowData} {...props} />
)

export const isElemMatchesOrHasClosest = (elem, selector) => {
  return elem.matches(selector) || elem.closest(selector)
}

export const download = (filename, blobLink) => {
  if (typeof blobLink !== 'string') {
    blobLink = window.URL.createObjectURL(blobLink)
  }

  const a = document.createElement('a')
  a.download = filename
  a.href = blobLink
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  return blobLink
}

export const copyToClipboardByTarget = (target, removeSelected = true) => {
  target.select()
  document.execCommand('copy')
  success(Notification.COPIED, { timeout: NOTIFICATION_FAST_TIMEOUT })
  if (removeSelected) target.selectionStart = target.selectionEnd
}

export const copyTextToClipboard = (text) => {
  const input = document.createElement('input')
  input.value = text
  document.body.appendChild(input)
  copyToClipboardByTarget(input, false)
  document.body.removeChild(input)
}

export const makeSelectOptionsFromEnum = (Enum) => {
  return Object.entries(Enum).reduce(
    (acc, [label, value]) => [...acc, { label, value }],
    [],
  )
}

export const renderSize = (rowData, { field } = { field: 'size' }) => {
  return humanizeSize(findValue(rowData, field, 0))
}

export const createSelect = (options) => {
  const {
    storeName,
    optionLabel = 'name',
    optionValue = 'id',
    dataKey = optionValue,
    DefaultSelect = CustomSelect,
  } = options || {}

  const EnhancedSelect = ({ list, Select = DefaultSelect, ...rest }) => (
    <Select
      options={list}
      optionLabel={optionLabel}
      optionValue={optionValue}
      dataKey={dataKey}
      {...rest}
    />
  )

  const withData = inject((stores) => ({
    list: stores[storeName].list,
  }))

  return withData(observer(EnhancedSelect))
}

export const createIdToValueMapper = (options) => {
  const {
    storeName,
    dataName,
    dataIdKey,
    listIdKey = 'id',
    render: defaultRender,
    fallback: defaultFallback,
  } = options || {}

  const Mapper = ({
    list,
    render = defaultRender,
    rowData,
    fallback = defaultFallback,
    ...props
  }) => {
    const id = (rowData || props)[dataIdKey]

    const result = useMemo(() => {
      let value = findInCollection(listIdKey, list, id)?.[dataName]
      value = render ? render({ value, list }) : value

      if (isNil(value) && fallback !== void 0) {
        value = fallback
      }

      return value
    }, [id, list])

    return result
  }

  const withData = inject((stores) => ({
    list: stores[storeName].list,
  }))

  return withData(observer(Mapper))
}
