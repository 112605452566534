import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../common/CustomInput'
import { pipe } from '../../../utils/function'
import CommonFiltersForm, {
  DateRangeField,
} from '../../table/CommonFiltersForm'
import { LogsError } from '../../messages/Error'

const initialValues = {
  dateRange: null,
  login: '',
  ip: '',
  actionText: '',
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getLogs, filterRef }) => (
  <CommonFiltersForm
    getAll={getLogs}
    limit={limit}
    offset={offset}
    isLoading={isLoading}
    initialValues={initialValues}
    errorComponent={LogsError}
    applyLabel="find"
    filterRef={filterRef}
  >
    <DateRangeField className={className} />
    <div className={className}>
      <Field type="text" name="ip" placeholder="ip" component={CustomInput} />
    </div>
    <div className={className}>
      <Field
        type="text"
        name="login"
        placeholder="login"
        component={CustomInput}
      />
    </div>
    <div className={className}>
      <Field
        type="text"
        name="actionText"
        placeholder="action_text"
        component={CustomInput}
      />
    </div>
  </CommonFiltersForm>
)

const withData = inject((stores) => ({
  isLoading: stores.LogsStore.isLoading,
  getLogs: stores.LogsStore.getLogs,
}))

export default pipe(observer, withData)(FilterForm)
