import React, { useCallback } from 'react'
import { Field } from 'formik'

import CustomCheckbox from '../../../common/CustomCheckbox'

const IsSuperAdmin = ({ setFieldValue, isSuperAdmin, ...rest }) => {
  const onChange = useCallback(
    (e) => {
      const { checked } = e

      if (checked) setFieldValue('isAdmin', true)
      setFieldValue('isSuperAdmin', checked)
    },
    [setFieldValue, isSuperAdmin],
  )

  return (
    <Field
      labelText="grant_super_admin_access"
      name="isSuperAdmin"
      component={CustomCheckbox}
      onChange={onChange}
      {...rest}
    />
  )
}

export default IsSuperAdmin
