import * as yup from 'yup'
import { createShape } from '../../utils/form'
import { ErrorMsg } from '../../constants/errorMsg'

export const defaultValues = {
  name: '',
}

export const propsMapping = {}

export const validationSchema = yup
  .object()
  .shape(
    createShape(
      defaultValues,
      propsMapping,
      (key) => ErrorMsg[`APPLICATION_PLATFORM_${key.toUpperCase()}_NOT_VALID`],
    ),
  )
