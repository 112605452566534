import React, { useEffect } from 'react'
import { Card } from 'primereact/card'
import { Field, useField } from 'formik'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Dropzone from '../../Dropzone'
import RenderIf from '../../hoc/RenderIf'
import CustomTextarea from '../../common/CustomTextarea'
import CustomRadioButton from '../../common/CusomRadioButton'
import { userConfig } from '../config'
import usePrevious from '../../../hooks/usePrevious'

const filesValidationData = {
  allowedExtensions: ['csv'],
}

const UserTarget = ({ removeFile, addFile, filesToUpload, hasTargetFiles }) => {
  const [{ value: isAllUsers }] = useField('isAllUsers')
  const [, , { setValue: setHasTargetFiles }] = useField('hasTargetFiles')

  const { t } = useTranslation()

  const prev = usePrevious({ hasTargetFiles })

  useEffect(() => {
    if (hasTargetFiles !== prev.hasTargetFiles)
      setHasTargetFiles(hasTargetFiles)
  }, [hasTargetFiles])

  return (
    <Card title={t('card.title.users_target')}>
      <div className="p-col-12 p-d-flex p-dir-row mb-3">
        {userConfig.map((elem, index) => {
          return (
            <Field
              key={index}
              name="isAllUsers"
              component={CustomRadioButton}
              {...elem}
            />
          )
        })}
      </div>
      <RenderIf condition={!isAllUsers}>
        <div className="p-col-12">
          <Dropzone
            titleKey="promotion_drop_users_list"
            filesValidationData={filesValidationData}
            files={filesToUpload}
            handleAddFiles={addFile}
            handleRemove={removeFile}
          />
        </div>
        <div className="p-col-12">
          <Field
            type="text"
            name="targets"
            labelText="promo_users"
            component={CustomTextarea}
          />
        </div>
      </RenderIf>
    </Card>
  )
}

const withData = inject((stores) => ({
  addFile: stores.PromotionCardStore.addTargetFiles,
  removeFile: stores.PromotionCardStore.removeTargetFile,
  filesToUpload: stores.PromotionCardStore.targetFiles,
  hasTargetFiles: stores.PromotionCardStore.hasTargetFiles,
}))

export default withData(observer(UserTarget))
