import api from '../../../../configs/api'
import CrudStore from '../../../../stores/common/CrudStore'

class DevicesAFKStore extends CrudStore {
  resource = `${api.devices}/v1/admin/device-statistics/afk`
  fallbackCsvExportName = 'afk-devices-statistics'

  prepareQueryObject({ days, ...query }) {
    if (Array.isArray(days)) {
      query.daysGte = days[0]
      query.daysLte = days[1]
    } else if (typeof days === 'number') {
      query.daysGte = days
    }

    return query
  }
}

export default new DevicesAFKStore()
