import React, { useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { body } from '../../../constants'

const Confirm = ({ close, isOpen, confirm, header = 'default' }) => {
  const handleConfirm = useCallback(() => {
    close()
    confirm()
  }, [close, confirm])

  const { t } = useTranslation()

  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      header={t(`confirm.${header}`)}
      appendTo={body}
    >
      <div className="p-col-12 p-d-flex p-justify-between p-mt-2">
        <Button
          label={t('labels.yes')}
          onClick={handleConfirm}
          className="tertiary-button confirm-tertiary success-tertiary-button"
        />
        <Button
          label={t('labels.no')}
          onClick={close}
          className="tertiary-button confirm-tertiary danger-tertiary-button ml-3"
        />
      </div>
    </Dialog>
  )
}

export default Confirm
