import { Notification } from './notification'

export const CommunityProfileStatus = {
  Pending: 'pending',
  Published: 'published',
  Rejected: 'rejected',
}

export const changeStatusNotificationMapping = {
  [CommunityProfileStatus.Published]: Notification.COMMUNITY_PROFILE_APPROVED,
  [CommunityProfileStatus.Rejected]: Notification.COMMUNITY_PROFILE_REJECTED,
}
