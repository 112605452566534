import React from 'react'

import CustomSelect from '../CustomSelect'
import countriesData from '../../../configs/countriesData'

export const mappedCountries = countriesData.map(({ name }) => ({
  label: name,
  value: name,
}))

const CountriesSelect = (props) => {
  return (
    <CustomSelect
      labelText="country"
      filter
      filterBy="label"
      resetFilterOnHide
      options={mappedCountries}
      {...props}
    />
  )
}

export default CountriesSelect
