import React from 'react'
import { Dropdown } from 'primereact/dropdown'

import { bindClassName } from '../../../utils/react'
import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'

const CustomSelect = ({
  form,
  options,
  placeholder,
  field,
  itemTemplate,
  labelText,
  optionLabel,
  optionValue,
  className,
  isRequired,
  showClear = true,
}) => {
  const { error, showError } = useFormError(form, field)

  const { label, _placeholder } = useLabel({
    labelKey: labelText,
    field,
    placeholder,
    isRequired,
  })

  return (
    <div className="p-grid p-dir-col p-nogutter p-my-2">
      {label}
      <Dropdown
        {...bindClassName([className], [showError, 'p-error'])}
        name={field.name}
        value={field.value}
        options={options}
        onChange={field.onChange}
        placeholder={_placeholder}
        showClear={showClear}
        itemTemplate={itemTemplate}
        optionLabel={optionLabel}
        optionValue={optionValue}
        onBlur={() => form.setFieldTouched(field.name, true, true)}
      />

      {error}
    </div>
  )
}

export default CustomSelect
