import React, { Fragment, useCallback } from 'react'
import { Field, FieldArray, useField } from 'formik'

import CustomInput from '../../../common/CustomInput'
import SecondaryButton from '../../../buttons/SecondaryButton'
import RenderIf from '../../../hoc/RenderIf'
import RemoveLink from './RemoveLink'

const Links = () => {
  const [{ value: links }, , { setValue: setLinks }] = useField('links')

  const addLink = useCallback(() => {
    setLinks([...links, { text: '', to: '' }])
  }, [links, setLinks])

  const render = useCallback(
    (arrayHelpers) =>
      links.map((link, index) => (
        <Fragment key={index}>
          <div className="p-col-5">
            <Field
              type="text"
              name={`links.${index}.text`}
              labelText="link_content"
              component={CustomInput}
            />
          </div>
          <div className="p-col-5">
            <Field
              type="text"
              name={`links.${index}.to`}
              labelText="url"
              component={CustomInput}
            />
          </div>
          <RemoveLink remove={arrayHelpers.remove} index={index} />
        </Fragment>
      )),
    [links],
  )

  return (
    <>
      <div className="p-col-12" />
      <FieldArray name="links" render={render} />
      <RenderIf condition={!links.length}>
        <div className="p-col-12">
          <SecondaryButton
            isAbsolute={false}
            labelKey="add_link"
            onClick={addLink}
          />
        </div>
      </RenderIf>
    </>
  )
}

export default React.memo(Links)
