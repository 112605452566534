import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { getDateRange, isValidDate } from '../../utils/date'
import { tableOrderMapping } from '../../constants'
import useSearchParams from '../useSearchParams'
import { buildQuery } from '../../helpers/http'
import { defaultDatePredicate } from '../../utils/object'

export const formatFilters = (fields) => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    if (defaultDatePredicate(key, value) && isValidDate(value)) {
      value = moment(value).endOf('day').utc().format()
    }
    return { ...acc, [key]: value }
  }, {})
}

const useApplyFilters = (getAll, { sortOrder, ...pagination }) => {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { pathname } = history.location

  return useCallback(
    ({ dateRange, ...rest }) => {
      const { from, to } = getDateRange(dateRange)

      const common = { ...rest, ...pagination }

      const query = {
        ...searchParams,
        ...common,
        sortOrder,
        dateRange: [from, dateRange?.[1] ? to : null],
      }

      history.push(`${pathname}${buildQuery(query)}`)

      getAll({
        ...common,
        from,
        to,
        sortOrder: tableOrderMapping[sortOrder],
      })
    },
    [getAll, sortOrder, pagination, searchParams, pathname],
  )
}

export default useApplyFilters
