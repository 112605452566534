import { observable, action, computed } from 'mobx'
import api from '../../../../configs/api'
import CrudStore from '../../../../stores/common/CrudStore'

const resource = `${api.applications}/v1/applications`

export const buildAppImageUrl = (app) => {
  const { id, image } = app || {}
  return image ? `${resource}/${id}/images/${image.filename}` : null
}

class ApplicationsStore extends CrudStore {
  resource = resource
  itemUrlTemplate = '/applications/application/{id}'

  NOTIFICATION_CREATED = 'APPLICATION_CREATED'
  NOTIFICATION_UPDATED = 'APPLICATION_UPDATED'
  NOTIFICATION_DELETED = 'APPLICATION_DELETED'

  @observable imageToUpload = null
  @observable shouldRemoveImage = false

  @computed get imageUrl() {
    if (this.imageToUpload) {
      return window.URL.createObjectURL(this.imageToUpload)
    }

    return buildAppImageUrl(this.item)
  }

  @computed get itemImage() {
    return this.item?.image
  }

  @computed get files() {
    if (this.imageToUpload) return [this.imageToUpload]

    const { image } = this.item || {}
    if (image && !this.shouldRemoveImage) {
      return [{ ...image, imageUrl: this.imageUrl }]
    }

    return []
  }

  @action
  async submitOne(...args) {
    await super.submitOne(...args)
    if (!this.errorMsg) {
      this.imageToUpload = null
      this.shouldRemoveImage = false
    }
  }

  processSubmitData(values) {
    const formData = new FormData()

    formData.set(
      'payload',
      JSON.stringify({
        ...values,
        shouldRemoveImage: this.shouldRemoveImage,
      }),
    )

    if (this.imageToUpload) {
      formData.set('image', this.imageToUpload)
    }

    return formData
  }

  @action
  handleAddFiles = ([image]) => {
    this.imageToUpload = image
  }

  @action
  handleRemoveFiles = () => {
    if (this.imageToUpload) {
      this.imageToUpload = null
    } else {
      this.shouldRemoveImage = true
    }
  }

  @action
  cleanUp() {
    super.cleanUp()
    this.imageToUpload = null
    this.shouldRemoveImage = false
  }
}

export default new ApplicationsStore()
