import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import CommonActionBody, { style } from '../../../../table/CommonActionBody'
import useRedirect from '../../../../../hooks/useRedirect'
import ArchivedItem from './ArchivedItem'
import RenderIf from '../../../../hoc/RenderIf'

const Actions = ({
  promotion,
  isUpdatable,
  isDeletable,
  isArchivable,
  deleteOne,
  archiveOne,
}) => {
  const { t } = useTranslation()

  const { id, isArchived } = promotion

  const redirect = useRedirect(`/promotions/promotion/${promotion.id}`)

  const handleDelete = useCallback(() => deleteOne(id), [deleteOne, id])
  const handleArchive = useCallback(() => archiveOne(id), [archiveOne, id])

  const canUpdate = isUpdatable(promotion)

  return (
    <>
      <RenderIf condition={isArchived}>
        <div className="settings-button-block">
          <ArchivedItem />
        </div>
      </RenderIf>
      <RenderIf condition={!isArchived || canUpdate}>
        <CommonActionBody
          successLabel="edit"
          successOnClick={redirect}
          successDisabled={!canUpdate}
          dangerLabel="delete"
          dangerOnClick={handleDelete}
          dangerDisabled={!isDeletable(promotion)}
        >
          <Button
            className="tertiary-button warn-tertiary-button ml-2"
            type="button"
            style={style}
            label={t('actions.archive')}
            onClick={handleArchive}
            disabled={!isArchivable(promotion)}
          />
        </CommonActionBody>
      </RenderIf>
    </>
  )
}

const withData = inject((stores) => ({
  isDeletable: stores.PromotionsStore.isDeletable,
  isUpdatable: stores.PromotionsStore.isUpdatable,
  isArchivable: stores.PromotionsStore.isArchivable,
  deleteOne: stores.PromotionsStore.deleteOne,
  archiveOne: stores.PromotionsStore.archiveOne,
}))

export default withData(observer(Actions))
