import React from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../../../constants'
import { useCalcTableHeightContext } from '../../../../../context'
import { formattedCreatedAt } from '../../../../../utils/date'
import ActionBody from '../../../ActionBody'
import ApplicationsStore from '../mobx'
import { renderIntoTable } from '../../../../../utils/react'

const renderActionBody = renderIntoTable(ActionBody, {
  Store: ApplicationsStore,
})

const View = (props) => {
  const { limit, offset, onPage, list, isLoading, count } = props

  const { height: tableHeight } = useCalcTableHeightContext()

  return (
    <DataTable
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No platforms found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} platforms"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      <Column header="Name" field="name" />
      <Column header="Created" field="createdAt" body={formattedCreatedAt} />
      <Column
        body={renderActionBody}
        header="Settings"
        className="common-td-style"
      />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  count: stores.ApplicationPlatformsStore.count,
  list: stores.ApplicationPlatformsStore.list,
  isLoading: stores.ApplicationPlatformsStore.isLoading,
}))

export default withData(observer(View))
