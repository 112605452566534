import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import CustomBreadCrumb, { Model } from '../../breadcrumb/CustomBreadCrumb'
import Headline from './HeadLine'
import Gallery from './Gallery'
import Loadable from '../../hoc/Loadable'
import Documents from './Documents'
import { DevicesError } from '../../messages/Error'

const DevicePreviewPage = ({ getOne, cleanUp, item, isLoading }) => {
  const { id } = useParams()

  useEffect(() => {
    if (id) getOne(id)

    return cleanUp
  }, [id])

  return (
    <>
      <CustomBreadCrumb model={Model.Device} />
      <div className="p-fluid page-content-wrapper device-view-page">
        <DevicesError />
        <Headline item={item} />
        <div className="p-grid common-gallery-wrapper">
          <Loadable loading={isLoading}>
            <Gallery />
            <Documents id={id} />
          </Loadable>
        </div>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  i18n: stores.DevicesStore.i18n,
  getOne: stores.DevicesStore.getOne,
  cleanUp: stores.DevicesStore.cleanUp,
  item: stores.DevicesStore.item,
  isLoading: stores.DevicesStore.isLoading,
}))

export default withData(observer(DevicePreviewPage))
