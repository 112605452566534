import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'primereact/button'

const ActionBody = ({ toggleOpen, setGamingSetDevices, item }) => {
  const { t } = useTranslation()

  const publish = useCallback(() => {
    toggleOpen()
    setGamingSetDevices(item)
  }, [item, setGamingSetDevices, toggleOpen])

  return (
    <Button
      className="p-button p-component common-button"
      label={t('actions.import')}
      onClick={publish}
    />
  )
}

export default ActionBody
