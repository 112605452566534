import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../common/CustomInput'
import { pipe } from '../../../utils/function'
import CommonFiltersForm, {
  DateRangeField,
} from '../../table/CommonFiltersForm'
import CustomTriStateCheckbox from '../../common/CustomTriStateCheckbox'

const initialValues = {
  dateRange: null,
  email: '',
  subject: '',
  body: '',
  isProcessed: null,
}

const propsMapping = {
  isProcessed: {
    component: CustomTriStateCheckbox,
  },
}

const keys = Object.keys(initialValues).filter((k) => k !== 'dateRange')

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll, filterRef }) => {
  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      {keys.map((key) => (
        <div className={className} key={key}>
          <Field
            name={key}
            placeholder={key}
            component={CustomInput}
            {...(propsMapping[key] || {})}
          />
        </div>
      ))}
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.FeedbackStore.isLoading,
  getAll: stores.FeedbackStore.getAll,
}))

export default pipe(observer, withData)(FilterForm)
