import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'

import { FeedbackAnswerError } from '../../messages/Error'
import CustomTextarea from '../../common/CustomTextarea'
import Loadable from '../../hoc/Loadable'
import { ErrorMsg } from '../../../constants/errorMsg'

const initialValues = { answer: '' }

const validationSchema = yup.object().shape({
  answer: yup.string().required(ErrorMsg.FEEDBACK_ANSWER_REQUIRED),
})

const AnswerModal = ({
  closeAnswerFeedbackModal,
  isLoading,
  isOpen,
  answerFeedback,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      modal
      className="answer-modal-wrapper"
      header={t('modals.answer_to_feedback')}
      visible={isOpen}
      onHide={closeAnswerFeedbackModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={answerFeedback}
        enableReinitialize
      >
        {({ dirty }) => (
          <Form>
            <div className="p-grid">
              <FeedbackAnswerError />
              <Loadable loading={isLoading}>
                <div className="p-col-12">
                  <Field
                    name="answer"
                    labelText="answer"
                    component={CustomTextarea}
                  />
                </div>
              </Loadable>
              <div className="p-col-12 p-flex p-justify-between">
                <Button
                  label={t('actions.send')}
                  icon="pi pi-check"
                  disabled={!(dirty && !isLoading)}
                />
                <Button
                  label={t('actions.no')}
                  icon="pi pi-times"
                  onClick={closeAnswerFeedbackModal}
                  type="button"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

const withData = inject((stores) => ({
  closeAnswerFeedbackModal: stores.FeedbackStore.closeAnswerFeedbackModal,
  answerFeedback: stores.FeedbackStore.answerFeedback,
  isLoading: stores.FeedbackStore.isLoading,
  isOpen: !!stores.FeedbackStore.answerFeedbackId,
}))

export default withData(observer(AnswerModal))
