import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isValidEmail } from '../../../../utils/string'

const msg = {
  email: 'password_link_email',
  sms: 'password_link_sms',
}

const RecoveryPassMsg = ({ login }) => {
  const { t } = useTranslation()
  const isEmail = useMemo(() => isValidEmail(login), [login])
  const label = t(`labels.message.${isEmail ? msg.email : msg.sms}`)

  return (
    <div className="p-d-flex p-dir-col p-my-4">
      <label className="p-d-flex">{login}</label>
      <span className="password-link-msg">{label}</span>
    </div>
  )
}

export default RecoveryPassMsg
