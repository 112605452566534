import React from 'react'
import { inject, observer } from 'mobx-react'

import SecondaryButton from '../../../../buttons/SecondaryButton'

const Export = ({ isExporting, openExport, hasList }) => (
  <SecondaryButton
    labelKey="export"
    disabled={isExporting || !hasList}
    onClick={openExport}
  />
)

const withData = inject((stores) => ({
  isExporting: stores.DevicesStatisticsStore.isExporting,
  openExport: stores.DevicesStatisticsStore.openExport,
  hasList: stores.DevicesStatisticsStore.hasList,
}))

export default withData(observer(Export))
