import * as yup from 'yup'

import { ErrorMsg } from '../../../constants/errorMsg'
import {
  loginFieldsExcludes,
  loginFieldsShape,
  MAX_USER_NAME_LENGTH,
  MIN_USER_NAME_LENGTH,
  nicknameShape,
  USER_NAME_REGEXP,
} from '../../../constants/user'

export const createValidationSchema = (shape = {}) =>
  yup.object().shape(
    {
      firstName: yup
        .string()
        .trim()
        .min(MIN_USER_NAME_LENGTH, ErrorMsg.FIRST_NAME_INVALID_LENGTH)
        .max(MAX_USER_NAME_LENGTH, ErrorMsg.FIRST_NAME_INVALID_LENGTH)
        .matches(USER_NAME_REGEXP, ErrorMsg.FIRST_NAME_LATIN_CHAR),
      lastName: yup
        .string()
        .trim()
        .min(MIN_USER_NAME_LENGTH, ErrorMsg.LAST_NAME_INVALID_LENGTH)
        .max(MAX_USER_NAME_LENGTH, ErrorMsg.LAST_NAME_INVALID_LENGTH)
        .matches(USER_NAME_REGEXP, ErrorMsg.LAST_NAME_LATIN_CHAR),
      ...nicknameShape,
      ...loginFieldsShape,
      ...shape,
    },
    loginFieldsExcludes,
  )

export const defaultSchema = createValidationSchema()

export const inputList = [
  { value: 'firstName', label: 'first_name' },
  { value: 'lastName', label: 'last_name' },
  { value: 'nickname', label: 'nickname' },
  { value: 'email', label: 'email', isRequired: true },
]

export const defaultValues = {
  nickname: '',
  firstName: '',
  lastName: '',
  country: null,
  email: '',
  phone: '',
}
