import React, { useCallback, useMemo } from 'react'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

import useToggler from '../../../../hooks/useToggler'
import RenderIf from '../../../hoc/RenderIf'

const DeleteAccBtn = ({ toggleUser, user, isUserDeletable }) => {
  const { t } = useTranslation()

  const [isOpen, , open, close] = useToggler()

  const deleteAccount = useCallback(() => toggleUser(user), [toggleUser, user])

  const isDeletable = useMemo(() => isUserDeletable(user), [
    isUserDeletable,
    user,
  ])

  return (
    <RenderIf condition={isDeletable}>
      <ConfirmDialog
        className="delete-confirm-dialog"
        visible={isOpen}
        onHide={close}
        message={t('confirm.delete_account_message')}
        header={t('confirm.delete_account')}
        icon="pi pi-exclamation-triangle"
        accept={deleteAccount}
        reject={close}
        acceptLabel={t('actions.yes')}
        rejectLabel={t('actions.no')}
        acceptClassName="common-btn danger-tertiary-button"
        rejectClassName="p-button-secondary"
      />
      <Button
        type="delete"
        label={t(`actions.delete_your_account`)}
        className="common-btn danger-tertiary-button delete-account-button"
        onClick={open}
      />
    </RenderIf>
  )
}
const withData = inject((stores) => ({
  toggleUser: stores.UsersStore.toggleUser,
  isUserDeletable: stores.UsersStore.isUserDeletable,
  user: stores.AuthStore.user,
}))

export default withData(observer(DeleteAccBtn))
