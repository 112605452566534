import cloneDeep from 'lodash.clonedeep'

export const DesktopPlatform = {
  Windows64: 'win64',
  Windows32: 'win32',
  Linux: 'linux',
  MacOs: 'macos',
}

export const platforms = Object.values(DesktopPlatform)

export const platformLabelsMapping = {
  [DesktopPlatform.Windows64]: 'Windows (x64)',
  [DesktopPlatform.Windows32]: 'Windows (x32)',
  [DesktopPlatform.Linux]: 'Linux',
  [DesktopPlatform.MacOs]: 'MacOs',
}

const windowsConfig = {
  allowedExtensions: ['exe'],
}

export const platformsConfig = {
  [DesktopPlatform.Windows64]: cloneDeep(windowsConfig),
  [DesktopPlatform.Windows32]: cloneDeep(windowsConfig),
  [DesktopPlatform.MacOs]: {
    allowedExtensions: ['pkg', 'mpkg'],
  },
  [DesktopPlatform.Linux]: {
    allowedExtensions: ['tar.bz2'],
  },
}
