import React from 'react'
import { inject, observer } from 'mobx-react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { rowsPerPageOptions } from '../../../../../constants'
import { useCalcTableHeightContext } from '../../../../../context'
import renderStaticCols, {
  commonStaticCols,
} from '../../../../../helpers/device-statistics/render-static-cols'

const View = (props) => {
  const { limit, offset, onPage, list, isLoading, count } = props

  const { height: tableHeight } = useCalcTableHeightContext()

  return (
    <DataTable
      tableClassName="device-statistics-table"
      value={list}
      dataKey="id"
      className="datatable"
      rowHover
      paginator
      first={offset}
      rows={limit}
      emptyMessage="No statistics found"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} statistics"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={rowsPerPageOptions}
      loading={isLoading}
      scrollable
      scrollHeight={`${tableHeight}px`}
      lazy
      totalRecords={count}
      onPage={onPage}
    >
      {renderStaticCols(true, commonStaticCols)}
      <Column header="Days" field="days" className="little-td-style" />
    </DataTable>
  )
}

const withData = inject((stores) => ({
  count: stores.DevicesAFKStore.count,
  list: stores.DevicesAFKStore.list,
  isLoading: stores.DevicesAFKStore.isLoading,
}))

export default withData(observer(View))
