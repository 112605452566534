import React, { useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { FiX } from 'react-icons/fi'

import Dropzone from '../../../../../Dropzone'
import FilenameLink from './FilenameLink'

const Item = ({
  item: { language, description, instructions },
  i18nOnChange,
  removeI18nItem,
}) => {
  const { t } = useTranslation()

  const { onChange, removeCurrentItem } = useMemo(
    () => ({
      onChange: (...args) => i18nOnChange(language, ...args),
      removeCurrentItem: (...args) => removeI18nItem(language, ...args),
    }),
    [language],
  )

  const dropzoneFiles = useMemo(() => (instructions ? [instructions] : []), [
    instructions,
  ])

  const handleAddFiles = useCallback(
    ([file]) => onChange('instructions', file),
    [onChange],
  )

  const handleRemove = useCallback(() => onChange('instructions', null), [
    onChange,
  ])

  const renderDropzoneItem = useCallback(
    ({ size, removeIcon, file }) => (
      <>
        <FilenameLink file={file} />
        {size}
        {removeIcon}
      </>
    ),
    [],
  )

  return (
    <tr className="language-row">
      <td>{t(`languages.${language}`)}</td>
      <td>
        <InputText name="description" value={description} onChange={onChange} />
      </td>
      <td>
        <Dropzone
          titleKey="device_drop_instruction"
          accept="application/pdf"
          disabled={instructions}
          files={dropzoneFiles}
          handleAddFiles={handleAddFiles}
          handleRemove={handleRemove}
          renderItem={renderDropzoneItem}
        />
      </td>
      <td>
        <FiX onClick={removeCurrentItem} />
      </td>
    </tr>
  )
}

const withData = inject((stores) => ({
  i18nOnChange: stores.DevicesStore.i18nOnChange,
  removeI18nItem: stores.DevicesStore.removeI18nItem,
}))

export default withData(observer(Item))
