import { action, observable, computed } from 'mobx'

import { filesComparator, unique } from '../../utils/array'
import {
  deserializeTargetEmails,
  isMediaLinkRequired,
  serializeTargetEmails,
} from '../../helpers/promotions'
import {
  PromotionFileTypes,
  PromotionMediaResourceType,
  PromotionMediaType,
} from '../../constants/promotions'
import { ErrorMsg } from '../../constants/errorMsg'
import { formDataAppendArray } from '../../utils/form'
import { Notification } from '../../constants/notification'
import { injectIntoTemplate } from '../../utils/string'
import Http, { buildQuery } from '../../helpers/http'
import { success } from '../../helpers/notifications'
import { formatDate } from '../../utils/date'
import { toFile } from '../../helpers/files'
import PromotionsStore from '../PromotionsPage/mobx'
import { pipe } from '../../utils/function'

const resetSecs = (date) => {
  date = new Date(date)
  date.setSeconds(0)
  return date
}

const formatPromotionDate = pipe(resetSecs, formatDate)

class PromotionCardStore {
  resource = PromotionsStore.resource

  itemUrlTemplate = '/promotions/promotion/{id}'

  NOTIFICATION_CREATED = Notification.PROMOTION_CREATED
  NOTIFICATION_UPDATED = Notification.PROMOTION_UPDATED

  @observable isLoading = false
  @observable errorMsg = null
  @observable item = null
  @observable rawItem = null
  @observable targetFiles = []
  @observable images = []

  @computed get hasTargetFiles() {
    return !!this.targetFiles.length
  }

  @action
  getOne = async (id) => {
    this.isLoading = true
    const query = buildQuery({ withNested: true })
    const { message = null, result } = await Http.get(
      `${this.resource}/${id}${query}`,
    )
    this.errorMsg = message
    if (result) {
      this.item = await this.processItem(result)
    }
    this.isLoading = false
  }

  @action
  submitOne = async ({ id, ...values }, { resetForm }, history) => {
    if (!this.validate(values)) return

    if (id && this.item && !PromotionsStore.isUpdatable(this.item)) return

    this.isLoading = true
    values = this.processSubmitData(values)

    let response, onSuccess
    if (id) {
      response = await Http.put(`${this.resource}/${id}`, values)
      onSuccess = async () => {
        resetForm()
        await this.getOne(id)
        success(this.NOTIFICATION_UPDATED)
      }
    } else {
      response = await Http.post(this.resource, values)
      onSuccess = (result) => {
        history.push(this.getItemUrl(result))
        success(this.NOTIFICATION_CREATED)
      }
    }

    const { message = null, result } = response
    this.errorMsg = message
    if (result) {
      this.cleanUp()
      onSuccess(result)
    }
    this.isLoading = false
  }

  @action
  validate = ({ mediaType, mediaResourceType }) => {
    if (
      !isMediaLinkRequired(mediaType, mediaResourceType) &&
      mediaType === PromotionMediaType.Image &&
      !this.images.length
    ) {
      this.errorMsg = ErrorMsg.PROMOTION_MEDIA_FILE_REQUIRED
      return false
    }

    return true
  }

  @action
  processSubmitData = (data) => {
    const {
      hasTargetFiles,
      mediaResourceType,
      targets,
      mediaLink,
      ...values
    } = data
    const { startDate, endDate, isAllUsers, mediaType } = values

    const formData = new FormData()

    if (mediaResourceType === PromotionMediaResourceType.File) {
      const [image] = this.images
      if (!image._isUploaded) formData.set('images', image)
    } else if (isMediaLinkRequired(mediaType, mediaResourceType)) {
      values.mediaLink = mediaLink
    }

    if (this.targetFiles.length) {
      formDataAppendArray('targets', this.targetFiles, formData)
    }

    if (!isAllUsers) values.targets = serializeTargetEmails(targets)

    values.startDate = formatPromotionDate(startDate)
    values.endDate = formatPromotionDate(endDate)

    formData.set('payload', JSON.stringify(values))

    return formData
  }

  @action
  processItem = async (item) => {
    const { targets, startDate, endDate, mediaFileInfo, ...values } = item
    const { id } = values

    let mediaResourceType = PromotionMediaResourceType.Url

    if (mediaFileInfo?.filename) {
      mediaResourceType = PromotionMediaResourceType.File
      await this.populateImage(id, mediaFileInfo)
    }

    return {
      targets: deserializeTargetEmails(targets),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      mediaResourceType,
      ...values,
    }
  }

  @action
  populateImage = async (id, { filename, originalname }) => {
    const image = await this.fetchFile(id, PromotionFileTypes.Images, filename)
    if (image.message) return

    const file = toFile(image, originalname, { path: originalname })
    file._isUploaded = true

    this.images = [file]
  }

  @action
  fetchFile = async (id, type, filename) => {
    return Http.get(`${this.resource}/${id}/${type}/${filename}`, {
      isBlob: true,
    })
  }

  @action
  addTargetFiles = (files) => {
    this.targetFiles = unique([...this.targetFiles, ...files], filesComparator)
  }

  @action
  removeTargetFile = ({ path }) => {
    this.targetFiles = this.targetFiles.filter((file) => file.path !== path)
  }

  @action
  addImage = (files) => {
    this.images = files
  }

  @action
  removeImage = () => {
    this.images = []
  }

  getItemUrl = (item) => {
    return injectIntoTemplate(this.itemUrlTemplate, item)
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.errorMsg = null
    this.item = null
    this.targetFiles = []
    this.images = []
  }
}

export default new PromotionCardStore()
