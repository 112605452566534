import React from 'react'
import { NotificationContainer } from 'react-notifications'

import Menubar from '../../Menubar'
import ConfirmDelete from '../../confirm/GlobalConfirm'
import InfoDialog from '../../dialogs/InfoDialog'
import CustomConfirmDialog from '../../dialogs/CustomConfirmDialog'

const Layout = ({ children }) => (
  <>
    <Menubar />
    <ConfirmDelete />
    <NotificationContainer />
    <InfoDialog />
    <CustomConfirmDialog />
    {children}
  </>
)

export default Layout
