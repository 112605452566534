import React from 'react'
import { Column } from 'primereact/column'
import { dateFormats, formattedDate } from '../../utils/date'
import { bindClassName } from '../../utils/react'

const activatedAt = formattedDate('activatedAt', dateFormats.dateOnly)

export const statisticsCountClassName = 'middle-td-style wrap'

export const commonStaticCols = [
  { header: 'Account', field: 'User.email', className: 'common-td-style' },
  { header: 'Country', field: 'User.country', className: 'common-td-style' },
  { header: 'Nickname', field: 'User.nickname', className: 'common-td-style' },
  {
    header: 'Product type',
    field: 'Device.DeviceCategory.name',
    className: 'common-td-style',
  },
  { header: 'Model', field: 'Device.model', className: 'common-td-style' },
]

const staticCols = [
  ...commonStaticCols,
  {
    header: 'Start of use',
    field: 'activatedAt',
    body: activatedAt,
    className: 'date-style',
  },
  {
    header: 'Total activity time (h)',
    field: 'activityTime',
    className: statisticsCountClassName,
  },
]

const renderStaticCols = (withHeader = true, cols = staticCols) => {
  return cols.map(({ header, className, ...rest }) => {
    return (
      <Column
        key={header}
        {...rest}
        {...(withHeader ? { header } : {})}
        {...bindClassName('static-col wrap', [className])}
      />
    )
  })
}

export default renderStaticCols
