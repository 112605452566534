import React from 'react'
import { Field } from 'formik'
import * as yup from 'yup'

import CustomInput from '../../../common/CustomInput'

export const nicknameShape = {
  nickname: yup.string().trim(),
}

const NickName = (props) => (
  <div className="p-col-12 field-wrapper">
    <Field
      type="text"
      name="nickname"
      labelText="Name"
      component={CustomInput}
      {...props}
    />
  </div>
)

export default NickName
