import React from 'react'
import { inject, observer } from 'mobx-react'

import CustomSelect from '../../common/CustomSelect'

const DeviceCategoryId = ({ list, Select = CustomSelect, ...rest }) => (
  <Select
    options={list}
    optionLabel="name"
    optionValue="id"
    dataKey="id"
    {...rest}
  />
)

const withData = inject((stores) => ({
  list: stores.DeviceCategoriesStore.list,
}))

export default withData(observer(DeviceCategoryId))
