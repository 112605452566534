import { DesktopPlatform } from '../constants/desktop'

const { userAgent, cpuClass, platform } = window.navigator

/**
 * @see https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
 * */
const mobileChecks = {
  get isAndroid() {
    return userAgent.match(/Android/i)
  },
  get isBlackBerry() {
    return userAgent.match(/BlackBerry/i)
  },
  get isIOS() {
    return userAgent.match(/iPhone|iPad|iPod/i)
  },
  get isOpera() {
    return userAgent.match(/Opera Mini/i)
  },
  get isWindows() {
    return (
      userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
    )
  },
  isAny: function () {
    return (
      mobileChecks.isAndroid ||
      mobileChecks.isBlackBerry ||
      mobileChecks.isIOS ||
      mobileChecks.isOpera ||
      mobileChecks.isWindows
    )
  },
}

export const isMobile = mobileChecks.isAny()

/**
 * @see https://stackoverflow.com/questions/50195475/detect-if-device-is-tablet/53518181
 * */
const tabletRegex =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i
export const isTablet = tabletRegex.test(userAgent)

/**
 * @see https://stackoverflow.com/questions/1741933/detect-64-bit-or-32-bit-windows-from-user-agent-or-javascript
 * */
const is64BitsSystemArchitecture = (() => {
  const toCheck = [userAgent]

  if (cpuClass) {
    toCheck.push(cpuClass)
  }

  if (platform) {
    toCheck.push(platform)
  }

  const x64BitsSignatures = [
    'x86_64',
    'x86-64',
    'Win64',
    'x64;',
    'amd64',
    'AMD64',
    'WOW64',
    'x64_64',
    'ia64',
    'sparc64',
    'ppc64',
    'IRIX64',
  ]

  return toCheck.some((entity) =>
    x64BitsSignatures.some((x64BitsSignature) =>
      entity.toLowerCase().includes(x64BitsSignature.toLowerCase()),
    ),
  )
})()

/**
 * @see https://www.amitmerchant.com/detect-device-os-javascript/
 * @see https://github.com/MikeKovarik/platform-detect/blob/master/os.mjs
 * */
export const osDesktopPlatform = (() => {
  if (userAgent.match(/Linux/i)) {
    return DesktopPlatform.Linux
  }

  if (userAgent.match(/Mac/i)) {
    return DesktopPlatform.MacOs
  }

  if (userAgent.match(/Windows/i)) {
    return is64BitsSystemArchitecture
      ? DesktopPlatform.Windows64
      : DesktopPlatform.Windows32
  }

  return null
})()

export const windowsPlatforms = [
  DesktopPlatform.Windows64,
  DesktopPlatform.Windows32,
]

export const isWindows = windowsPlatforms.includes(osDesktopPlatform)
