import React from 'react'

import SecondaryButton from '../../../../buttons/SecondaryButton'
import ToggleLike from '../../../../devices/ToggleLike'
import ChangeStatus from '../../../../devices/ChangeStatus'
import { CommunityProfileStatus } from '../../../../../constants/profiles'
import RenderIf from '../../../../hoc/RenderIf'

const UsersProfilesActionBody = ({ rowData: { id, status } }) => {
  const isPending = status === CommunityProfileStatus.Pending
  const isPublished = status === CommunityProfileStatus.Published

  return (
    <div className="settings-button-block">
      <div className="p-flex">
        <SecondaryButton
          isAbsolute={false}
          to={`/community/profile/${id}`}
          icon="pi pi-cloud-download"
          overrideClassName="common-button-rounded success-round-button ml-3"
        />
      </div>
      <RenderIf condition={isPublished}>
        <div className="p-flex">
          <ToggleLike id={id} className="ml-3" />
        </div>
      </RenderIf>
      <RenderIf condition={isPending}>
        <ChangeStatus id={id} className="ml-4" btnClassName="with-hover" />
      </RenderIf>
    </div>
  )
}

export default UsersProfilesActionBody
