import React from 'react'

import './CommonCard.scss'
import { bindClassName } from '../../../utils/react'

const CommonCard = ({ className, children, cardStyle, icon, title = '' }) => (
  <div {...bindClassName('common-card-wrapper', [className])} style={cardStyle}>
    <div className="p-card-title p-grid p-align-center p-col-12 px-2">
      {icon} {title}
    </div>
    {children}
  </div>
)

export default CommonCard
