import React, { useEffect } from 'react'
import { inject } from 'mobx-react'

import usePagination from '../../../../hooks/table/usePagination'
import View from './View'
import FilterForm from './FilterForm'

const AppsPage = (props) => {
  const {
    cleanUp,
    getApplicationPlatforms,
    applicationPlatformsCleanUp,
  } = props

  const pagination = usePagination(cleanUp)

  useEffect(() => {
    getApplicationPlatforms()

    return applicationPlatformsCleanUp
  }, [])

  return (
    <>
      <FilterForm {...pagination} />
      <View {...pagination} />
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.ApplicationsStore.cleanUp,
  getApplicationPlatforms: stores.ApplicationPlatformsStore.getAll,
  applicationPlatformsCleanUp: stores.ApplicationPlatformsStore.cleanUp,
}))

export default withData(AppsPage)
