import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { MdSecurity } from 'react-icons/md'

import Error from './Error'
/*import SocialButtons from './SocialButtons'*/
import CommonCard from '../../common/CommonCard'
import { pipe } from '../../../utils/function'
import TwoFaSwitch from './TwoFaSwitch'
import TwoFaConfirm from './TwoFaConfirm'

const SecuritySettings = ({ userInfo, isLoading, updateSettings, title }) => {
  const initialValues = useMemo(
    () => ({
      id: userInfo.id,
      enabledSms: false,
      enabled2fa: userInfo.enabled2fa || false,
      twoFactorAuthenticationCode: [],
    }),
    [userInfo],
  )

  return (
    <CommonCard
      title={title}
      icon={<MdSecurity />}
      className="user-profile-card p-mx-auto"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={updateSettings}
      >
        {({ dirty, values, setFieldValue }) => (
          <Form>
            <div className="p-grid">
              <Error />
              <TwoFaSwitch
                setFieldValue={setFieldValue}
                enabledSms={values.enabledSms}
                is2faAlreadyEnabled={userInfo.enabled2fa}
              />
              <TwoFaConfirm />
              {/* <SocialButtons />*/}
              <div className="p-col-12">
                <Button
                  type="submit"
                  label="Save"
                  disabled={!(dirty && !isLoading)}
                  className="p-button-secondary"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CommonCard>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.UsersStore.isLoading,
  userInfo: stores.AuthStore.user,
  updateSettings: stores.UsersStore.updateSettings,
  generate2FA: stores.UsersStore.generate2FA,
}))

export default pipe(observer, withData)(SecuritySettings)
