import React from 'react'
import { inject } from 'mobx-react'

import usePagination from '../../../../hooks/table/usePagination'
import View from './View'
import FilterForm from './FilterForm'
import ExportDialog from './ExportDialog'

const StatisticsDevices = ({ cleanUp }) => {
  const pagination = usePagination(cleanUp)

  return (
    <>
      <FilterForm {...pagination} />
      <View {...pagination} />
      <ExportDialog />
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.DevicesStatisticsStore.cleanUp,
}))

export default withData(StatisticsDevices)
