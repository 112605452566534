import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { inject } from 'mobx-react'

import './assets/styles/index.scss'
import PrivateRoute from './PrivateRoute'
import NotAuthRoute from './NotAuthRoute'
import LoginPage from './components/LoginPage'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import ChangePasswordPage from './components/ChangePasswordPage'
import LogPage from './components/LogPage'
import LogsPage from './components/LogsPage'
import UsersPage from './components/UsersPage'
import UserPage from './components/UserPage'
import ProfilePage from './components/ProfilePage'
import CallbackPage from './components/CallbackPage'
import Layout from './components/hoc/Layout'
import StatisticsPage from './components/StatisticsPage'
import DevicePage from './components/device-pages/DevicePage'
import DevicesPage from './components/device-pages/DevicesPage'
import DevisesListPage from './components/device-pages/DevisesListPage'
import DeviceProfilesPage from './components/device-pages/DeviceProfilesPage'
import FeedbackPage from './components/FeedbackPage'
import DevicePreviewPage from './components/device-pages/DevicePreviewPage'
import DeviceProfilePage from './components/device-pages/DeviceProfilePage'
import CommunityPage from './components/CommunityPage'
import CommunityProfilePage from './components/CommunityProfilePage'
import PromotionsPage from './components/PromotionsPage'
import PromotionPage from './components/PromotionPage'
import DesktopVersionPage from './components/DesktopVersionPage'
import AppsPage from './components/AppsPage'
import ApplicationPage from './components/ApplicationPage'
import ApplicationPlatformPage from './components/ApplicationPlatformPage'
import GamingSets from './components/GamingSets'
import GamingSet from './components/GamingSet'

const App = ({ init }) => {
  useEffect(() => {
    init()
  }, [init])

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/callback" component={CallbackPage} />
          <NotAuthRoute exact path="/" component={LoginPage} />
          <NotAuthRoute
            exact
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <NotAuthRoute
            exact
            path="/change-password/:token"
            component={ChangePasswordPage}
          />
          <PrivateRoute
            exact
            path="/users"
            component={UsersPage}
            title="Users"
          />
          <PrivateRoute
            exact
            path="/users/user"
            component={UserPage}
            title="User"
          />
          <PrivateRoute
            exact
            path="/users/user/:id"
            component={UserPage}
            title="User"
          />
          <PrivateRoute
            exact
            path="/feedback"
            component={FeedbackPage}
            title="Feedback"
          />
          <PrivateRoute
            exact
            path="/devices"
            component={DevicesPage}
            title="Devices"
          />
          <PrivateRoute
            exact
            path="/devices/list"
            component={DevisesListPage}
            title="Devices List"
          />
          <PrivateRoute
            exact
            path="/devices/list/device"
            component={DevicePage}
            title="Device"
          />
          <PrivateRoute
            exact
            path="/devices/list/device/:id"
            component={DevicePage}
            title="Device"
          />
          <PrivateRoute
            exact
            path="/devices/list/device/:id/preview"
            component={DevicePreviewPage}
            title="Device"
          />
          <PrivateRoute
            exact
            path="/devices/profiles"
            component={DeviceProfilesPage}
            title="Device Profiles"
          />
          <PrivateRoute
            exact
            path="/devices/gaming-sets"
            component={GamingSets}
            title="Gaming Sets"
          />
          <PrivateRoute
            exact
            path="/devices/gaming-sets/set/:id"
            component={GamingSet}
            title="Gaming Set"
          />
          <PrivateRoute
            exact
            path="/devices/gaming-sets/set"
            component={GamingSet}
            title="Gaming Set"
          />
          <PrivateRoute
            exact
            path="/devices/profiles/profile"
            component={DeviceProfilePage}
            title="Device Profile"
          />
          <PrivateRoute
            exact
            path="/devices/profiles/profile/:id"
            component={DeviceProfilePage}
            title="Device Profile"
          />
          <PrivateRoute
            exact
            path="/community"
            component={CommunityPage}
            title="community"
          />
          <PrivateRoute
            exact
            path="/community/profile/:id"
            component={CommunityProfilePage}
            title="Community profile"
          />
          <PrivateRoute
            exact
            path="/statistics"
            component={StatisticsPage}
            title="Statistics"
          />
          <PrivateRoute
            exact
            path="/promotions"
            component={PromotionsPage}
            title="Promotions"
          />
          <PrivateRoute
            exact
            path="/promotions/promotion"
            component={PromotionPage}
            title="Promotion"
          />
          <PrivateRoute
            exact
            path="/promotions/promotion/:id"
            component={PromotionPage}
            title="Promotion"
          />
          <PrivateRoute
            exact
            path="/profile"
            component={ProfilePage}
            title="Profile"
          />
          <PrivateRoute
            exact
            path="/desktop-version"
            component={DesktopVersionPage}
            title="Desktop version"
          />
          <PrivateRoute
            exact
            path="/applications"
            component={AppsPage}
            title="Applications"
          />
          <PrivateRoute
            exact
            path="/applications/application"
            component={ApplicationPage}
            title="Application"
          />
          <PrivateRoute
            exact
            path="/applications/application/:id"
            component={ApplicationPage}
            title="Application"
          />
          <PrivateRoute
            exact
            path="/applications/platform"
            component={ApplicationPlatformPage}
            title="Application platform"
          />
          <PrivateRoute
            exact
            path="/applications/platform/:id"
            component={ApplicationPlatformPage}
            title="Application platform"
          />
          <PrivateRoute exact path="/logs" component={LogsPage} title="Logs" />
          <PrivateRoute
            exact
            path="/logs/:id"
            component={LogPage}
            title="Log"
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

const withData = inject((stores) => ({
  init: stores.AuthStore.init,
}))

export default withData(App)
