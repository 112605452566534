import React from 'react'
import { inject, observer } from 'mobx-react'

import SecondaryButton from '../../../../buttons/SecondaryButton'

const Export = ({ isExporting, exportData, hasList }) => (
  <SecondaryButton
    labelKey="export"
    disabled={isExporting || !hasList}
    onClick={exportData}
  />
)

const withData = inject((stores) => ({
  isExporting: stores.DevicesAFKStore.isExporting,
  exportData: stores.DevicesAFKStore.exportData,
  hasList: stores.DevicesAFKStore.hasList,
}))

export default withData(observer(Export))
