import React from 'react'
import { Column } from 'primereact/column'

import { formattedCreatedAt, renderIntoTable } from '../../../../utils/react'
import StatusTemplate from '../../../devices/StatusTemplate'
import UserInfo from '../View/UserInfo'
import Description from '../View/Description'
import Boolean from '../../../table/columns/Boolean'
import { formattedActiveTo } from '../../../../utils/date'
import { MappedApplication } from '../../../applications'

const rowStatusTemplate = renderIntoTable(StatusTemplate)
const avatarTemplate = renderIntoTable(UserInfo)
const descriptionTemplate = renderIntoTable(Description)
const isLightingEffectsOnlyTemplate = renderIntoTable(Boolean, {
  field: 'isLightingEffectsOnly',
})
const applicationTemplate = renderIntoTable(MappedApplication)

export const user = (props) => (
  <Column
    header="User"
    body={avatarTemplate}
    className="little-td-style"
    {...props}
  />
)

export const name = (props) => (
  <Column
    field="name"
    header="Profile Name"
    className="common-td-style"
    {...props}
  />
)

export const desc = (props) => (
  <Column
    field="desc"
    header="Description"
    className="lg-td-style"
    body={descriptionTemplate}
    {...props}
  />
)

export const application = (props) => (
  <Column
    field="ApplicationId"
    header="Application"
    className="common-td-style"
    body={applicationTemplate}
    {...props}
  />
)

export const isLightingEffectsOnly = (props) => (
  <Column
    field="isLightingEffectsOnly"
    header="Lightning effects only?"
    className="middle-td-style"
    body={isLightingEffectsOnlyTemplate}
    {...props}
  />
)

export const activeTo = (props) => (
  <Column
    field="activeTo"
    header="Active to"
    className="created-at-style"
    body={formattedActiveTo}
    {...props}
  />
)

export const likes = (props) => (
  <Column
    field="likes"
    header={<i className="pi pi-thumbs-up" />}
    className="little-td-style"
    {...props}
  />
)

export const downloads = (props) => (
  <Column
    field="downloads"
    header={<i className="pi pi-download" />}
    className="little-td-style"
    {...props}
  />
)

export const status = (props) => (
  <Column
    header="Status"
    className="middle-td-style"
    body={rowStatusTemplate}
    {...props}
  />
)

export const createdAt = (props) => (
  <Column
    className="created-at-style"
    header="Created"
    field="createdAt"
    body={formattedCreatedAt}
    {...props}
  />
)

export const actions = (props) => <Column {...props} />
