import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { BreadCrumb as PrimeBreadCrumb } from 'primereact/breadcrumb'
import { useTranslation } from 'react-i18next'

import { mapToArray } from '../../../utils/array'

export * from './model'

const CustomBreadCrumb = ({ model, wrapperClassName, ...rest }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const home = useMemo(
    () => ({ icon: 'pi pi-home', command: () => history.push('/') }),
    [history],
  )

  const processedModel = useMemo(() => {
    return mapToArray(model).map(({ url, labelKey, ...rest }) => {
      const extra = {}
      if (url) {
        if (url.startsWith('/')) extra.command = () => history.push(url)
        else extra.url = url
      }
      return { ...rest, ...extra, label: t(`breadcrumb.${labelKey}`) }
    })
  }, [model, history])

  return <PrimeBreadCrumb {...rest} home={home} model={processedModel} />
}

export default React.memo(CustomBreadCrumb)
