import React, { useCallback, useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { initialValues, byApplicationData } from './config'
import Loadable from '../../../../../hoc/Loadable'
import CustomRadioButton from '../../../../../common/CusomRadioButton'
import RenderIf from '../../../../../hoc/RenderIf'
import CustomMultiSelect from '../../../../../common/CustomMultiSelect'
import CustomCheckbox from '../../../../../common/CustomCheckbox'
import ButtonSubmit from '../../../../../buttons/ButtonSubmit'

const ExportDialogBody = (props) => {
  const {
    getApplications,
    applicationsCleanUp,
    applications,
    exportData,
    lastGetAllQuery,
    isExporting,
    closeExport,
  } = props

  const { t } = useTranslation()

  useEffect(() => {
    getApplications({ attribute: ['id', 'name'] })

    return applicationsCleanUp
  }, [])

  const onSubmit = useCallback(
    (values) => {
      exportData({
        query: {
          ...lastGetAllQuery,
          ...values,
        },
      })
      closeExport()
    },
    [exportData, lastGetAllQuery, closeExport],
  )

  const formattedApplications = useMemo(() => {
    return [...applications, { name: t('labels.other'), id: null }]
  }, [applications])

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => (
        <Form className="p-grid">
          <Loadable loading={isExporting}>
            {byApplicationData.map((item) => (
              <div className="p-col-12 p-md-6" key={item.labelText}>
                <Field
                  name="byApplication"
                  component={CustomRadioButton}
                  {...item}
                />
              </div>
            ))}
            <RenderIf condition={values.byApplication}>
              <div className="p-col-12">
                <Field
                  name="ApplicationId"
                  options={formattedApplications}
                  optionLabel="name"
                  optionValue="id"
                  labelText="select_applications"
                  component={CustomMultiSelect}
                />
              </div>
              <div className="p-col-12">
                <Field
                  name="showTotal"
                  labelText="show_total"
                  component={CustomCheckbox}
                />
              </div>
            </RenderIf>
            <ButtonSubmit
              dirty
              isLoading={isExporting}
              submitLabelKey="export"
            />
          </Loadable>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  getApplications: stores.ApplicationsStore.getAll,
  applicationsCleanUp: stores.ApplicationsStore.cleanUp,
  applications: stores.ApplicationsStore.list,
  isExporting:
    stores.DevicesStatisticsStore.isExporting ||
    stores.ApplicationsStore.isLoading,
  exportData: stores.DevicesStatisticsStore.exportData,
  lastGetAllQuery: stores.DevicesStatisticsStore.lastGetAllQuery,
  closeExport: stores.DevicesStatisticsStore.closeExport,
}))

export default withData(observer(ExportDialogBody))
