import { observable, action, computed } from 'mobx'

import api from '../../../configs/api'
import Http, { buildQuery } from '../../../helpers/http'
import { Notification, success, error } from '../../../helpers/notifications'
import { injectIntoTemplate } from '../../../utils/string'
import DeviceCategoriesStore from '../DeviceCategories/mobx'
import {
  filesComparator,
  findInCollection,
  findIndexInCollection,
  unique,
} from '../../../utils/array'
import { availableLanguages, fallbackLng } from '../../../constants/languages'
import { ErrorMsg } from '../../../constants/errorMsg'
import { compare } from '../../../utils/object'
import { DeviceTab } from '../DevicePage/DeviceForm/config'

const createI18nItem = (language) => {
  const item = {
    language,
    description: '',
    instructions: null,
  }

  return {
    ...item,
    _initial: { ...item },
  }
}

const pushToTab = (history, tab) => {
  const { pathname } = history.location
  history.push(`${pathname}${buildQuery({ tab })}`)
}

class DeviceProfilesStore {
  resource = `${api.devices}/v1/devices`
  itemUrlTemplate = '/devices/list/device/{id}'
  itemUrlPreview = '/devices/list/device/{id}/preview'

  NOTIFICATION_CREATED = Notification.DEVICE_CREATED
  NOTIFICATION_UPDATED = Notification.DEVICE_UPDATED
  NOTIFICATION_DELETED = Notification.DEVICE_DELETED

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable item = null

  @observable imagesToUpload = []
  @observable imagesToRemove = []

  @observable i18n = []

  @computed get _isLoading() {
    return this.isLoading || DeviceCategoriesStore.isLoading
  }

  @computed get isDirty() {
    return !!(
      this.imagesToUpload.length ||
      this.imagesToRemove.length ||
      this.isI18nDirty
    )
  }

  @computed get isI18nDirty() {
    if (!this.item) {
      return !!this.i18n.length
    }

    return (
      this.item.i18n.length !== this.i18n.length ||
      this.i18n.some(({ _initial, ...rest }) => {
        return (
          !this.item.i18n.find(({ language }) => language === rest.language) ||
          !compare(rest, _initial)
        )
      })
    )
  }

  @computed get images() {
    return (this.item?.images || []).filter(
      ({ filename }) => !this.imagesToRemove.includes(filename),
    )
  }

  processSubmitData = (device) => {
    const data = new FormData()

    const payload = { device }

    if (this.isI18nDirty) {
      payload.i18n = this.prepareI18n(data, 'i18nFiles')
    }

    if (this.imagesToUpload.length) {
      this.prepareImages(data, 'images')
    }

    if (this.imagesToRemove.length) {
      payload.imagesToRemove = this.imagesToRemove
    }

    data.set('payload', JSON.stringify(payload))

    return data
  }

  processItem = (values) => {
    values.i18n = values.i18n || []

    this.i18n = values.i18n.map((item) => ({
      ...item,
      _initial: { ...item },
    }))

    return values
  }

  @action
  getAll = async (fields) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  getOne = async (id) => {
    this.isLoading = true
    const { message = null, result } = await Http.get(`${this.resource}/${id}`)
    this.errorMsg = message
    this.item = result ? this.processItem(result) : null
    this.isLoading = false
  }

  @action
  submitOne = async ({ id, ...values }, { resetForm }, history) => {
    if (!this.validateI18n(history)) return

    this.isLoading = true
    pushToTab(history, DeviceTab.Info)
    values = this.processSubmitData(values)

    let response, onSuccess

    if (id) {
      response = await Http.put(`${this.resource}/${id}`, values)
      onSuccess = async () => {
        resetForm()
        await this.getOne(id)
        success(this.NOTIFICATION_UPDATED)
      }
    } else {
      response = await Http.post(this.resource, values)
      onSuccess = (result) => {
        history.push(this.getItemUrl(result))
        success(this.NOTIFICATION_CREATED)
      }
    }

    const { message = null, result } = response
    this.errorMsg = message
    if (result) {
      if (!this.errorMsg) {
        this.cleanUp()
        onSuccess(result)
      }
    }
    this.isLoading = false
  }

  prepareI18n = (formData, key) => {
    return this.i18n.map(({ _initial, instructions, ...rest }) => {
      if (instructions && instructions instanceof File) {
        rest.filename = instructions.name
        formData.append(key, instructions)
      }
      return rest
    })
  }

  prepareImages = (formData, key) => {
    this.imagesToUpload.forEach((file) => formData.append(key, file))
  }

  @action
  deleteOne = async (id) => {
    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      error(response.message, { path: 'errors' })
    } else {
      success(this.NOTIFICATION_DELETED)
      this.list = this.list.filter((item) => item.id != id)
    }
    this.isLoading = false
  }

  @action
  i18nOnChange = (lng, name, value) => {
    const idx = findIndexInCollection('language', this.i18n, lng)
    if (!~idx) return

    if (name.hasOwnProperty('target')) {
      const e = name
      name = e.target.name
      value = e.target.value
    }

    this.i18n[idx] = {
      ...this.i18n[idx],
      [name]: value,
    }
  }

  @action
  addI18nItem = (lng) => {
    if (
      !availableLanguages.includes(lng) ||
      findInCollection('language', this.i18n, lng)
    )
      return

    this.i18n = [...this.i18n, createI18nItem(lng)]
  }

  @action
  removeI18nItem = (lng) => {
    this.i18n = this.i18n.filter(({ language }) => language !== lng)
  }

  @action
  markImageForRemove = (filename) => {
    this.imagesToRemove.push(filename)
  }

  @action
  addImages = (files) => {
    this.imagesToUpload = unique(
      [...this.imagesToUpload, ...files],
      filesComparator,
    )
  }

  @action
  removeImage = ({ path }) => {
    this.imagesToUpload = this.imagesToUpload.filter(
      (file) => file.path !== path,
    )
  }

  @action
  validateI18n = (history) => {
    if (this.i18n.length) {
      const defaultLngConfig = findInCollection(
        'language',
        this.i18n,
        fallbackLng,
      )

      const error = (errorMsg) => {
        this.errorMsg = errorMsg
        pushToTab(history, DeviceTab.I18n)
        return false
      }

      if (!defaultLngConfig?.instructions) {
        return error(ErrorMsg.DEVICE_INSTRUCTION_DEFAULT_LANGUAGE_IS_REQUIRED)
      }

      if (this.i18n.some(({ instructions }) => !instructions)) {
        return error(ErrorMsg.DEVICE_INSTRUCTION_IS_REQUIRED)
      }
    }

    return true
  }

  getItemUrl = (item) => {
    return injectIntoTemplate(this.itemUrlTemplate, item)
  }

  getPreviewItemUrl = (item) => {
    return injectIntoTemplate(this.itemUrlPreview, item)
  }

  @action
  postSubmit = async ({ id }, history) => {
    if (this.imagesToUpload.length) await this.uploadImages(id, history)
    if (this.imagesToRemove.length) await this.removeImages(id, history)
    if (this.isI18nDirty) await this.handleI18n(id, history)
  }

  @action
  handleI18n = async (id, history) => {
    const data = new FormData()
    const i18n = this.prepareI18n(data, 'index.js')
    data.set('payload', JSON.stringify({ i18n }))
    const { message } = await Http.post(`${this.resource}/${id}/i18n`, data)
    if (message) {
      this.errorMsg = message
      pushToTab(history, DeviceTab.I18n)
    }
  }

  @action
  uploadImages = async (id, history) => {
    const data = new FormData()
    this.prepareImages(data, 'images')
    const { message } = await Http.post(`${this.resource}/${id}/images`, data)
    if (message) {
      this.errorMsg = message
      pushToTab(history, DeviceTab.Images)
    }
  }

  @action
  removeImages = async (id, history) => {
    const query = buildQuery({
      filename: this.imagesToRemove,
    })
    const { message } = await Http.delete(
      `${this.resource}/${id}/images/bulk${query}`,
    )
    if (message) {
      this.errorMsg = message
      pushToTab(history, DeviceTab.Images)
    }
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
    this.imagesToUpload = []
    this.imagesToRemove = []
    this.i18n = []
  }
}

export default new DeviceProfilesStore()
