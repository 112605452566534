import React from 'react'

import './ChangeStatus.scss'
import { bindClassName } from '../../../utils/react'
import ChangeStatusButton from './ChangeStatusButton'
import { CommunityProfileStatus } from '../../../constants/profiles'

const statuses = Object.values(CommunityProfileStatus).filter(
  (status) => status !== CommunityProfileStatus.Pending,
)

const ChangeStatus = ({ className, id, btnClassName }) => (
  <div {...bindClassName('change-status-group', [className])}>
    {statuses.map((status) => (
      <ChangeStatusButton
        className={btnClassName}
        key={status}
        id={id}
        status={status}
      />
    ))}
  </div>
)

export default ChangeStatus
