import * as yup from 'yup'

import DeviceCategoryId from '../../../devices/DeviceCategoryId'
import DeviceProfileId from '../../../devices/DeviceProfileId'
import { ErrorMsg } from '../../../../constants/errorMsg'
import { createShape, devicesGetErrorKey } from '../../../../utils/form'
import CustomCheckbox from '../../../common/CustomCheckbox'
import DeviceInfo from './DeviceInfo'
import DeviceImages from './DeviceImages'
import DeviceDocumentation from './DeviceDocumentation'

const MIN_IDENTIFIER = 0
const MAX_IDENTIFIER = 65535

export const defaultValues = {
  DeviceCategoryId: null,
  company: '',
  model: '',
  vid: '',
  pid: '',
  modelName: '',
  DeviceProfileId: null,
  isPublic: true,
  identifier: null,
}

export const propsMapping = {
  DeviceCategoryId: {
    component: DeviceCategoryId,
  },
  modelName: {
    isRequired: false,
  },
  DeviceProfileId: {
    component: DeviceProfileId,
    noGrid: true,
  },
  isPublic: {
    component: CustomCheckbox,
    wrapperClassName: 'public-control-checkbox',
  },
  identifier: {
    type: 'number',
    min: MIN_IDENTIFIER,
    max: MAX_IDENTIFIER,
  },
}

export const validationSchema = yup
  .object()
  .shape(
    createShape(
      defaultValues,
      propsMapping,
      (key) => ErrorMsg[`DEVICE_${devicesGetErrorKey(key)}_NOT_VALID`],
    ),
  )

export const DeviceTab = {
  Info: 'info',
  Images: 'images',
  I18n: 'i18n',
}

export const tabs = [
  {
    header: 'device_info',
    Component: DeviceInfo,
    tabName: DeviceTab.Info,
  },
  {
    header: 'device_images',
    Component: DeviceImages,
    tabName: DeviceTab.Images,
  },
  {
    header: 'device_documentation',
    Component: DeviceDocumentation,
    tabName: DeviceTab.I18n,
  },
]
