import React from 'react'

import UserAvatar from '../../../../avatars/UserAvatar'

const UserInfo = ({ rowData: { User } }) => {
  const { email, nickname } = User || {}

  return (
    <div>
      <UserAvatar user={User} />
      <span className="user-auth-info">{nickname || email}</span>
    </div>
  )
}

export default UserInfo
