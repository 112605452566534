import React from 'react'

import './ImgPreview.scss'
import { bindClassName } from '../../utils/react'

const ImgPreview = ({ className, ...props }) => (
  <img alt="" {...bindClassName('img-preview', [className])} {...props} />
)

export default ImgPreview
