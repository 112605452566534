import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

import api from '../../../../../configs/api'
import { bindClassName } from '../../../../../utils/react'

const Instructions = ({ language, i18n, id }) => {
  const { t } = useTranslation()

  const filename = useMemo(
    () => i18n.find((el) => el?.language === language)?.instructions?.filename,
    [language, i18n],
  )
  const urlFile = useMemo(
    () => `${api.devices}/v1/devices/${id}/i18n/files/${filename}`,
    [filename],
  )

  const instructions = filename ? 'instructions' : 'no_instructions'

  return (
    <div className="instructions-wrapper">
      <a
        href={urlFile}
        target="_blank"
        {...bindClassName('instructions-download-btn', [
          !filename,
          'base-disabled',
        ])}
      >
        {t(`actions.${instructions}`)}
      </a>
    </div>
  )
}

const withData = inject((stores) => ({
  i18n: stores.DevicesStore.i18n,
  isLoading: stores.DevicesStore.isLoading,
  language: stores.LanguageStore.language,
}))

export default withData(observer(Instructions))
