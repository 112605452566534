import React, { useCallback } from 'react'
import { Field, useField } from 'formik'

import CustomCalendarWithTime from '../../../common/CustomCalendarWithTime'

const calendarProps = {
  readOnlyInput: true,
  touchUI: true,
  appendTo: document.body,
}

const Dates = () => {
  const [{ value: startDate }, , { setValue: setStartDate }] = useField(
    'startDate',
  )
  const [{ value: endDate }, , { setValue: setEndDate }] = useField('endDate')

  const onChange = useCallback(
    (e) => {
      const { value, name } = e.target
      if (!value) return

      if (name === 'startDate') {
        if (endDate && value > endDate) setEndDate(value)
      } else if (name === 'endDate') {
        if (startDate && value < startDate) setStartDate(value)
      }
    },
    [startDate, endDate, setEndDate, setStartDate],
  )

  return (
    <>
      <div className="p-col-12 p-md-6 p-lg-4">
        <Field
          name="startDate"
          labelText="start_time"
          component={CustomCalendarWithTime}
          onChange={onChange}
          {...calendarProps}
        />
      </div>
      <div className="p-col-12 p-md-6 p-lg-4">
        <Field
          name="endDate"
          labelText="end_time"
          component={CustomCalendarWithTime}
          onChange={onChange}
          {...calendarProps}
        />
      </div>
    </>
  )
}

export default React.memo(Dates)
