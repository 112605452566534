import { detectMimeType } from 'libmime'

import { noop } from '../../utils/function'

export const readFile = (
  file,
  { onProgress = noop, encoding = 'utf8' } = {},
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('load', (event) => {
      const result = event.target.result
      resolve(result)
    })

    reader.addEventListener('error', reject)

    reader.addEventListener('progress', (event) => {
      if (event.loaded && event.total) {
        const percent = Math.floor((event.loaded / event.total) * 100)
        onProgress(percent)
      }
    })

    reader.readAsText(file, encoding)
  })
}

export const toFile = (blob, name, opts) => {
  return new File([blob], name, {
    type: detectMimeType(name),
    ...opts,
  })
}
