import React from 'react'

import PageActionsWrapper from '../../common/PageActionsWrapper'
import Export from './Export'

const ButtonsGroup = () => (
  <PageActionsWrapper>
    <Export />
  </PageActionsWrapper>
)

export default ButtonsGroup
