import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'

import { pipe } from '../../../utils/function'
import Error from './Error'
import Nickname, { nicknameShape } from './Nickname'
import MixLogin, { mixLoginShape } from './MixLogin'
import Email, { emailShape } from './Email'
import Password, { passwordShape, confirmPasswordShape } from './Password'
import Loadable from '../../hoc/Loadable'
import Phone from './Phone'

export {
  passwordShape,
  mixLoginShape,
  nicknameShape,
  emailShape,
  confirmPasswordShape,
}

export const shape = {
  ...nicknameShape,
  ...passwordShape,
  ...mixLoginShape,
}

const AuthForm = ({
  isLoading,
  values,
  validationSchema,
  onSubmit,
  submitLabel,
  children,
}) => {
  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <div className="p-grid p-nogutter">
            <Error />
            <Loadable loading={isLoading}>
              {children}
              <div className="p-col-12 p-mt-2">
                <Button
                  className="secondary-btn full-width-btn"
                  type="submit"
                  label={submitLabel}
                  disabled={!(dirty && !isLoading)}
                />
              </div>
            </Loadable>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
}))

const Enhanced = pipe(observer, withData)(AuthForm)

Enhanced.Nickname = Nickname
Enhanced.MixLogin = MixLogin
Enhanced.Email = Email
Enhanced.Phone = Phone
Enhanced.Password = Password

export default Enhanced
