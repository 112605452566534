import React, { useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { availableLanguages } from '../../../../../../constants/languages'
import RenderIf from '../../../../../hoc/RenderIf'
import useToggler from '../../../../../../hooks/useToggler'
import { ErrorMsg } from '../../../../../../constants/errorMsg'
import CustomSelect from '../../../../../common/CustomSelect'
import ButtonSubmit from '../../../../../buttons/ButtonSubmit'

const initialValues = {
  language: null,
}

const validationSchema = yup.object().shape({
  language: yup.string().nullable().required(ErrorMsg.LANGUAGE_INVALID),
})

const AddItemGroup = ({ i18n, addI18nItem }) => {
  const { t } = useTranslation()
  const [isOpen, toggleOpen, , close] = useToggler()

  const options = useMemo(
    () =>
      availableLanguages
        .filter((lng) => !i18n.find(({ language }) => lng === language))
        .map((value) => ({
          label: t(`languages.${value}`),
          value,
        })),
    [i18n, t],
  )

  const onSubmit = useCallback(
    ({ language }) => {
      close()
      addI18nItem(language)
    },
    [addI18nItem, close],
  )

  return (
    <RenderIf condition={!!options.length}>
      <div className="p-d-flex">
        <Button
          type="button"
          onClick={toggleOpen}
          className="success-tertiary-button p-button p-mr-3"
          label="Add"
        />
      </div>
      <Dialog
        appendTo={document.body}
        visible={isOpen}
        onHide={close}
        className="popup-width"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty }) => (
            <Form>
              <div className="p-grid">
                <div className="p-col-12">
                  <Field
                    name="language"
                    labelText="language"
                    component={CustomSelect}
                    options={options}
                  />
                </div>
                <div className="p-col-12">
                  <ButtonSubmit dirty={dirty} submitLabelKey="select" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  i18n: stores.DevicesStore.i18n,
  addI18nItem: stores.DevicesStore.addI18nItem,
}))

export default withData(observer(AddItemGroup))
