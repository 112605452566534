import React from 'react'
import { useTranslation } from 'react-i18next'

const Footer = ({ accept, reject }) => {
  const { t } = useTranslation()

  return (
    <>
      <button
        className="p-button p-component p-confirm-dialog-reject p-button-secondary"
        onClick={reject}
      >
        <span className="p-button-label p-c">{t('actions.no')}</span>
      </button>
      <button
        className="p-button p-component p-confirm-dialog-accept common-btn danger-tertiary-button"
        onClick={accept}
      >
        <span className="p-button-label p-c">{t('actions.yes')}</span>
      </button>
    </>
  )
}

export default Footer
