import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const Description = ({ language, i18n }) => {
  const { t } = useTranslation()

  const content = useMemo(() => {
    const { description } = i18n.find((el) => el?.language === language) || {}

    return (
      description || (
        <span className="default__description-content">
          {t('devices.preview.no_description')}
        </span>
      )
    )
  }, [language, i18n])

  return (
    <div className="description-wrapper">
      <span className="description-header">{t('devices.description')}</span>
      <div className="description-content">{content}</div>
    </div>
  )
}

const withData = inject((stores) => ({
  i18n: stores.DevicesStore.i18n,
  language: stores.LanguageStore.language,
}))

export default withData(observer(Description))
