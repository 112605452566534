import React from 'react'
import { inject, observer } from 'mobx-react'
import { Tag } from 'primereact/tag'

import RenderIf from '../../../hoc/RenderIf'

const LightningEffectsTag = ({ isLightingEffectsOnly }) => (
  <RenderIf condition={isLightingEffectsOnly}>
    <Tag value="Lightning effects only" className="lightning-effects-tag" />
  </RenderIf>
)

const withData = inject((stores) => ({
  isLightingEffectsOnly: stores.CommunityStore.item?.isLightingEffectsOnly,
}))

export default withData(observer(LightningEffectsTag))
