import React from 'react'
import { Card } from 'primereact/card'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import CustomTextarea from '../../common/CustomTextarea'
import CustomInput from '../../common/CustomInput'
import CustomCheckbox from '../../common/CustomCheckbox'
import Links from './Links'
import Dates from './Dates'

const CommonInfo = () => {
  const { t } = useTranslation()

  return (
    <Card title={t('card.title.promotion_settings')}>
      <Dates />
      <div className="p-col-12 p-md-6 p-lg-4">
        <Field
          type="number"
          min={1}
          name="visualizationTime"
          labelText="visualization_time"
          component={CustomInput}
        />
      </div>

      <div className="p-col-12 p-md-8 p-lg-5">
        <Field
          type="text"
          name="content"
          labelText="promo_content"
          component={CustomTextarea}
        />
      </div>
      <div className="p-col-12 p-md-4 p-lg-3">
        <Field
          type="text"
          name="shortText"
          labelText="short_content"
          component={CustomTextarea}
        />
      </div>
      <div className="p-col-12 p-md-4 p-lg-3 p-d-flex mt-3 ml-4">
        <Field
          name="showTextInTray"
          labelText="show_tray"
          component={CustomCheckbox}
        />
      </div>
      <Links />
    </Card>
  )
}

export default CommonInfo
