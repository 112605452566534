const Profile = [{ labelKey: 'profile' }]

const Users = [{ labelKey: 'users', url: '/users' }]

const User = [...Users, { labelKey: 'user' }]

const Devices = [{ labelKey: 'devices', url: '/devices' }]

const DevicesList = [
  ...Devices,
  { labelKey: 'devices_list', url: '/devices/list' },
]

const Device = [...DevicesList, { labelKey: 'device' }]

const DeviceProfiles = [
  ...Devices,
  { labelKey: 'device_profiles', url: '/devices/profiles' },
]

const DeviceProfile = [...DeviceProfiles, { labelKey: 'device_profile' }]

const GamingSets = [
  ...Devices,
  { labelKey: 'gaming_sets', url: '/devices/gaming-sets' },
]

const GamingSet = [...GamingSets, { labelKey: 'gaming_set' }]

const Categories = [...Devices, { labelKey: 'categories' }]

const Logs = [{ labelKey: 'logs', url: '/logs' }]

const Log = [...Logs, { labelKey: 'log' }]

const Community = [{ labelKey: 'community', url: '/community' }]

const CommunityProfile = [...Community, { labelKey: 'community_profile' }]

const Feedback = [{ labelKey: 'feedback' }]

const Promotions = [{ labelKey: 'promotions', url: '/promotions' }]

const Promotion = [...Promotions, { labelKey: 'promotion' }]

const DesktopVersion = [
  { labelKey: 'desktop_version', url: '/desktop-version' },
]

const Statistics = [{ labelKey: 'statistics', url: '/statistics' }]

const Applications = [{ labelKey: 'applications', url: '/applications' }]

const Application = [...Applications, { labelKey: 'application' }]

const ApplicationPlatform = [
  ...Applications,
  { labelKey: 'application_platform' },
]

export const Model = {
  Users,
  User,
  Devices,
  Device,
  DevicesList,
  DeviceProfiles,
  DeviceProfile,
  Categories,
  Profile,
  Logs,
  Log,
  Feedback,
  Community,
  CommunityProfile,
  Promotions,
  Promotion,
  DesktopVersion,
  Statistics,
  Applications,
  Application,
  ApplicationPlatform,
  GamingSets,
  GamingSet,
}
