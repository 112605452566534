import React from 'react'

import useLabel from '../../../../hooks/form/useLabel'

const Item = ({ label, children }) => {
  const { labelText } = useLabel({ labelKey: label })

  return (
    <div className="p-col-12">
      <div className="p-grid">
        <label className="p-col-12 p-md-6 p-grid p-dir-row p-align-center">
          {labelText}
        </label>
        <div className="p-col-12 p-md-6">{children}</div>
      </div>
    </div>
  )
}

export default Item
