import { action, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { error, success, Notification } from '../../helpers/notifications'
import ConfirmDialogStore from '../dialogs/CustomConfirmDialog/mobx'
import { filesComparator, unique } from '../../utils/array'
import { formDataAppendArray } from '../../utils/form'

class DesktopApplicationStore {
  resource = `${api.desktop}/v1/desktop/files`

  NOTIFICATION_CREATED = Notification.DESKTOP_FILES_UPLOADED
  NOTIFICATION_DELETED = Notification.DESKTOP_FILE_DELETED

  @observable isLoading = false
  @observable isSubmitLoading = false
  @observable isModalOpen = false
  @observable files = []
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable uploadErrorMsg = null
  @observable item = null

  getDownloadLink = (id) => {
    return `${this.resource}/${id}/download`
  }

  @action
  toggleModal = () => {
    this.isModalOpen = !this.isModalOpen

    if (!this.isModalOpen) {
      this.uploadErrorMsg = null
      this.files = []
    }
  }

  @action
  uploadFiles = async (history) => {
    this.isSubmitLoading = true

    const formData = formDataAppendArray('files', this.files)
    const { message = null, result } = await Http.post(
      `${this.resource}/bulk`,
      formData,
    )

    this.uploadErrorMsg = message

    if (result) {
      const query = buildQuery({ forceSearch: true })
      history.push(`/desktop-version${query}`)
      this.toggleModal()
      success(this.NOTIFICATION_CREATED)
    }

    this.isSubmitLoading = false
  }

  @action
  addFile = (files) => {
    this.files = unique([...this.files, ...files], filesComparator)
  }

  @action
  removeFile = ({ path }) => {
    this.files = this.files.filter((file) => file.path !== path)
  }

  @action
  getAll = async (fields) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  deleteOne = async (id) => {
    const { isConfirmed } = await ConfirmDialogStore.open({
      header: 'delete_desktop_file_header',
      message: 'delete_desktop_file_message',
    })
    if (!isConfirmed) return

    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      error(response.message, { path: 'errors' })
    } else {
      success(this.NOTIFICATION_DELETED)
      this.list = this.list.filter((item) => item.id != id)
    }
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.isSubmitLoading = false
    this.isModalOpen = false
    this.files = []
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.uploadErrorMsg = null
    this.item = null
  }
}

export default new DesktopApplicationStore()
