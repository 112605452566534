import React, { useEffect } from 'react'
import { inject } from 'mobx-react'
import { Card } from 'primereact/card'

import ForgotPasswordLink from './ForgotPasswordLink'
import SignHeadLine from './Form/SignHeadLine'
import { Action } from './OAuth'
import { bindClassName } from '../../utils/react'

export { Action }

const Auth = ({
  form: Form,
  children,
  cleanUp,
  className,
  withHeadline = true,
}) => {
  useEffect(() => cleanUp, [])

  return (
    <>
      <div {...bindClassName('p-fluid auth-page-wrapper', [className])}>
        <Card className="sign-card relative">
          {withHeadline && <SignHeadLine />}
          {Form && (
            <div className="sign-card-form-block p-col-12">
              <Form />
            </div>
          )}
          {children}
        </Card>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.AuthStore.cleanUp,
}))

const Enhanced = withData(Auth)

Enhanced.ForgotPasswordLink = ForgotPasswordLink

export default Enhanced
