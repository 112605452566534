import React from 'react'
import './ImportProfileModal.scss'
import CommonModalWindow from '../../common/dialogWindow/CommonModalWindow'
import ModalContent from './ModalContent'
import { RiShareBoxLine } from 'react-icons/ri'

const ImportProfileModal = ({ isOpen, toggleOpen }) => (
  <CommonModalWindow
    isActive={isOpen}
    className="share-profile-modal"
    toggleModal={toggleOpen}
    headerKey="import_profile"
    icon={<RiShareBoxLine />}
    withBody
  >
    <ModalContent toggleOpen={toggleOpen} isOpen={isOpen} />
  </CommonModalWindow>
)

export default ImportProfileModal
