import React from 'react'
import { inject, observer } from 'mobx-react'

import './PromotionView.scss'
import ModalContent from './ModalContent'
import { bindClassName } from '../../../utils/react'

const PromotionView = ({ promotionView }) => {
  return (
    <div {...bindClassName('promotion-view-page', [promotionView, 'active'])}>
      <div className="promotion-view-wrapper">
        {promotionView && <ModalContent />}
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  promotionView: stores.PromotionsStore.promotionView,
}))

export default withData(observer(PromotionView))
