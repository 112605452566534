import React from 'react'
import { observer } from 'mobx-react'

import './Configurations.scss'
import Empty from '../../../Empty'
import Condition, { If, Else } from '../../../hoc/Condition'
import { useDeviceProfileContext } from '../../../../context'

const Configurations = () => {
  const { profile, ConfigurationItem } = useDeviceProfileContext()
  const devices = profile?.devices || []

  return (
    <Condition condition={devices.length}>
      <If>
        <div className="configuration-item p-grid">
          {devices.map((profileDevice) => (
            <div
              key={profileDevice.id}
              className="p-col-12 p-sm-6 p-md-4 p-xl-3"
            >
              <ConfigurationItem profileDevice={profileDevice} />
            </div>
          ))}
        </div>
      </If>
      <Else>
        <Empty labelKey="no_profile_devices" />
      </Else>
    </Condition>
  )
}

export default observer(Configurations)
