import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

const FilenameLink = ({ file, resource }) => {
  const { id } = useParams()

  const { name, url } = useMemo(() => {
    if (file instanceof File) {
      return {
        name: file.name,
        url: URL.createObjectURL(file),
      }
    } else {
      return {
        name: file.originalname,
        url: `${resource}/${id}/i18n/files/${file.filename}`,
      }
    }
  }, [file, resource, id])

  return (
    <div className="file-name">
      <a href={url} target="_blank">
        {name}
      </a>
    </div>
  )
}

const withData = inject((stores) => ({
  resource: stores.DevicesStore.resource,
}))

export default withData(observer(FilenameLink))
