import { NotificationManager } from 'react-notifications'

import { SHORT_NOTIFICATION_TIMEOUT } from '../constants'
import { translateError, translateNotification } from './i18n'
import { Notification } from '../constants/notification'

export * from '../constants/notification'

const createHandler = (method) => (
  msg,
  { replacers = {}, title = '', timeout = SHORT_NOTIFICATION_TIMEOUT, cb } = {},
) => {
  msg = translateNotification({ msg, replacers })
  NotificationManager[method](msg, title, timeout, cb)
}

export const success = createHandler('success')
export const error = createHandler('error')
export const warning = createHandler('warning')
export const info = createHandler('info')

export const notifyErrorOccurred = (msg) => {
  error(Notification.ERROR_OCCURRED, {
    replacers: { error: translateError({ msg }) },
  })
}
