import React from 'react'
import { inject, observer } from 'mobx-react'

import SecondaryButton from '../../../buttons/SecondaryButton'

const Export = ({ isExporting, exportUsers }) => (
  <SecondaryButton
    labelKey="export"
    isAbsolute={false}
    disabled={isExporting}
    onClick={exportUsers}
  />
)

const withData = inject((stores) => ({
  isExporting: stores.UsersStore.isExporting,
  exportUsers: stores.UsersStore.exportUsers,
}))

export default withData(observer(Export))
