import React from 'react'
import CommonCard from '../../../common/CommonCard'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { useGamingSetContext } from '../../../../context'
import DeviceBlock from '../../DeviceBlock'

const GamingSetDevicesBlock = ({ toggleOpen }) => {
  const { t } = useTranslation()
  const [GamingSetDevices] = useGamingSetContext()

  return (
    <>
      {GamingSetDevices ? (
        <CommonCard
          title="Gaming Set Devices"
          className="p-grid p-device-card gaming-srt-device-cart"
        >
          <DeviceBlock GamingSetDevices={GamingSetDevices} />
        </CommonCard>
      ) : (
        <Button
          type="button"
          onClick={toggleOpen}
          className="success-tertiary-button p-button p-mr-3"
          label={t('actions.import_devices')}
        />
      )}
    </>
  )
}

export default GamingSetDevicesBlock
