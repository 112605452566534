import React, { useCallback } from 'react'
import { RadioButton } from 'primereact/radiobutton'

import useLabel from '../../../hooks/form/useLabel'
import { bindClassName } from '../../../utils/react'

const CustomRadioButton = ({
  field,
  labelText: labelKey,
  value,
  className,
  onChange,
  disabled,
}) => {
  const id = `${field.name}-${value}`

  const { label } = useLabel({
    labelKey,
    field,
    className: 'ml-2 mb-0',
    htmlFor: id,
  })

  const handleChange = useCallback(
    (e) => {
      e.target.value = value
      field.onChange(e)
      if (onChange) onChange(e)
    },
    [value, field.onChange, onChange],
  )

  return (
    <div
      {...bindClassName(
        'p-d-flex p-dir-row p-align-center custom-radio-button',
        [className],
      )}
    >
      <RadioButton
        inputId={id}
        value={'' + value}
        name={field.name}
        disabled={disabled || field.disabled}
        onChange={handleChange}
        checked={field.value === value}
      />
      {label}
    </div>
  )
}

export default CustomRadioButton
