import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'

import { bindClassName } from '../../../utils/react'
import Logo from '../../Logo'
import UserInfo from '../UserInfo'
import SettingsMenu from './SettingsMenu'

const Header = ({ children, isOpenNav, toggleOpenNav }) => (
  <header>
    <div
      {...bindClassName('burger-menu', [!isOpenNav, 'active'])}
      onClick={toggleOpenNav}
    >
      <MdKeyboardArrowDown />
    </div>
    <div className="nav-container container">
      <Logo />
      <ul {...bindClassName('nav-bar-menu', [!isOpenNav, 'active'])}>
        {children}
      </ul>
    </div>
    <div className="user-profile-block">
      <SettingsMenu />
      <UserInfo />
    </div>
  </header>
)

export default Header
