import React, { useEffect } from 'react'
import { inject } from 'mobx-react'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import PageTabs from '../PageTabs'
import {
  STATISTICS_TABS_SECTION_NAME,
  statisticsTabsConfig,
} from './tabs/config'
import calcTableHeight from '../hoc/calcTableHeight'
import { useCalcTableHeightContext } from '../../context'

const StatisticsPage = (props) => {
  const { getDeviceCategories, deviceCategoriesCleanUp } = props

  const { wrapperRef } = useCalcTableHeightContext()

  useEffect(() => {
    getDeviceCategories()

    return deviceCategoriesCleanUp
  }, [])

  return (
    <>
      <CustomBreadCrumb model={Model.Statistics} />
      <div className="p-fluid page-content-wrapper" ref={wrapperRef}>
        <PageTabs
          section={STATISTICS_TABS_SECTION_NAME}
          tabs={statisticsTabsConfig}
          className=""
        />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
}))

export default withData(calcTableHeight(StatisticsPage))
