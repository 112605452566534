import React from 'react'
import './ImportItem.scss'
import moment from 'moment'
import { GoCalendar } from 'react-icons/go'
import { useGamingSetContext } from '../../../../../context'

const ImportItem = ({ item, ActionBody, toggleOpen }) => {
  const { name, desc, id, createdAt, devices } = item

  const [GamingSetDevices, setGamingSetDevices] = useGamingSetContext()

  const deviceList = devices.map((el, i) => (
    <div className="item-device" key={i}>
      {el?.Device?.DeviceCategory?.name + ' ' + el?.Device?.model}
    </div>
  ))
  if (!deviceList.length) return null

  return (
    <div className="share-item-wrapper">
      <div className="share-item-content">
        <span className="community-item-active-date">
          <GoCalendar />
          <span className="active-date">
            {moment(createdAt).format('DD.MM.YYYY HH:mm')}
          </span>
        </span>
        <div className="share-item-name">{name}</div>
        <div className="share-item-desc">{desc}</div>
        <div className="share-item-devices">{deviceList}</div>
      </div>
      <ActionBody
        toggleOpen={toggleOpen}
        setGamingSetDevices={setGamingSetDevices}
        item={item}
      />
    </div>
  )
}

export default ImportItem
