import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import defaultImg from '../../../../../../assets/images/no-image-available.png'
import { useDeviceProfileContext } from '../../../../../../context'
import { bindClassName } from '../../../../../../utils/react'

const ItemUI = ({ actionUI, profileDevice, deviceResource, onClick }) => {
  const { itemActionType } = useDeviceProfileContext()
  const { t } = useTranslation()

  const { DeviceCategory, id, images, model, company } =
    profileDevice?.Device || {}

  const filename = images?.[0]?.filename
  const url = filename
    ? `${deviceResource}/${id}/images/${filename}`
    : defaultImg

  const style = useMemo(
    () => ({
      backgroundImage: `url("${url}")`,
    }),
    [url],
  )

  return (
    <div
      {...bindClassName('device-config-item', [itemActionType])}
      onClick={onClick}
    >
      <div className="device-config-item__header">
        {DeviceCategory?.name}
        <div {...bindClassName('item-action', [itemActionType])}>
          {actionUI}
        </div>
      </div>
      <div className="device-config-item__body" style={style} />
      <div className="device-config-item__footer">
        {company}
        <span>
          {t('labels.model')} {model}
        </span>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  deviceResource: stores.DevicesStore.resource,
}))

export default withData(observer(ItemUI))
