import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Formik } from 'formik'
import { FaMouse } from 'react-icons/fa'

import CommonCard from '../../../common/CommonCard'
import { defaultValues, validationSchema, tabs } from './config'
import Loadable from '../../../hoc/Loadable'
import { DevicesError } from '../../../messages/Error'
import ButtonSubmit from '../../../buttons/ButtonSubmit'
import useCommonSubmit from '../../../../hooks/form/useCommonSubmit'
import useInitialValues from '../../../../hooks/form/useInitialValues'
import PageTabs from '../../../PageTabs'

const DeviceForm = ({ submitOne, item, isLoading, isDirty }) => {
  const initialValues = useInitialValues(defaultValues, item)
  const onSubmit = useCommonSubmit(item, submitOne)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form className="p-grid p-col-12">
          <DevicesError />
          <Loadable loading={isLoading}>
            <CommonCard
              title="Device info"
              icon={<FaMouse />}
              className="p-grid p-device-card"
            >
              <PageTabs tabs={tabs} section="devices" className="p-col-12" />
            </CommonCard>
            <ButtonSubmit dirty={dirty || isDirty} isLoading={isLoading} />
          </Loadable>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  submitOne: stores.DevicesStore.submitOne,
  isLoading: stores.DevicesStore._isLoading,
  item: stores.DevicesStore.item,
  isDirty: stores.DevicesStore.isDirty,
}))

export default withData(observer(DeviceForm))
