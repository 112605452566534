/**
 * @param template {string}
 * @param replacers {Object}
 * @param ignoreCase {boolean | undefined}
 * @param l {string|undefined} - left interpolation
 * @param r {string|undefined} - right interpolation
 * @return {string}
 * */
import { curry } from './function'
import { platforms } from '../constants/desktop'

export const injectIntoTemplate = (
  template,
  replacers,
  ignoreCase = false,
  l = '{',
  r = '}',
) =>
  Object.entries(replacers).reduce((acc, [key, value]) => {
    const regEx = new RegExp(`${l}${key}${r}`, `g${ignoreCase ? 'i' : ''}`)
    return acc.replace(regEx, value)
  }, template)

export const capitalize = (str) =>
  str[0].toUpperCase() + str.slice(1).toLowerCase()

export const inRange = curry(
  (from, to, value) => +value >= from && +value <= to,
)

export const isValidEmail = (str) => /[A-Za-z]|@/.test(str || '')

export const parseBoolean = (str, allowBoolean = true) => {
  if (typeof str === 'boolean' && allowBoolean) return str

  if (!str || typeof str !== 'string') return null
  if (/true/.test(str)) return true
  if (/false/.test(str)) return false
  return null
}

export const isValidVersion = (value) => {
  return /^(\d+\.)+\d+$/.test((value || '').toString())
}

export const isValidPlatform = (value) => platforms.includes(value)
