export const ErrorMsg = {
  ERROR_OCCURRED: 'ERROR_OCCURRED',
  /**
   * Validation
   */
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  PHONE_REQUIRED: 'PHONE_REQUIRED',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  CONFIRM_PASSWORD_REQUIRED: 'CONFIRM_PASSWORD_REQUIRED',
  CODE_2FA_REQUIRED: 'CODE_2FA_REQUIRED',
  RESET_TOKEN_REQUIRED: 'RESET_TOKEN_REQUIRED',
  ACTION_FORBIDDEN: 'ACTION_FORBIDDEN',
  LANGUAGE_INVALID: 'LANGUAGE_INVALID',
  DEFAULT_LANGUAGE_IS_REQUIRED: 'DEFAULT_LANGUAGE_IS_REQUIRED',
  /**
   * USER
   * */
  FIRST_NAME_LATIN_CHAR: 'FIRST_NAME_LATIN_CHAR',
  LAST_NAME_LATIN_CHAR: 'LAST_NAME_LATIN_CHAR',
  FIRST_NAME_INVALID_LENGTH: 'FIRST_NAME_INVALID_LENGTH',
  LAST_NAME_INVALID_LENGTH: 'LAST_NAME_INVALID_LENGTH',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  CANT_DELETE_THIS_USER: 'CANT_DELETE_THIS_USER',
  USER_FILE_NOT_FOUND: 'USER_FILE_NOT_FOUND',
  USER_AVATAR_NOT_FOUND: 'USER_AVATAR_NOT_FOUND',
  EMAIL_NOT_VALID: 'EMAIL_NOT_VALID',
  PHONE_NOT_VALID: 'PHONE_NOT_VALID',
  FIRST_NAME_NOT_VALID: 'FIRST_NAME_NOT_VALID',
  LAST_NAME_NOT_VALID: 'LAST_NAME_NOT_VALID',
  COUNTRY_REQUIRED: 'COUNTRY_REQUIRED',
  NICKNAME_NOT_VALID: 'NICKNAME_NOT_VALID',
  CURRENT_PASSWORD_NOT_VALID: 'CURRENT_PASSWORD_NOT_VALID',
  NEW_PASSWORD_NOT_VALID: 'NEW_PASSWORD_NOT_VALID',
  PASSWORD_NOT_VALID: 'PASSWORD_NOT_VALID',
  PASSWORD_IS_SHORT: 'PASSWORD_IS_SHORT',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  NO_CURRENT_PASSWORD: 'NO_CURRENT_PASSWORD',
  CONFIRM_PASSWORD_NOT_VALID: 'CONFIRM_PASSWORD_NOT_VALID',
  PASSWORD_NOT_MATCH: 'PASSWORD_NOT_MATCH',
  PASSWORD_MUST_BE_NEW: 'PASSWORD_MUST_BE_NEW',
  USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
  USER_EMAIL_ALREADY_EXIST: 'USER_EMAIL_ALREADY_EXIST',
  USER_NICKNAME_ALREADY_EXIST: 'USER_NICKNAME_ALREADY_EXIST',
  USER_PHONE_ALREADY_EXIST: 'USER_PHONE_ALREADY_EXIST',
  USER_NOT_ADMIN: 'USER_NOT_ADMIN',
  USER_NOT_SUPER_ADMIN: 'USER_NOT_SUPER_ADMIN',
  OAUTH_CANT_UNLINK_SINGLE_ACCOUNT_NO_PASSWORD:
    'OAUTH_CANT_UNLINK_SINGLE_ACCOUNT_NO_PASSWORD',
  OAUTH_CANCELED: 'OAUTH_CANCELED',
  CANT_GRANT_SUPER_ADMIN_WITHOUT_ADMIN: 'CANT_GRANT_SUPER_ADMIN_WITHOUT_ADMIN',
  USER_AVATAR_HAS_NOT_BEEN_CHANGED: 'USER_AVATAR_HAS_NOT_BEEN_CHANGED',
  USER_ACCOUNT_NOT_VERIFIED: 'USER_ACCOUNT_NOT_VERIFIED',
  USER_ACCOUNT_ALREADY_VERIFIED: 'USER_ALREADY_VERIFIED_ACCOUNT',
  /**
   * AUTH
   * */
  AUTH_FAILED: 'AUTH_FAILED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EMPTY_TOKEN: 'EMPTY_TOKEN',
  LOW_PERMISSIONS: 'LOW_PERMISSIONS',
  INNER_REQUEST_SIGN_NOT_VALID: 'INNER_REQUEST_SIGN_NOT_VALID',
  LOGIN_INFO_INVALID: 'LOGIN_INFO_INVALID',
  /**
   * SMS
   * */
  CANT_SEND_SMS_RIGHT_NOW: 'CANT_SEND_SMS_RIGHT_NOW',
  CANT_REGENERATE_SMS_RIGHT_NOW: 'CANT_REGENERATE_SMS_RIGHT_NOW',
  SMS_2FA_DISABLED: 'SMS_2FA_DISABLED',
  SMS_2FA_NO_PHONE: 'SMS_2FA_NO_PHONE',
  CODE_REQUIRED: 'CODE_REQUIRED',
  CODE_INVALID: 'CODE_INVALID',
  /**
   * 2FA Google
   * */
  GOOGLE_2FA_ENABLED: 'GOOGLE_2FA_ENABLED',
  WRONG_2FA: 'WRONG_2FA',
  /**
   * LOG
   * */
  LOG_NOT_FOUND: 'LOG_NOT_FOUND',
  /**
   * FILES
   * */
  FILES_REQUIRED: 'FILES_REQUIRED',
  FILE_REQUIRED: 'FILE_REQUIRED',
  EXCEEDED_FILE_LIMIT: 'EXCEEDED_FILE_LIMIT',
  EXCEEDED_FILES_LIMIT: 'EXCEEDED_FILES_LIMIT',
  EXCEEDED_FILES_QUANTITY: 'EXCEEDED_FILES_QUANTITY',
  UNACCEPTABLE_FILE: 'UNACCEPTABLE_FILE',
  /**
   * DEVICE CATEGORIES
   * */
  DEVICE_CATEGORY_NOT_FOUND: 'DEVICE_CATEGORY_NOT_FOUND',
  /**
   * FEEDBACK
   * */
  FEEDBACK_NOT_FOUND: 'FEEDBACK_NOT_FOUND',
  FEEDBACK_RATE_LIMIT_EXCEEDED: 'FEEDBACK_RATE_LIMIT_EXCEEDED',
  FEEDBACK_ANSWER_REQUIRED: 'FEEDBACK_ANSWER_REQUIRED',
  /**
   * DEVICE
   * */
  DEVICE_CATEGORY_NOT_VALID: 'DEVICE_CATEGORY_NOT_VALID',
  DEVICE_COMPANY_NOT_VALID: 'DEVICE_COMPANY_NOT_VALID',
  DEVICE_MODEL_NOT_VALID: 'DEVICE_MODEL_NOT_VALID',
  DEVICE_VID_NOT_VALID: 'DEVICE_VID_NOT_VALID',
  DEVICE_PID_NOT_VALID: 'DEVICE_PID_NOT_VALID',
  DEVICE_MANUFACTURER_NOT_VALID: 'DEVICE_MANUFACTURER_NOT_VALID',
  DEVICE_PROFILE_NOT_VALID: 'DEVICE_PROFILE_NOT_VALID',
  DEVICE_DESCRIPTION_NOT_VALID: 'DEVICE_DESCRIPTION_NOT_VALID',
  DEVICE_ISPUBLIC_NOT_VALID: 'DEVICE_ISPUBLIC_NOT_VALID',
  DEVICE_IDENTIFIER_NOT_VALID: 'DEVICE_IDENTIFIER_NOT_VALID',
  DEVICE_INSTRUCTION_DEFAULT_LANGUAGE_IS_REQUIRED:
    'DEVICE_INSTRUCTION_DEFAULT_LANGUAGE_IS_REQUIRED',
  DEVICE_INSTRUCTION_IS_REQUIRED: 'DEVICE_INSTRUCTION_IS_REQUIRED',
  /**
   * DEVICE PROFILE
   * */
  DEVICE_PROFILE_NOT_FOUND: 'DEVICE_PROFILE_NOT_FOUND',
  DEVICE_PROFILE_NAME_NOT_VALID: 'DEVICE_PROFILE_NAME_NOT_VALID',
  DEVICE_PROFILE_CATEGORY_NOT_VALID: 'DEVICE_PROFILE_CATEGORY_NOT_VALID',
  DEVICE_PROFILE_SENSOR_NOT_VALID: 'DEVICE_PROFILE_SENSOR_NOT_VALID',
  DEVICE_PROFILE_CONFIG_NOT_VALID: 'DEVICE_PROFILE_CONFIG_NOT_VALID',
  /**
   * DROPZONE
   * */
  DROPZONE_FILES_LIMIT_EXCEEDED: 'DROPZONE_FILES_LIMIT_EXCEEDED',
  DROPZONE_FILE_LIMIT_EXCEEDED: 'DROPZONE_FILE_LIMIT_EXCEEDED',
  DROPZONE_FILES_REJECTED: 'DROPZONE_FILES_REJECTED',
  DROPZONE_UNACCEPTABLE_FILES: 'DROPZONE_UNACCEPTABLE_FILES',
  DROPZONE_FILES_QUANTITY_EXCEEDED: 'DROPZONE_FILES_QUANTITY_EXCEEDED',

  /**
   * COMMUNITY PROFILES
   * */
  COMMUNITY_DESCRIPTION_INVALID: 'COMMUNITY_DESCRIPTION_INVALID',
  COMMUNITY_PROFILE_NOT_FOUND: 'COMMUNITY_PROFILE_NOT_FOUND',
  COMMUNITY_PROFILE_AT_LEAST_ONE_CONFIG_NEEDED:
    'COMMUNITY_PROFILE_AT_LEAST_ONE_CONFIG_NEEDED',
  COMMUNITY_PROFILE_ALREADY_EXISTS: 'COMMUNITY_PROFILE_ALREADY_EXISTS',
  COMMUNITY_PROFILE_ALREADY_EXISTS_PENDING_BY_CURRENT_USER:
    'COMMUNITY_PROFILE_ALREADY_EXISTS_PENDING_BY_CURRENT_USER',
  COMMUNITY_PROFILE_LIKE_ALREADY_EXISTS:
    'COMMUNITY_PROFILE_LIKE_ALREADY_EXISTS',
  COMMUNITY_PROFILE_LIKE_NOT_FOUND: 'COMMUNITY_PROFILE_LIKE_NOT_FOUND',
  REJECT_REASON_REQUIRED: 'REJECT_REASON_REQUIRED',

  /** GAMING SET ... */
  GAMING_SET_DESCRIPTION_INVALID: 'GAMING_SET_DESCRIPTION_INVALID',
  GAMING_SET_NAME_INVALID: 'GAMING_SET_NAME_INVALID',

  /**
   * USER COMMUNITY DEVICES
   * */
  COMMUNITY_PROFILE_DEVICE_NOT_FOUND: 'COMMUNITY_PROFILE_DEVICE_NOT_FOUND',
  COMMUNITY_PROFILE_DEVICE_ALREADY_EXISTS:
    'COMMUNITY_PROFILE_DEVICE_ALREADY_EXISTS',
  /**
   * PROMOTION
   */
  PROMOTION_CONTENT_REQUIRED: 'PROMOTION_CONTENT_REQUIRED',
  PROMOTION_CONTENT_INVALID: 'PROMOTION_CONTENT_INVALID',
  PROMOTION_SHORT_TEXT_INVALID: 'PROMOTION_SHORT_TEXT_INVALID',
  PROMOTION_LINK_TEXT_INVALID: 'PROMOTION_LINK_TEXT_INVALID',
  PROMOTION_SHORT_TEXT_REQUIRED: 'PROMOTION_SHORT_TEXT_REQUIRED',
  PROMOTION_START_DATE_REQUIRED: 'PROMOTION_START_DATE_REQUIRED',
  PROMOTION_START_DATE_INVALID: 'PROMOTION_START_DATE_INVALID',
  PROMOTION_END_DATE_REQUIRED: 'PROMOTION_END_DATE_REQUIRED',
  PROMOTION_END_DATE_INVALID: 'PROMOTION_END_DATE_INVALID',
  PROMOTION_VISUALIZATION_TIME_REQUIRED:
    'PROMOTION_VISUALIZATION_TIME_REQUIRED',
  PROMOTION_VISUALIZATION_TIME_INVALID: 'PROMOTION_VISUALIZATION_TIME_INVALID',
  PROMOTION_LINK_TEXT_REQUIRED: 'PROMOTION_LINK_TEXT_REQUIRED',
  PROMOTION_LINK_TO_REQUIRED: 'PROMOTION_LINK_TO_REQUIRED',
  PROMOTION_LINK_TO_INVALID: 'PROMOTION_LINK_TO_INVALID',
  PROMOTION_TARGETS_REQUIRED: 'PROMOTION_TARGETS_REQUIRED',
  PROMOTION_TARGETS_INVALID: 'PROMOTION_TARGETS_INVALID',
  PROMOTION_MEDIA_LINK_REQUIRED: 'PROMOTION_MEDIA_LINK_REQUIRED',
  PROMOTION_MEDIA_LINK_INVALID: 'PROMOTION_MEDIA_LINK_INVALID',
  PROMOTION_MEDIA_LINK_VIDEO_INVALID: 'PROMOTION_MEDIA_LINK_VIDEO_INVALID',
  PROMOTION_MEDIA_FILE_REQUIRED: 'PROMOTION_MEDIA_FILE_REQUIRED',

  /**
   * DESKTOP
   */
  DESKTOP_INVALID_FILE_NAME: 'DESKTOP_INVALID_FILE_NAME',
  DESKTOP_NOT_FOUND: 'DESKTOP_NOT_FOUND',
  DESKTOP_ALREADY_EXISTS: 'DESKTOP_ALREADY_EXISTS',
  DESKTOP_FILE_NOT_FOUND: 'DESKTOP_FILE_NOT_FOUND',
  DESKTOP_EXCEEDED_FILES_QUANTITY_IN_STORAGE:
    'DESKTOP_EXCEEDED_FILES_QUANTITY_IN_STORAGE',
  /**
   * APPLICATION PLATFORM
   * */
  APPLICATION_PLATFORM_NOT_FOUND: 'APPLICATION_PLATFORM_NOT_FOUND',
  APPLICATION_PLATFORM_ALREADY_EXISTS: 'APPLICATION_PLATFORM_ALREADY_EXISTS',
  APPLICATION_PLATFORM_NAME_NOT_VALID: 'APPLICATION_PLATFORM_NAME_NOT_VALID',
  /**
   * APPLICATION
   * */
  APPLICATION_NOT_FOUND: 'APPLICATION_NOT_FOUND',
  APPLICATION_ALREADY_EXISTS: 'APPLICATION_ALREADY_EXISTS',
  APPLICATION_NAME_NOT_VALID: 'APPLICATION_NAME_NOT_VALID',
  APPLICATION_APPLICATIONPLATFORMID_NOT_VALID:
    'APPLICATION_APPLICATIONPLATFORMID_NOT_VALID',
  APPLICATION_REALID_NOT_VALID: 'APPLICATION_REALID_NOT_VALID',
  NAME_REQUIRED() {},
}
