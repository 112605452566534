import React, { useCallback, useMemo } from 'react'
import { Galleria } from 'primereact/galleria'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import api from '../../../../configs/api'
import './Gallery.scss'
import { detectDefaultDeviceImage } from '../../../../helpers/devices'
import RenderIf from '../../../hoc/RenderIf'

const style = { maxWidth: '640px' }

const Gallery = ({ item }) => {
  const { t } = useTranslation()

  const { DeviceCategory, id } = item || {}
  const deviceCategory = DeviceCategory?.name

  const altImg = useMemo(() => detectDefaultDeviceImage(deviceCategory), [
    deviceCategory,
  ])

  const hasImages = !!item?.images?.length

  const deviceImagesList = useMemo(() => (hasImages ? item.images : [altImg]), [
    item,
    altImg,
    hasImages,
  ])

  const itemTemplate = useCallback(
    ({ filename }) => {
      const src = hasImages
        ? `${api.devices}/v1/devices/${id}/images/${filename}`
        : filename

      return (
        <>
          <img src={src} alt="img" />
          <RenderIf condition={!hasImages}>
            <span className="alt-image-preview">
              {t('devices.preview.no_images')}
            </span>
          </RenderIf>
        </>
      )
    },
    [hasImages],
  )

  return (
    item && (
      <Galleria
        className="devices-img-gallery p-col-12 p-md-6"
        value={deviceImagesList}
        numVisible={5}
        circular
        style={style}
        showItemNavigators
        showThumbnails={false}
        showItemNavigatorsOnHover
        showIndicators
        item={itemTemplate}
      />
    )
  )
}

const withData = inject((stores) => ({
  item: stores.DevicesStore.item,
}))

export default withData(observer(Gallery))
