export const deviceField = {
  DeviceCategoryId: null,
  deviceModel: '',
  config: '',
}

export const DEFAULT_DEVICE_COUNT = 2

export const MAX_SET_DESCRIPTION_LENGTH = 100

export const MAX_SET_NAME_LENGTH = 40
