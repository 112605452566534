import Applications from './Applications'
import ApplicationPlatforms from './ApplicationPlatforms'

export const TABS_SECTION_NAME = 'applications'

export const ApplicationsTab = {
  Applications: 'applications',
  ApplicationPlatforms: 'application_platforms',
}

export const tabsConfig = [
  {
    tabName: ApplicationsTab.Applications,
    Component: Applications,
  },
  {
    tabName: ApplicationsTab.ApplicationPlatforms,
    Component: ApplicationPlatforms,
  },
]
