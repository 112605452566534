import { useEffect, useMemo } from 'react'
import { ErrorMsg } from '../../../constants/errorMsg'
import { MAX_SET_DESCRIPTION_LENGTH, MAX_SET_NAME_LENGTH } from '../config'
import * as yup from 'yup'

export default (shape, cleanUp, getAll) => {
  useEffect(() => {
    getAll()
    return () => cleanUp()
  }, [])

  return useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(ErrorMsg.NAME_REQUIRED)
          .max(MAX_SET_NAME_LENGTH, ErrorMsg.GAMING_SET_DESCRIPTION_INVALID),
        desc: yup
          .string()
          .trim()
          .required(ErrorMsg.DESC_REQUIRED)
          .max(
            MAX_SET_DESCRIPTION_LENGTH,
            ErrorMsg.GAMING_SET_DESCRIPTION_INVALID,
          ),
        ...(shape || {}),
      }),
    [shape],
  )
}
