import React from 'react'
import { inject, observer } from 'mobx-react'

import { pipe } from '../../../../utils/function'
import ReactJson from 'react-json-view'
import { Card } from 'primereact/card'

const ActionData = ({ actionData }) => (
  <Card title="Action data" className="p-col-12 p-md-6 p-xl-4">
    <ReactJson
      src={JSON.parse(actionData || '{}')}
      theme="pop"
      displayObjectSize={false}
      displayDataTypes={false}
    />
  </Card>
)

const withData = inject((stores) => ({
  actionData: stores.LogsStore.log.actionData,
}))

export default pipe(observer, withData)(ActionData)
