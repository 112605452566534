import React from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { bindClassName } from '../../../utils/react'

const ButtonSubmit = ({
  withWrapper = true,
  submitLabelKey = 'save',
  dirty,
  isLoading,
  className,
  wrapperClassName,
  ...rest
}) => {
  const { t } = useTranslation()

  const button = (
    <Button
      {...bindClassName('p-button-secondary', [className])}
      type="submit"
      label={t(`actions.${submitLabelKey}`)}
      disabled={!(dirty && !isLoading)}
      {...rest}
    />
  )

  if (!withWrapper) return button

  return (
    <div {...bindClassName('p-col-12 p-my-2', [wrapperClassName])}>
      {button}
    </div>
  )
}

export default ButtonSubmit
