import { observable, action } from 'mobx'

import api from '../../../../configs/api'
import CrudStore from '../../../../stores/common/CrudStore'

class DevicesStatisticsStore extends CrudStore {
  resource = `${api.devices}/v1/admin/device-statistics`
  fallbackCsvExportName = 'devices-statistics'

  @observable isExportOpen = false

  @action
  openExport() {
    this.isExportOpen = true
  }

  @action
  closeExport() {
    this.isExportOpen = false
  }

  @action
  cleanUp() {
    super.cleanUp()
    this.isExportOpen = false
  }
}

export default new DevicesStatisticsStore()
