import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../../../common/CustomInput'
import { pipe } from '../../../../../utils/function'
import CommonFiltersForm from '../../../../table/CommonFiltersForm'
import CustomCalendarInput from '../../../../common/CustomCalendarInput'
import { bindClassName } from '../../../../../utils/react'
import CustomTriStateCheckbox from '../../../../common/CustomTriStateCheckbox'
import { useCalcTableHeightContext } from '../../../../../context'

const initialValues = {
  startDate: null,
  endDate: null,
  shortText: '',
  isActive: null,
  isArchived: null,
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll }) => {
  const { filterRef } = useCalcTableHeightContext()

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <div className={className}>
        <Field
          name="startDate"
          placeholder="start_date"
          component={CustomCalendarInput}
        />
      </div>
      <div className={className}>
        <Field
          name="endDate"
          placeholder="end_date"
          component={CustomCalendarInput}
        />
      </div>
      <div className={className}>
        <Field
          name="shortText"
          component={CustomInput}
          placeholder="short_content"
        />
      </div>
      <div {...bindClassName('p-d-flex', [className])}>
        <Field
          className="mr-4"
          name="isActive"
          labelText="isActive"
          component={CustomTriStateCheckbox}
        />
        <Field
          className="mr-4"
          name="isArchived"
          labelText="archived"
          component={CustomTriStateCheckbox}
        />
      </div>
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.PromotionsStatisticsStore.isLoading,
  getAll: stores.PromotionsStatisticsStore.getAll,
}))

export default pipe(observer, withData)(FilterForm)
