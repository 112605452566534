export default [
  {
    name: 'Australia',
    isoCode: 'au',
    phoneCode: '61',
    mask: '+61-_-____-____',
  },
  {
    name: 'Austria',
    isoCode: 'at',
    phoneCode: '43',
    mask: '+43(___)___-____',
  },
  {
    name: 'Azerbaijan',
    isoCode: 'az',
    phoneCode: '994',
    mask: '+994-__-___-__-__',
  },
  {
    name: 'Albania',
    isoCode: 'al',
    phoneCode: '355',
    mask: '+355(___)___-___',
  },
  {
    name: 'Algeria',
    isoCode: 'dz',
    phoneCode: '213',
    mask: '+213-__-___-____',
  },
  {
    name: 'American Samoa',
    isoCode: 'as',
    phoneCode: '1684',
    mask: '+1(684)___-____',
  },
  {
    name: 'Anguilla',
    isoCode: 'ai',
    phoneCode: '1264',
    mask: '+1(264)___-____',
  },
  {
    name: 'Angola',
    isoCode: 'ao',
    phoneCode: '244',
    mask: '+244(___)___-___',
  },
  {
    name: 'Andorra',
    isoCode: 'ad',
    phoneCode: '376',
    mask: '+376-___-___',
  },
  {
    name: 'Antarctica',
    isoCode: 'aq',
    phoneCode: '6721',
    mask: '+672-1__-___',
  },
  {
    name: 'Antigua and Barbuda',
    isoCode: 'ag',
    phoneCode: '1268',
    mask: '+1(268)___-____',
  },
  {
    name: 'Arab Emirates',
    isoCode: 'ae',
    phoneCode: '971',
    mask: '+971-_-___-____',
  },
  {
    name: 'Argentina',
    isoCode: 'ar',
    phoneCode: '54',
    mask: '+54(___)___-____',
  },
  {
    name: 'Armenia',
    isoCode: 'am',
    phoneCode: '374',
    mask: '+374-__-___-___',
  },
  {
    name: 'Aruba',
    isoCode: 'aw',
    phoneCode: '297',
    mask: '+297-___-____',
  },
  {
    name: 'Afghanistan',
    isoCode: 'af',
    phoneCode: '93',
    mask: '+93-__-___-____',
  },
  {
    name: 'The Bahamas',
    isoCode: 'bs',
    phoneCode: '1242',
    mask: '+1(242)___-____',
  },
  {
    name: 'Bangladesh',
    isoCode: 'bd',
    phoneCode: '880',
    mask: '+880-__-___-___',
  },
  {
    name: 'Barbados',
    isoCode: 'bb',
    phoneCode: '1246',
    mask: '+1(246)___-____',
  },
  {
    name: 'Bahrain',
    isoCode: 'bh',
    phoneCode: '973',
    mask: '+973-____-____',
  },
  {
    name: 'Belize',
    isoCode: 'bz',
    phoneCode: '501',
    mask: '+501-___-____',
  },
  {
    name: 'Byelorussia',
    isoCode: 'by',
    phoneCode: '375',
    mask: '+375(__)___-__-__',
  },
  {
    name: 'Belgium',
    isoCode: 'be',
    phoneCode: '32',
    mask: '+32(___)___-___',
  },
  {
    name: 'Benin',
    isoCode: 'bj',
    phoneCode: '229',
    mask: '+229-__-__-____',
  },
  {
    name: 'Bermuda',
    isoCode: 'bm',
    phoneCode: '1441',
    mask: '+1(441)___-____',
  },
  {
    name: 'Burma (Myanmar)',
    isoCode: 'mm',
    phoneCode: '95',
    mask: '+95-___-___',
  },
  {
    name: 'Bulgaria',
    isoCode: 'bg',
    phoneCode: '359',
    mask: '+359(___)___-___',
  },
  {
    name: 'Bolivia',
    isoCode: 'bo',
    phoneCode: '591',
    mask: '+591-_-___-____',
  },
  {
    name: 'Bosnia and Herzegovina',
    isoCode: 'ba',
    phoneCode: '387',
    mask: '+387-__-____',
  },
  {
    name: 'Botswana',
    isoCode: 'bw',
    phoneCode: '267',
    mask: '+267-__-___-___',
  },
  {
    name: 'Brazil',
    isoCode: 'br',
    phoneCode: '55',
    mask: '+55-__-____-____',
  },
  {
    name: 'Brunei Darussalam',
    isoCode: 'bn',
    phoneCode: '673',
    mask: '+673-___-____',
  },
  {
    name: 'Burkina Faso',
    isoCode: 'bf',
    phoneCode: '226',
    mask: '+226-__-__-____',
  },
  {
    name: 'Burundi',
    isoCode: 'bi',
    phoneCode: '257',
    mask: '+257-__-__-____',
  },
  {
    name: 'Butane',
    isoCode: 'bt',
    phoneCode: '975',
    mask: '+975-_-___-___',
  },
  {
    name: 'Vanuatu',
    isoCode: 'vu',
    phoneCode: '678',
    mask: '+678-_____',
  },
  {
    name: 'The Vatican',
    isoCode: 'va',
    phoneCode: '396698',
    mask: '+39-6-698-_____',
  },
  {
    name: 'United Kingdom',
    isoCode: 'gb',
    phoneCode: '44',
    mask: '+44-__-____-____',
  },
  {
    name: 'Hungary',
    isoCode: 'hu',
    phoneCode: '36',
    mask: '+36(___)___-___',
  },
  {
    name: 'Venezuela',
    isoCode: 've',
    phoneCode: '58',
    mask: '+58(___)___-____',
  },
  {
    name: 'Virgin Islands (America)',
    isoCode: 'vi',
    phoneCode: '1340',
    mask: '+1(340)___-____',
  },
  {
    name: 'Virgin Islands (Britain)',
    isoCode: 'vg',
    phoneCode: '1284',
    mask: '+1(284)___-____',
  },
  {
    name: 'East Timor',
    isoCode: 'tl',
    phoneCode: '670',
    mask: '+670-___-____',
  },
  {
    name: 'Vietnam',
    isoCode: 'vn',
    phoneCode: '84',
    mask: '+84-__-____-___',
  },
  {
    name: 'Gabon',
    isoCode: 'ga',
    phoneCode: '241',
    mask: '+241-_-__-__-__',
  },
  {
    name: 'Haiti',
    isoCode: 'ht',
    phoneCode: '509',
    mask: '+509-__-__-____',
  },
  {
    name: 'Guyana',
    isoCode: 'gy',
    phoneCode: '592',
    mask: '+592-___-____',
  },
  {
    name: 'The Gambia',
    isoCode: 'gm',
    phoneCode: '220',
    mask: '+220(___)__-__',
  },
  {
    name: 'Ghana',
    isoCode: 'gh',
    phoneCode: '233',
    mask: '+233(___)___-___',
  },
  {
    name: 'Guadeloupe',
    isoCode: 'fr',
    phoneCode: '590',
    mask: '+590(___)___-___',
  },
  {
    name: 'Guatemala',
    isoCode: 'gt',
    phoneCode: '502',
    mask: '+502-_-___-____',
  },
  {
    name: 'Guinea',
    isoCode: 'gn',
    phoneCode: '224',
    mask: '+224-__-___-___',
  },
  {
    name: 'Guinea-Bissau',
    isoCode: 'gw',
    phoneCode: '245',
    mask: '+245-_-______',
  },
  {
    name: 'Germany',
    isoCode: 'de',
    phoneCode: '49',
    mask: '+49-___-___',
  },
  {
    name: 'Gibraltar',
    isoCode: 'gi',
    phoneCode: '350',
    mask: '+350-___-_____',
  },
  {
    name: 'Honduras',
    isoCode: 'hn',
    phoneCode: '504',
    mask: '+504-____-____',
  },
  {
    name: 'Hong Kong',
    isoCode: 'hk',
    phoneCode: '852',
    mask: '+852-____-____',
  },
  {
    name: 'Grenada',
    isoCode: 'gd',
    phoneCode: '1473',
    mask: '+1(473)___-____',
  },
  {
    name: 'Greenland',
    isoCode: 'gl',
    phoneCode: '299',
    mask: '+299-__-__-__',
  },
  {
    name: 'Greece',
    isoCode: 'gr',
    phoneCode: '30',
    mask: '+30(___)___-____',
  },
  {
    name: 'Georgia',
    isoCode: 'ge',
    phoneCode: '995',
    mask: '+995(___)___-___',
  },
  {
    name: 'Guam',
    isoCode: 'gu',
    phoneCode: '1671',
    mask: '+1(671)___-____',
  },
  {
    name: 'Denmark',
    isoCode: 'dk',
    phoneCode: '45',
    mask: '+45-__-__-__-__',
  },
  {
    name: 'Djibouti',
    isoCode: 'dj',
    phoneCode: '253',
    mask: '+253-__-__-__-__',
  },
  {
    name: 'Diego Garcia',
    isoCode: 'io',
    phoneCode: '246',
    mask: '+246-___-____',
  },
  {
    name: 'Dominica',
    isoCode: 'dm',
    phoneCode: '1767',
    mask: '+1(767)___-____',
  },
  {
    name: 'Dominican Republic',
    isoCode: 'do',
    phoneCode: '1809',
    mask: '+1(809)___-____',
  },
  {
    name: 'Egypt',
    isoCode: 'eg',
    phoneCode: '20',
    mask: '+20(___)___-____',
  },
  {
    name: 'Zambia',
    isoCode: 'zm',
    phoneCode: '260',
    mask: '+260-__-___-____',
  },
  {
    name: 'Zimbabwe',
    isoCode: 'zw',
    phoneCode: '263',
    mask: '+263-_-______',
  },
  {
    name: 'Israel',
    isoCode: 'il',
    phoneCode: '972',
    mask: '+972-_-___-____',
  },
  {
    name: 'India',
    isoCode: 'in',
    phoneCode: '91',
    mask: '+91(____)___-___',
  },
  {
    name: 'Indonesia',
    isoCode: 'id',
    phoneCode: '62',
    mask: '+62-__-___-__',
  },
  {
    name: 'Jordan',
    isoCode: 'jo',
    phoneCode: '962',
    mask: '+962-_-____-____',
  },
  {
    name: 'Iraq',
    isoCode: 'iq',
    phoneCode: '964',
    mask: '+964(___)___-____',
  },
  {
    name: 'Iran',
    isoCode: 'ir',
    phoneCode: '98',
    mask: '+98(___)___-____',
  },
  {
    name: 'Ireland',
    isoCode: 'ie',
    phoneCode: '353',
    mask: '+353(___)___-___',
  },
  {
    name: 'Iceland',
    isoCode: 'is',
    phoneCode: '354',
    mask: '+354-___-____',
  },
  {
    name: 'Spain',
    isoCode: 'es',
    phoneCode: '34',
    mask: '+34(___)___-___',
  },
  {
    name: 'Italy',
    isoCode: 'it',
    phoneCode: '39',
    mask: '+39(___)____-___',
  },
  {
    name: 'Yemen',
    isoCode: 'ye',
    phoneCode: '967',
    mask: '+967-_-___-___',
  },
  {
    name: 'Cape Verde',
    isoCode: 'cv',
    phoneCode: '238',
    mask: '+238(___)__-__',
  },
  {
    name: 'Kazakhstan',
    isoCode: 'kz',
    phoneCode: '77',
    mask: '+7(7__)___-__-__',
  },
  {
    name: 'Cayman Islands',
    isoCode: 'ky',
    phoneCode: '1345',
    mask: '+1(345)___-____',
  },
  {
    name: 'Cambodia',
    isoCode: 'kh',
    phoneCode: '855',
    mask: '+855-__-___-___',
  },
  {
    name: 'Cameroon',
    isoCode: 'cm',
    phoneCode: '237',
    mask: '+237-____-____',
  },
  {
    name: 'Canada',
    isoCode: 'ca',
    phoneCode: '1',
    mask: '+1(___)___-____',
  },
  {
    name: 'Qatar',
    isoCode: 'qa',
    phoneCode: '974',
    mask: '+974-____-____',
  },
  {
    name: 'Kenya',
    isoCode: 'ke',
    phoneCode: '254',
    mask: '+254-___-______',
  },
  {
    name: 'Cyprus',
    isoCode: 'cy',
    phoneCode: '357',
    mask: '+357-__-___-___',
  },
  {
    name: 'Kyrgyzstan',
    isoCode: 'kg',
    phoneCode: '996',
    mask: '+996(___)___-___',
  },
  {
    name: 'Kiribati',
    isoCode: 'ki',
    phoneCode: '686',
    mask: '+686-__-___',
  },
  {
    name: 'China',
    isoCode: 'cn',
    phoneCode: '86',
    mask: '+86(___)____-___',
  },
  {
    name: 'Colombia',
    isoCode: 'co',
    phoneCode: '57',
    mask: '+57(___)___-____',
  },
  {
    name: 'Comoros',
    isoCode: 'km',
    phoneCode: '269',
    mask: '+269-__-_____',
  },
  {
    name: 'Congo (Brazzaville)',
    isoCode: 'cg',
    phoneCode: '242',
    mask: '+242-__-___-____',
  },
  {
    name: 'Congo (Kinshasa)',
    isoCode: 'cd',
    phoneCode: '243',
    mask: '+243(___)___-___',
  },
  {
    name: 'Korean NDR',
    isoCode: 'kp',
    phoneCode: '850',
    mask: '+850-___-___',
  },
  {
    name: 'Costa Rica',
    isoCode: 'cr',
    phoneCode: '506',
    mask: '+506-____-____',
  },
  {
    name: "Cote d'Ivoire",
    isoCode: 'ci',
    phoneCode: '225',
    mask: '+225-__-___-___',
  },
  {
    name: 'Cuba',
    isoCode: 'cu',
    phoneCode: '53',
    mask: '+53-_-___-____',
  },
  {
    name: 'Kuwait',
    isoCode: 'kw',
    phoneCode: '965',
    mask: '+965-____-____',
  },
  {
    name: 'Laos',
    isoCode: 'la',
    phoneCode: '856',
    mask: '+856-__-___-___',
  },
  {
    name: 'Latvia',
    isoCode: 'lv',
    phoneCode: '371',
    mask: '+371-__-___-___',
  },
  {
    name: 'Lesotho',
    isoCode: 'ls',
    phoneCode: '266',
    mask: '+266-_-___-____',
  },
  {
    name: 'Liberia',
    isoCode: 'lr',
    phoneCode: '231',
    mask: '+231-__-___-___',
  },
  {
    name: 'Lebanon',
    isoCode: 'lb',
    phoneCode: '961',
    mask: '+961-_-___-___',
  },
  {
    name: 'Libya',
    isoCode: 'ly',
    phoneCode: '218',
    mask: '+218-__-___-___',
  },
  {
    name: 'Lithuania',
    isoCode: 'lt',
    phoneCode: '370',
    mask: '+370(___)__-___',
  },
  {
    name: 'Liechtenstein',
    isoCode: 'li',
    phoneCode: '423',
    mask: '+423(___)___-____',
  },
  {
    name: 'Luxembourg',
    isoCode: 'lu',
    phoneCode: '352',
    mask: '+352(___)___-___',
  },
  {
    name: 'Mauritius',
    isoCode: 'mu',
    phoneCode: '230',
    mask: '+230-___-____',
  },
  {
    name: 'Mauritania',
    isoCode: 'mr',
    phoneCode: '222',
    mask: '+222-__-__-____',
  },
  {
    name: 'Madagascar',
    isoCode: 'mg',
    phoneCode: '261',
    mask: '+261-__-__-_____',
  },
  {
    name: 'Mayotte',
    isoCode: 'fr',
    phoneCode: '262',
    mask: '+262-_____-____',
  },
  {
    name: 'Macau',
    isoCode: 'mo',
    phoneCode: '853',
    mask: '+853-____-____',
  },
  {
    name: 'Malawi',
    isoCode: 'mw',
    phoneCode: '265',
    mask: '+265-_-____-____',
  },
  {
    name: 'Malaysia',
    isoCode: 'my',
    phoneCode: '60',
    mask: '+60-_-___-___',
  },
  {
    name: 'Mali',
    isoCode: 'ml',
    phoneCode: '223',
    mask: '+223-__-__-____',
  },
  {
    name: 'Maldives',
    isoCode: 'mv',
    phoneCode: '960',
    mask: '+960-___-____',
  },
  {
    name: 'Malta',
    isoCode: 'mt',
    phoneCode: '356',
    mask: '+356-____-____',
  },
  {
    name: 'Morocco',
    isoCode: 'ma',
    phoneCode: '212',
    mask: '+212-__-____-___',
  },
  {
    name: 'Martinique',
    isoCode: 'mq',
    phoneCode: '596',
    mask: '+596(___)__-__-__',
  },
  {
    name: 'Marshall Islands',
    isoCode: 'mh',
    phoneCode: '692',
    mask: '+692-___-____',
  },
  {
    name: 'Mexico',
    isoCode: 'mx',
    phoneCode: '52',
    mask: '+52-__-__-____',
  },
  {
    name: 'Mozambique',
    isoCode: 'mz',
    phoneCode: '258',
    mask: '+258-__-___-___',
  },
  {
    name: 'Moldova',
    isoCode: 'md',
    phoneCode: '373',
    mask: '+373-____-____',
  },
  {
    name: 'Monaco',
    isoCode: 'mc',
    phoneCode: '377',
    mask: '+377-__-___-___',
  },
  {
    name: 'Mongolia',
    isoCode: 'mn',
    phoneCode: '976',
    mask: '+976-__-__-____',
  },
  {
    name: 'Montserrat',
    isoCode: 'ms',
    phoneCode: '1664',
    mask: '+1(664)___-____',
  },
  {
    name: 'Namibia',
    isoCode: 'na',
    phoneCode: '264',
    mask: '+264-__-___-____',
  },
  {
    name: 'Nauru',
    isoCode: 'nr',
    phoneCode: '674',
    mask: '+674-___-____',
  },
  {
    name: 'Nepal',
    isoCode: 'np',
    phoneCode: '977',
    mask: '+977-__-___-___',
  },
  {
    name: 'Niger',
    isoCode: 'ne',
    phoneCode: '227',
    mask: '+227-__-__-____',
  },
  {
    name: 'Nigeria',
    isoCode: 'ng',
    phoneCode: '234',
    mask: '+234-__-___-__',
  },
  {
    name: 'The Netherlands',
    isoCode: 'nl',
    phoneCode: '31',
    mask: '+31-__-___-____',
  },
  {
    name: 'Nicaragua',
    isoCode: 'ni',
    phoneCode: '505',
    mask: '+505-____-____',
  },
  {
    name: 'Niue',
    isoCode: 'nu',
    phoneCode: '683',
    mask: '+683-____',
  },
  {
    name: 'New Zealand',
    isoCode: 'nz',
    phoneCode: '64',
    mask: '+64-__-___-___',
  },
  {
    name: 'New Caledonia',
    isoCode: 'nc',
    phoneCode: '687',
    mask: '+687-__-____',
  },
  {
    name: 'Norway',
    isoCode: 'no',
    phoneCode: '47',
    mask: '+47(___)__-___',
  },
  {
    name: 'Norfolk Island',
    isoCode: 'nf',
    phoneCode: '6723',
    mask: '+672-3__-___',
  },
  {
    name: 'Oman',
    isoCode: 'om',
    phoneCode: '968',
    mask: '+968-__-___-___',
  },
  {
    name: 'Cook Islands',
    isoCode: 'ck',
    phoneCode: '682',
    mask: '+682-__-___',
  },
  {
    name: 'Pakistan',
    isoCode: 'pk',
    phoneCode: '92',
    mask: '+92(___)___-____',
  },
  {
    name: 'Palau',
    isoCode: 'pw',
    phoneCode: '680',
    mask: '+680-___-____',
  },
  {
    name: 'Palestine',
    isoCode: 'ps',
    phoneCode: '970',
    mask: '+970-__-___-____',
  },
  {
    name: 'Panama',
    isoCode: 'pa',
    phoneCode: '507',
    mask: '+507-___-____',
  },
  {
    name: 'Papua New Guinea',
    isoCode: 'pg',
    phoneCode: '675',
    mask: '+675(___)__-___',
  },
  {
    name: 'Paraguay',
    isoCode: 'py',
    phoneCode: '595',
    mask: '+595(___)___-___',
  },
  {
    name: 'Peru',
    isoCode: 'pe',
    phoneCode: '51',
    mask: '+51(___)___-___',
  },
  {
    name: 'Poland',
    isoCode: 'pl',
    phoneCode: '48',
    mask: '+48(___)___-___',
  },
  {
    name: 'Portugal',
    isoCode: 'pt',
    phoneCode: '351',
    mask: '+351-__-___-____',
  },
  {
    name: 'Republic of Korea',
    isoCode: 'kr',
    phoneCode: '82',
    mask: '+82-__-___-____',
  },
  {
    name: 'Republic of Macedonia',
    isoCode: 'mk',
    phoneCode: '389',
    mask: '+389-__-___-___',
  },
  {
    name: 'Reunion',
    isoCode: 're',
    phoneCode: '262',
    mask: '+262-_____-____',
  },
  {
    name: 'Russia',
    isoCode: 'ru',
    phoneCode: '7',
    mask: '+7(___)___-__-__',
  },
  {
    name: 'Rwanda',
    isoCode: 'rw',
    phoneCode: '250',
    mask: '+250(___)___-___',
  },
  {
    name: 'Romania',
    isoCode: 'ro',
    phoneCode: '40',
    mask: '+40-__-___-____',
  },
  {
    name: 'Salvador',
    isoCode: 'sv',
    phoneCode: '503',
    mask: '+503-__-__-____',
  },
  {
    name: 'Samoa',
    isoCode: 'ws',
    phoneCode: '685',
    mask: '+685-__-____',
  },
  {
    name: 'San Marino',
    isoCode: 'sm',
    phoneCode: '378',
    mask: '+378-____-______',
  },
  {
    name: 'Sao Tome and Principe',
    isoCode: 'st',
    phoneCode: '239',
    mask: '+239-__-_____',
  },
  {
    name: 'Saudi Arabia',
    isoCode: 'sa',
    phoneCode: '966',
    mask: '+966-_-___-____',
  },
  {
    name: 'Swaziland',
    isoCode: 'sz',
    phoneCode: '268',
    mask: '+268-__-__-____',
  },
  {
    name: 'Northern Mariana Islands',
    isoCode: 'mp',
    phoneCode: '1670',
    mask: '+1(670)___-____',
  },
  {
    name: 'Seychelles',
    isoCode: 'sc',
    phoneCode: '248',
    mask: '+248-_-___-___',
  },
  {
    name: 'Saint-Pierre and Miquelon',
    isoCode: 'fr',
    phoneCode: '508',
    mask: '+508-__-____',
  },
  {
    name: 'Senegal',
    isoCode: 'sn',
    phoneCode: '221',
    mask: '+221-__-___-____',
  },
  {
    name: 'St. Vincent and the Grenadines',
    isoCode: 'vc',
    phoneCode: '1784',
    mask: '+1(784)___-____',
  },
  {
    name: 'Saint Kitts and Nevis',
    isoCode: 'kn',
    phoneCode: '1869',
    mask: '+1(869)___-____',
  },
  {
    name: 'Saint Lucia',
    isoCode: 'lc',
    phoneCode: '1758',
    mask: '+1(758)___-____',
  },
  {
    name: 'Serbia',
    isoCode: 'rs',
    phoneCode: '381',
    mask: '+381-__-___-____',
  },
  {
    name: 'Singapore',
    isoCode: 'sg',
    phoneCode: '65',
    mask: '+65-____-____',
  },
  {
    name: 'Syrian Arab Republic',
    isoCode: 'sy',
    phoneCode: '963',
    mask: '+963-__-____-___',
  },
  {
    name: 'Slovakia',
    isoCode: 'sk',
    phoneCode: '421',
    mask: '+421(___)___-___',
  },
  {
    name: 'Slovenia',
    isoCode: 'si',
    phoneCode: '386',
    mask: '+386-__-___-___',
  },
  {
    name: 'United States',
    isoCode: 'us',
    phoneCode: '1',
    mask: '+1(___)___-____',
  },
  {
    name: 'Solomon Islands',
    isoCode: 'sb',
    phoneCode: '677',
    mask: '+677-_____',
  },
  {
    name: 'Somalia',
    isoCode: 'so',
    phoneCode: '252',
    mask: '+252-_-___-___',
  },
  {
    name: 'Sudan',
    isoCode: 'sd',
    phoneCode: '249',
    mask: '+249-__-___-____',
  },
  {
    name: 'Suriname',
    isoCode: 'sr',
    phoneCode: '597',
    mask: '+597-___-___',
  },
  {
    name: 'Sierra Leone',
    isoCode: 'sl',
    phoneCode: '232',
    mask: '+232-__-______',
  },
  {
    name: 'Tajikistan',
    isoCode: 'tj',
    phoneCode: '992',
    mask: '+992-__-___-____',
  },
  {
    name: 'Thailand',
    isoCode: 'th',
    phoneCode: '66',
    mask: '+66-__-___-___',
  },
  {
    name: 'Taiwan',
    isoCode: 'tw',
    phoneCode: '886',
    mask: '+886-____-____',
  },
  {
    name: 'Tanzania',
    isoCode: 'tz',
    phoneCode: '255',
    mask: '+255-__-___-____',
  },
  {
    name: 'Togo',
    isoCode: 'tg',
    phoneCode: '228',
    mask: '+228-__-___-___',
  },
  {
    name: 'Tokelau',
    isoCode: 'tk',
    phoneCode: '690',
    mask: '+690-____',
  },
  {
    name: 'Tonga',
    isoCode: 'to',
    phoneCode: '676',
    mask: '+676-_____',
  },
  {
    name: 'Trinidad and Tobago',
    isoCode: 'tt',
    phoneCode: '1868',
    mask: '+1(868)___-____',
  },
  {
    name: 'Tuvalu',
    isoCode: 'tv',
    phoneCode: '688',
    mask: '+688-_____',
  },
  {
    name: 'Tunisia',
    isoCode: 'tn',
    phoneCode: '216',
    mask: '+216-__-___-___',
  },
  {
    name: 'Turkmenistan',
    isoCode: 'tm',
    phoneCode: '993',
    mask: '+993-_-___-____',
  },
  {
    name: 'Turkey',
    isoCode: 'tr',
    phoneCode: '90',
    mask: '+90(___)___-____',
  },
  {
    name: 'Turks and Caicos',
    isoCode: 'tc',
    phoneCode: '1649',
    mask: '+1(649)___-____',
  },
  {
    name: 'Uganda',
    isoCode: 'ug',
    phoneCode: '256',
    mask: '+256(___)___-___',
  },
  {
    name: 'Uzbekistan',
    isoCode: 'uz',
    phoneCode: '998',
    mask: '+998-__-___-____',
  },
  {
    name: 'Ukraine',
    isoCode: 'ua',
    phoneCode: '380',
    mask: '+380(__)___-__-__',
  },
  {
    name: 'Wallis and Futuna',
    isoCode: 'wf',
    phoneCode: '681',
    mask: '+681-__-____',
  },
  {
    name: 'Uruguay',
    isoCode: 'uy',
    phoneCode: '598',
    mask: '+598-_-___-__-__',
  },
  {
    name: 'F. Micronesia',
    isoCode: 'fm',
    phoneCode: '691',
    mask: '+691-___-____',
  },
  {
    name: 'Faroe Islands',
    isoCode: 'fo',
    phoneCode: '298',
    mask: '+298-___-___',
  },
  {
    name: 'Fiji',
    isoCode: 'fj',
    phoneCode: '679',
    mask: '+679-__-_____',
  },
  {
    name: 'Philippines',
    isoCode: 'ph',
    phoneCode: '63',
    mask: '+63(___)___-____',
  },
  {
    name: 'Finland',
    isoCode: 'fi',
    phoneCode: '358',
    mask: '+358(___)___-__-__',
  },
  {
    name: 'Falkland Islands',
    isoCode: 'fk',
    phoneCode: '500',
    mask: '+500-_____',
  },
  {
    name: 'Fr. Guyana',
    isoCode: 'gf',
    phoneCode: '594',
    mask: '+594-_____-____',
  },
  {
    name: 'France',
    isoCode: 'fr',
    phoneCode: '33',
    mask: '+33(___)___-___',
  },
  {
    name: 'French Polynesia (Tahiti)',
    isoCode: 'pf',
    phoneCode: '689',
    mask: '+689-__-__-__',
  },
  {
    name: 'Croatia',
    isoCode: 'hr',
    phoneCode: '385',
    mask: '+385-__-___-___',
  },
  {
    name: 'Central African Republic',
    isoCode: 'cf',
    phoneCode: '236',
    mask: '+236-__-__-____',
  },
  {
    name: 'Chad',
    isoCode: 'td',
    phoneCode: '235',
    mask: '+235-__-__-__-__',
  },
  {
    name: 'Montenegro',
    isoCode: 'me',
    phoneCode: '382',
    mask: '+382-__-___-___',
  },
  {
    name: 'Czech Republic',
    isoCode: 'cz',
    phoneCode: '420',
    mask: '+420(___)___-___',
  },
  {
    name: 'Chile',
    isoCode: 'cl',
    phoneCode: '56',
    mask: '+56-_-____-____',
  },
  {
    name: 'Switzerland',
    isoCode: 'ch',
    phoneCode: '41',
    mask: '+41-__-___-____',
  },
  {
    name: 'Sweden',
    isoCode: 'se',
    phoneCode: '46',
    mask: '+46-__-___-____',
  },
  {
    name: 'Sri Lanka',
    isoCode: 'lk',
    phoneCode: '94',
    mask: '+94-__-___-____',
  },
  {
    name: 'Ecuador',
    isoCode: 'ec',
    phoneCode: '593',
    mask: '+593-_-___-____',
  },
  {
    name: 'Equatorial Guinea',
    isoCode: 'gq',
    phoneCode: '240',
    mask: '+240-__-___-____',
  },
  {
    name: 'Eritrea',
    isoCode: 'er',
    phoneCode: '291',
    mask: '+291-_-___-___',
  },
  {
    name: 'Estonia',
    isoCode: 'ee',
    phoneCode: '372',
    mask: '+372-___-____',
  },
  {
    name: 'Ethiopia',
    isoCode: 'et',
    phoneCode: '251',
    mask: '+251-__-___-____',
  },
  {
    name: 'South Africa',
    isoCode: 'za',
    phoneCode: '27',
    mask: '+27-__-___-____',
  },
  {
    name: 'South Sudan',
    isoCode: 'ss',
    phoneCode: '211',
    mask: '+211-__-___-____',
  },
  {
    name: 'Jamaica',
    isoCode: 'jm',
    phoneCode: '1876',
    mask: '+1(876)___-____',
  },
  {
    name: 'Japan',
    isoCode: 'jp',
    phoneCode: '81',
    mask: '+81(___)___-___',
  },
]
