import React from 'react'
import { inject, observer } from 'mobx-react'

import CommonFiltersForm from '../../../../table/CommonFiltersForm'
import { useCalcTableHeightContext } from '../../../../../context'
import DeviceAndUserFilters, {
  deviceAndUserFiltersIntitalValues,
} from '../../../../table/DeviceAndUserFilters'
import { Field } from 'formik'
import CustomCalendarInput from '../../../../common/CustomCalendarInput'
import Export from '../Export'
import { afkDevicesCommonDays } from '../../../../../constants/devices'
import CustomSelect from '../../../../common/CustomSelect'

const initialValues = {
  date: null,
  ...deviceAndUserFiltersIntitalValues,
  days: null,
}

const daysOptions = afkDevicesCommonDays
  .map((day, i, arr) => {
    const isFirst = !i
    const isLast = i === arr.length - 1

    const { lte, label } = (() => {
      if (isFirst) {
        return { lte: 1, label: `<= ${day}` }
      }
      const lte = arr[i - 1]
      return { lte, label: `${lte} - ${day}` }
    })()

    const rangeItem = { label, value: [lte, day] }

    if (isLast) {
      return [rangeItem, { label: `>= ${day}`, value: day }]
    }

    return rangeItem
  })
  .flat()

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll }) => {
  const { filterRef } = useCalcTableHeightContext()

  const outFormElem = <Export />

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
      outFormElem={outFormElem}
    >
      <div className={className}>
        <Field name="date" notFuture component={CustomCalendarInput} />
      </div>
      <DeviceAndUserFilters className={className} />
      <div className={className}>
        <Field
          name="days"
          placeholder="days"
          component={CustomSelect}
          options={daysOptions}
        />
      </div>
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.DevicesAFKStore.isLoading,
  getAll: stores.DevicesAFKStore.getAll,
}))

export default withData(observer(FilterForm))
