import React, { useCallback } from 'react'
import { inject } from 'mobx-react'

import useRedirect from '../../../../../hooks/useRedirect'
import CommonActionBody from '../../../../table/CommonActionBody'

const ActionBody = ({ rowData, open, getItemUrl, deleteOne }) => {
  const { id } = rowData
  const redirect = useRedirect(getItemUrl(rowData))

  const deleteDeviceProfile = useCallback(() => deleteOne(id), [deleteOne, id])

  const openConfirmDelete = useCallback(
    () =>
      open(deleteDeviceProfile, {
        header: 'delete_device_profile',
      }),
    [open, deleteDeviceProfile],
  )

  return (
    <CommonActionBody
      successLabel="edit"
      successOnClick={redirect}
      dangerLabel="delete"
      dangerOnClick={openConfirmDelete}
    />
  )
}

const withData = inject((stores) => ({
  open: stores.GlobalConfirm.open,
  getItemUrl: stores.DeviceProfilesStore.getItemUrl,
  deleteOne: stores.DeviceProfilesStore.deleteOne,
}))

export default withData(ActionBody)
