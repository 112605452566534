import Promotions from './Promotions'
import Devices from './Devices'
import DevicesAFK from './DevicesAFK'

export const STATISTICS_TABS_SECTION_NAME = 'statistics'

export const StatisticsTab = {
  Promotions: 'promotions',
  Devices: 'devices',
  AFK: 'afk',
}

export const statisticsTabsConfig = [
  {
    tabName: StatisticsTab.Promotions,
    Component: Promotions,
  },
  {
    tabName: StatisticsTab.Devices,
    Component: Devices,
  },
  {
    tabName: StatisticsTab.AFK,
    Component: DevicesAFK,
  },
]
