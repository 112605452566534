import React from 'react'
import { inject, observer } from 'mobx-react'

import CommonFiltersForm, {
  DateRangeField,
} from '../../../../table/CommonFiltersForm'
import { useCalcTableHeightContext } from '../../../../../context'
import DeviceAndUserFilters, {
  deviceAndUserFiltersIntitalValues,
} from '../../../../table/DeviceAndUserFilters'
import Export from '../Export'

const initialValues = {
  dateRange: null,
  ...deviceAndUserFiltersIntitalValues,
}

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll }) => {
  const { filterRef } = useCalcTableHeightContext()

  const outFormElem = <Export />

  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
      outFormElem={outFormElem}
    >
      <DateRangeField className={className} placeholder="from_to" />
      <DeviceAndUserFilters className={className} />
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.DevicesStatisticsStore.isLoading,
  getAll: stores.DevicesStatisticsStore.getAll,
}))

export default withData(observer(FilterForm))
