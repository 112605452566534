import React from 'react'
import { Avatar } from 'primereact/avatar'

const common = { size: 'large', shape: 'circle' }

const CustomAvatar = (props) => {
  return <Avatar {...common} {...props} />
}

export default CustomAvatar
