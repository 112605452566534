import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject } from 'mobx-react'

import CustomBreadCrumb, { Model } from '../../breadcrumb/CustomBreadCrumb'
import DeviceForm from './DeviceForm'

const DevicePage = ({
  getOne,
  cleanUp,
  getDeviceCategories,
  deviceCategoriesCleanUp,
  getDeviceProfiles,
  deviceProfilesCleanUp,
}) => {
  const { id } = useParams()

  useEffect(() => {
    if (id) getOne(id)

    getDeviceCategories()
    getDeviceProfiles()

    return () => {
      cleanUp()
      deviceCategoriesCleanUp()
      deviceProfilesCleanUp()
    }
  }, [id])

  return (
    <>
      <CustomBreadCrumb model={Model.Device} />
      <div className="p-fluid page-content-wrapper">
        <DeviceForm />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  getOne: stores.DevicesStore.getOne,
  cleanUp: stores.DevicesStore.cleanUp,
  getDeviceCategories: stores.DeviceCategoriesStore.getAll,
  deviceCategoriesCleanUp: stores.DeviceCategoriesStore.cleanUp,
  getDeviceProfiles: stores.DeviceProfilesStore.getAll,
  deviceProfilesCleanUp: stores.DeviceProfilesStore.cleanUp,
}))

export default withData(DevicePage)
