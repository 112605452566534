import React from 'react'
import { inject, observer } from 'mobx-react'
import Dropzone from '../../Dropzone'
import { APPLICATION_IMAGE_MAX_FILE_LIMIT } from '../../../constants/applications'

const filesValidationData = {
  fileLimit: APPLICATION_IMAGE_MAX_FILE_LIMIT,
}

const ImageUpload = (props) => {
  const { handleAddFiles, handleRemoveFiles, files } = props

  return (
    <div className="p-col-12">
      <Dropzone
        isSingleMode
        multiple={false}
        handleAddFiles={handleAddFiles}
        handleRemove={handleRemoveFiles}
        accept="image/*"
        files={files}
        filesValidationData={filesValidationData}
        titleKey="application"
      />
    </div>
  )
}

const withData = inject((stores) => ({
  handleAddFiles: stores.ApplicationsStore.handleAddFiles,
  handleRemoveFiles: stores.ApplicationsStore.handleRemoveFiles,
  files: stores.ApplicationsStore.files,
}))

export default withData(observer(ImageUpload))
