import React from 'react'
import { inject, observer } from 'mobx-react'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'

import ExportDialogBody from './Body'

const ExportDialog = ({ isExportOpen, closeExport }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      modal
      className="popup-statistics-export"
      visible={isExportOpen}
      onHide={closeExport}
      header={t('modals.export_devices_statistics')}
    >
      {isExportOpen && <ExportDialogBody />}
    </Dialog>
  )
}

const withData = inject((stores) => ({
  isExportOpen: stores.DevicesStatisticsStore.isExportOpen,
  closeExport: stores.DevicesStatisticsStore.closeExport,
}))

export default withData(observer(ExportDialog))
