import React from 'react'
import { inject, observer } from 'mobx-react'

import CustomAvatar from '../CustomAvatar'

const UserAvatar = ({ user, resource }) => {
  const { avatar, id } = user || {}

  const avatarImgProps = avatar
    ? { image: `${resource}/${id}/avatars/${avatar}` }
    : { icon: 'pi pi-user' }

  return <CustomAvatar {...avatarImgProps} />
}

const withData = inject((stores) => ({
  resource: stores.UsersStore.resource,
}))

export default withData(observer(UserAvatar))
