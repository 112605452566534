import React from 'react'
import { inject, observer } from 'mobx-react'
import { MdPersonPin } from 'react-icons/md'

import UserPersonalInfo from '../../user/PersonalInfo'
import CommonCard from '../../common/CommonCard'
import VerificationGroup from './VerificationGroup'
import DeleteAccBtn from './DeleteAccBtn'

const PersonalInfo = ({ user, updateUserInfo, title }) => {
  return (
    <CommonCard
      title={title}
      icon={<MdPersonPin />}
      className="user-profile-card p-mx-auto"
    >
      <VerificationGroup />
      <UserPersonalInfo user={user} onSubmit={updateUserInfo} />
      <DeleteAccBtn />
    </CommonCard>
  )
}

const withData = inject(({ AuthStore, UsersStore }) => ({
  user: AuthStore.user,
  updateUserInfo: UsersStore.updateUserInfo,
}))

export default withData(observer(PersonalInfo))
