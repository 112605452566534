import React from 'react'
import { inject, observer } from 'mobx-react'
import { pipe } from '../../../utils/function'

import Condition, { If, Else } from '../../hoc/Condition'

const UserInfo = ({ user, avatarUrl }) => {
  const { email, nickname } = user

  return (
    <div>
      <Condition condition={avatarUrl}>
        <If>
          <img className="user-avatar-info" src={avatarUrl} alt="avatar" />
        </If>
        <Else>
          <div className="user-avatar-info">GI</div>
        </Else>
      </Condition>
      <span className="user-auth-info">{nickname || email}</span>
    </div>
  )
}

const withData = inject((stores) => ({
  avatarUrl: stores.UsersStore.currentUserAvatarUrl,
  user: stores.AuthStore.user,
}))

export default pipe(observer, withData)(UserInfo)
