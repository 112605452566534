class DropzoneInvalidFilesException extends Error {
  name = 'DropzoneInvalidFilesException'

  constructor(message, replacers) {
    super(message)
    this.replacers = replacers
  }
}

export default DropzoneInvalidFilesException
