import React from 'react'
import { inject, observer } from 'mobx-react'

import { pipe } from '../../../../utils/function'
import CommonFiltersForm, {
  DateRangeField,
} from '../../../table/CommonFiltersForm'
import { defaultValues, propsMapping } from '../../DeviceProfilePage/config'
import FormFields from '../../../common/FormFields'

const { config, ...initialValues } = defaultValues

const className = 'p-col-12 p-sm-6 p-lg-3'

const FilterForm = ({ limit, offset, isLoading, getAll, filterRef }) => {
  return (
    <CommonFiltersForm
      getAll={getAll}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
      initialValues={initialValues}
      filterRef={filterRef}
    >
      <DateRangeField className={className} />
      <FormFields
        values={initialValues}
        propsMapping={propsMapping}
        gridClassName={className}
        labelPropName="placeholder"
        isRequired={false}
        withWrapper={false}
      />
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.DeviceProfilesStore.isLoading,
  getAll: stores.DeviceProfilesStore.getAll,
}))

export default pipe(observer, withData)(FilterForm)
