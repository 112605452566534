import * as yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import {
  getYoutubeEmbedUrl,
  serializeTargetEmails,
} from '../../helpers/promotions'

yup.string.prototype.emailList = function (message) {
  return this.test('valid-emails', message, (list) => {
    return serializeTargetEmails(list).every((value) =>
      /[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,}$/.test(value),
    )
  })
}

yup.string.prototype.internationalPhone = function (message) {
  return this.trim().test(
    'valid-phone-number',
    message,
    (value) => !value || !!parsePhoneNumberFromString(`+${value}`)?.isValid(),
  )
}

yup.string.prototype.httpOrHttps = function (message) {
  return this.url(message).matches(/^http(s)?:\/\//, message)
}

yup.string.prototype.isYoutubeUrl = function (message) {
  return this.test('is-youtube', message, getYoutubeEmbedUrl)
}
