import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import './GamingSet.scss'
import { inject, observer } from 'mobx-react'
import useGamingSetDeviceField from './hook/useGamingSetDeviceField'
import FormGroup from './FormGroup'
import { GamingSetContext } from '../../context'

const GamingSet = ({
  isLoading,
  className,
  Error,
  FormEntities,
  submitLabelKey,
  shape,
  children,
  cleanUp,
  getAll,
  submitOne,
  getOne,
  item,
  GamingSetcleanUp,
}) => {
  const validationSchema = useGamingSetDeviceField(shape, cleanUp, getAll)
  const { id } = useParams()
  const history = useHistory()
  const [GamingSetDevices, setGamingSetDevices] = useState()
  const initialValues = useMemo(() => {
    return {
      name: item ? item?.name : '',
      desc: item ? item?.desc : '',
    }
  }, [GamingSetDevices])

  useEffect(() => {
    if (id) getOne(id)
    return () => {
      cleanUp()
      GamingSetcleanUp()
    }
  }, [id])

  useEffect(() => {
    if (item) setGamingSetDevices(item)
    return () => {
      cleanUp()
    }
  }, [item])

  const onSubmit = useCallback(
    ({ ...values }, helpers) => {
      values.devices = GamingSetDevices.devices.map((i) => ({
        DeviceId: i.DeviceId,
        config: i.config,
      }))
      if (id) {
        values.id = id
      }

      submitOne(values, helpers, history, GamingSetDevices)
    },
    [submitOne, history, GamingSetDevices],
  )

  return (
    <GamingSetContext.Provider value={[GamingSetDevices, setGamingSetDevices]}>
      <CustomBreadCrumb model={Model.GamingSet} />
      <div className="p-fluid page-content-wrapper">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          className={className}
          validationSchema={validationSchema}
        >
          {({ dirty }) => (
            <FormGroup
              dirty={dirty}
              isLoading={isLoading}
              Error={Error}
              GamingSetDevices={GamingSetDevices}
              FormEntities={FormEntities}
              submitLabelKey={submitLabelKey}
              children={children}
            />
          )}
        </Formik>
      </div>
    </GamingSetContext.Provider>
  )
}

const withData = inject((stores) => ({
  getAll: stores.DeviceCategoriesStore.getAll,
  cleanUp: stores.DeviceCategoriesStore.cleanUp,
  submitOne: stores.GamingSetsStore.submitOne,
  getOne: stores.GamingSetsStore.getOne,
  item: stores.GamingSetsStore.item,
  GamingSetcleanUp: stores.GamingSetsStore.cleanUp,
}))

export default withData(observer(GamingSet))
