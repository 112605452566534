import React from 'react'

import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'
import PageTabs from '../PageTabs'
import { TABS_SECTION_NAME, tabsConfig } from './tabs/config'
import calcTableHeight from '../hoc/calcTableHeight'
import { useCalcTableHeightContext } from '../../context'
import ButtonsGroup from './ButtonsGroup'

const AppsPage = () => {
  const { wrapperRef } = useCalcTableHeightContext()

  return (
    <>
      <CustomBreadCrumb model={Model.Applications} />
      <div className="p-fluid page-content-wrapper" ref={wrapperRef}>
        <ButtonsGroup />
        <PageTabs section={TABS_SECTION_NAME} tabs={tabsConfig} className="" />
      </div>
    </>
  )
}

export default calcTableHeight(AppsPage)
