import { action, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import AuthStore from '../Auth/mobx'
import ConfirmDialogStore from '../dialogs/CustomConfirmDialog/mobx'
import { Notification, success } from '../../helpers/notifications'
import { findById, findInCollection, findIndexById } from '../../utils/array'
import { NOTIFICATION_FAST_TIMEOUT } from '../../constants'
import {
  changeStatusNotificationMapping,
  CommunityProfileStatus,
} from '../../constants/profiles'
import { ErrorMsg } from '../../constants/errorMsg'

class CommunityStore {
  resource = `${api.devices}/v1/community-profiles`

  NOTIFICATION_DELETED = Notification.COMMUNITY_PROFILE_DELETED

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable item = null

  @action
  changeStatus = async (id, status) => {
    const isChangeable = this.getIsStatusChangeable(id)
    if (!isChangeable) return

    const data = { status }
    const isTwoStepped = status === CommunityProfileStatus.Rejected

    const { isConfirmed, value } = await ConfirmDialogStore.open({
      isTwoStepped,
      header: 'profile_change_status_header',
      message: 'profile_change_status_message',
      ...(!isTwoStepped
        ? {}
        : {
            labelKey: 'reject_reason',
            errorMsg: ErrorMsg.REJECT_REASON_REQUIRED,
          }),
    })
    if (!isConfirmed) return

    if (isTwoStepped) data.rejectReason = value

    this.isLoading = true

    const { message = null, result } = await Http.patch(
      `${this.resource}/${id}/status`,
      data,
    )
    this.errorMsg = message

    if (result) {
      this.updateItem(result, id)
      success(changeStatusNotificationMapping[result.status])
    }

    this.isLoading = false
  }

  @action
  downloadCommunityProfile = async ({ id, ...values }, { resetForm }) => {
    if (!values.ids?.length) {
      this.errorMsg = ErrorMsg.COMMUNITY_PROFILE_AT_LEAST_ONE_CONFIG_NEEDED
      return
    }

    const isDownloaded = this.getIsDownloaded(id)
    if (isDownloaded === void 0) return

    if (isDownloaded) {
      const { isConfirmed } = await ConfirmDialogStore.open({
        header: 'profile_already_downloaded',
        message: 'profile_already_downloaded_message',
      })

      if (!isConfirmed) return
    }

    this.isLoading = true

    const { message = null, result } = await Http.post(
      `${this.resource}/${id}/device-profiles`,
      values,
    )
    this.errorMsg = message
    if (result) {
      resetForm()
      if (!isDownloaded) await this.getOne(id)
      success(Notification.COMMUNITY_PROFILE_DOWNLOADED)
    }
    this.isLoading = false
  }

  @action
  toggleLike = async (id) => {
    const isLiked = this.getIsLiked(id)
    if (isLiked === void 0 || !this.getIsLikable(id)) return

    const url = `${this.resource}/${id}/likes`

    this.isLoading = true

    let response, notification

    if (isLiked) {
      response = await Http.delete(url)
      notification = Notification.COMMUNITY_PROFILE_UN_LIKED
    } else {
      response = await Http.patch(url)
      notification = Notification.COMMUNITY_PROFILE_LIKED
    }

    const { message = null, result } = response
    this.errorMsg = message

    if (result) {
      this.updateItem(result, id)
      success(notification, {
        timeout: NOTIFICATION_FAST_TIMEOUT,
      })
    }

    this.isLoading = false
  }

  updateItem = (result, id) => {
    if (this.item) this.item = { ...this.item, ...result }

    const idx = findIndexById(this.list, id)
    if (~idx) this.list[idx] = { ...this.list[idx], ...result }
  }

  getIsStatusChangeable = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return item.status === CommunityProfileStatus.Pending
  }

  getIsLikable = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return item.status === CommunityProfileStatus.Published
  }

  getIsLiked = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return !!findInCollection(
      'UserId',
      item.CommunityProfileLikes,
      AuthStore.user.id,
    )
  }

  getIsDownloaded = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return !!findInCollection(
      'UserId',
      item.CommunityProfileDownloads,
      AuthStore.user.id,
    )
  }

  getItem = (id) => {
    return this.item || findById(this.list, id)
  }

  @action
  getAll = async (fields = {}) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  deleteOne = async (id) => {
    const { isConfirmed } = await ConfirmDialogStore.open({
      header: 'delete_community_profile',
      message: 'delete_community_profile_message',
    })
    if (!isConfirmed) return

    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      this.errorMsg = response.message
    } else {
      this.list = this.list.filter((elem) => elem.id != id)
      success(this.NOTIFICATION_DELETED)
    }
    this.isLoading = false
  }

  @action
  getOne = async (id) => {
    const profile = this.list.find((elm) => elm.id === id)
    if (profile?.devices) return

    this.isLoading = true
    const { message = null, result } = await Http.get(`${this.resource}/${id}`)
    this.errorMsg = message
    if (result) {
      if (profile) {
        profile.devices = result?.devices
      } else {
        this.item = result
      }
    }
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
  }
}

export default new CommunityStore()
