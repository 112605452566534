import moment from 'moment'

const dateOnly = 'DD/MM/YYYY'

export const toDateOnly = (date) => moment(date).format(dateOnly)

export const formatDate = (date) => moment(date).utc().format()

export const isValidDate = (value) =>
  value !== null && !isNaN(new Date(value).getTime())

export const getDateRange = (dateRange) => {
  let from
  let to
  if (dateRange && dateRange.length) {
    from = moment(dateRange[0]).startOf('day').utc().format()
    to = moment(dateRange[1] || dateRange[0])
      .endOf('day')
      .utc()
      .format()
  }
  return { from, to }
}

export const dateFormats = {
  dateOnly: 'DD/MM/YYYY',
  fullDateAndTime: `${dateOnly} HH:mm:ss`,
  fullDateAndTimeNoSecs: `${dateOnly} HH:mm`,
}

export const formattedDate = (field, format = dateFormats.fullDateAndTime) => (
  data,
) => {
  const value = data[field]
  return isValidDate(value) && moment(value).format(format)
}

export const formattedCreatedAt = formattedDate('createdAt')
export const formattedAnsweredAt = formattedDate('answeredAt')
export const formattedActiveTo = formattedDate('activeTo', dateOnly)

export const formattedDeactivatedAt = formattedDate('deactivatedAt')

export const secondsToTime = (secs) => {
  const labels = {
    0: 'h',
    1: 'm',
    2: 's',
  }

  return moment()
    .startOf('day')
    .seconds(secs)
    .format('HH:mm:ss')
    .split(':')
    .map((v, i) => `${v}${labels[i]}`)
    .join(' ')
}
