import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from 'primereact/confirmdialog'

import './CustomConfirmDialog.scss'
import { bindClassName } from '../../../utils/react'
import Value from './Value'
import Footer from './Footer'

const CustomConfirmDialog = ({
  dialogProps,
  accept,
  reject,
  setError,
  showValue,
}) => {
  const { t } = useTranslation()

  const { message, header, isTwoStepped, value, errorMsg, isOpen, error } =
    dialogProps || {}
  const hasValue = !!(value || '').trim()

  const handleAccept = useCallback(() => {
    if (isTwoStepped) {
      if (!isOpen) {
        return showValue()
      } else if (!hasValue) {
        return setError(errorMsg)
      }
    }

    accept()
  }, [hasValue, isTwoStepped, isOpen, showValue, setError, errorMsg, accept])

  const footer = <Footer accept={handleAccept} reject={reject} />

  const msg = message && (
    <>
      {t(`confirm.${message}`)}
      {isOpen && <Value />}
    </>
  )

  return (
    <ConfirmDialog
      visible={!!dialogProps}
      {...bindClassName(
        'custom-confirm',
        [isOpen, 'expanded'],
        [!!error, 'with-error'],
      )}
      message={msg}
      header={header && t(`confirm.${header}`)}
      icon="pi pi-exclamation-triangle"
      onHide={reject}
      footer={footer}
    />
  )
}

const withData = inject((stores) => ({
  dialogProps: stores.ConfirmDialogStore.dialogProps,
  accept: stores.ConfirmDialogStore.accept,
  reject: stores.ConfirmDialogStore.reject,
  setError: stores.ConfirmDialogStore.setError,
  showValue: stores.ConfirmDialogStore.showValue,
}))

export default withData(observer(CustomConfirmDialog))
