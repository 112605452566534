import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import CommonModalWindow from '../../../common/dialogWindow/CommonModalWindow'
import Loadable from '../../../hoc/Loadable'
import Dropzone from '../../../Dropzone'
import { DesktopApplicationUploadError } from '../../../messages/Error'
import { extractInfoFromName } from '../../../../helpers/desktop/files/info'
import { isValidPlatform, isValidVersion } from '../../../../utils/string'
import { ErrorMsg } from '../../../../constants/errorMsg'
import ButtonSubmit from '../../../buttons/ButtonSubmit'
import { platformsConfig } from '../../../../constants/desktop'
import { isExtensionsMatch } from '../../../../helpers/files/validation'

const filesValidationData = {
  isValidFile: ({ name }) => {
    const { version, platform } = extractInfoFromName(name)

    const { allowedExtensions } = platformsConfig[platform] || {
      allowedExtensions: [],
    }

    return (
      isValidVersion(version) &&
      isValidPlatform(platform) &&
      isExtensionsMatch(name, allowedExtensions)
    )
  },
  errors: {
    notValidFileError: ErrorMsg.DESKTOP_INVALID_FILE_NAME,
  },
}

const UploadModal = (props) => {
  const {
    uploadFiles,
    toggleModal,
    isModalOpen,
    isSubmitLoading,
    addFile,
    removeFile,
    files,
  } = props

  const history = useHistory()

  const handleSubmit = useCallback(() => uploadFiles(history), [
    uploadFiles,
    history,
  ])

  return (
    <CommonModalWindow
      isMd
      isActive={isModalOpen}
      toggleModal={toggleModal}
      headerKey="upload_desktop"
    >
      <Loadable loading={isSubmitLoading}>
        <div className="p-grid">
          <DesktopApplicationUploadError />
          <div className="p-col-12">
            <Dropzone
              filesValidationData={filesValidationData}
              files={files}
              handleAddFiles={addFile}
              handleRemove={removeFile}
            />
          </div>
          <ButtonSubmit
            isLoading={isSubmitLoading}
            dirty={!!files.length}
            onClick={handleSubmit}
            submitLabelKey="upload"
          />
        </div>
      </Loadable>
    </CommonModalWindow>
  )
}

const withData = inject((stores) => ({
  uploadFiles: stores.DesktopApplicationStore.uploadFiles,
  toggleModal: stores.DesktopApplicationStore.toggleModal,
  isModalOpen: stores.DesktopApplicationStore.isModalOpen,
  isSubmitLoading: stores.DesktopApplicationStore.isSubmitLoading,
  addFile: stores.DesktopApplicationStore.addFile,
  removeFile: stores.DesktopApplicationStore.removeFile,
  files: stores.DesktopApplicationStore.files,
}))

export default withData(observer(UploadModal))
