import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { bindClassName } from '../../../utils/react'

const ToggleLike = ({
  id,
  toggleLike,
  getIsLikable,
  getIsLiked,
  className,
  ...props
}) => {
  const isLikable = getIsLikable(id)
  const isLiked = getIsLiked(id)

  const handleLikeClick = useCallback(() => isLikable && toggleLike(id), [
    toggleLike,
    id,
    isLikable,
  ])

  return (
    <Button
      {...bindClassName(
        'common-button-rounded success-round-button toggle-like',
        [className],
      )}
      icon={isLiked ? 'pi pi-thumbs-down' : 'pi pi-thumbs-up'}
      onClick={handleLikeClick}
      disabled={!isLikable}
      type="button"
      {...props}
    />
  )
}

const withData = inject((stores) => ({
  toggleLike: stores.CommunityStore.toggleLike,
  getIsLiked: stores.CommunityStore.getIsLiked,
  getIsLikable: stores.CommunityStore.getIsLikable,
}))

export default withData(observer(ToggleLike))
