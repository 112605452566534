import React from 'react'

import { propsMapping, defaultValues } from '../config'
import FormFields from '../../../../common/FormFields'

const DeviceInfo = () => (
  <FormFields values={defaultValues} propsMapping={propsMapping} />
)

export default DeviceInfo
