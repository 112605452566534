import React, { useCallback, useEffect } from 'react'
import { useField } from 'formik'
import { Checkbox } from 'primereact/checkbox'

import ItemUI from '../ItemUI'

const SelectableItem = ({ profileDevice }) => {
  const [{ value: ids }, , { setValue: setIds }] = useField('ids')

  const currId = profileDevice.id
  const checked = ids.includes(currId)

  const toggleSelected = useCallback(() => {
    let newIds
    if (checked) {
      newIds = ids.filter((id) => id != currId)
    } else {
      newIds = [...ids, currId]
    }
    setIds(newIds)
  }, [setIds, ids, checked, currId])

  useEffect(() => {
    if (!checked) toggleSelected()
  }, [])

  const actionUI = <Checkbox checked={checked} />

  return (
    <ItemUI
      actionUI={actionUI}
      onClick={toggleSelected}
      profileDevice={profileDevice}
    />
  )
}

export default SelectableItem
