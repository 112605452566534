import React from 'react'

import PageActionsWrapper from '../../common/PageActionsWrapper'
import SecondaryButton from '../../buttons/SecondaryButton'

const ButtonsGroup = () => (
  <PageActionsWrapper>
    <SecondaryButton
      labelKey="add_application"
      to="/applications/application"
      isAbsolute={false}
    />
    <SecondaryButton
      labelKey="add_platform"
      to="/applications/platform"
      isAbsolute={false}
    />
  </PageActionsWrapper>
)

export default ButtonsGroup
