import * as yup from 'yup'
import { createShape } from '../../utils/form'
import { ErrorMsg } from '../../constants/errorMsg'
import CustomSelect from '../common/CustomSelect'

export const defaultValues = {
  name: '',
  ApplicationPlatformId: null,
  realId: '',
}

export const propsMapping = {
  realId: {
    isRequired: false,
  },
  ApplicationPlatformId: {
    component: CustomSelect,
    optionLabel: 'name',
    optionValue: 'id',
    labelText: 'platform',
  },
}

export const validationSchema = yup
  .object()
  .shape(
    createShape(
      defaultValues,
      propsMapping,
      (key) => ErrorMsg[`APPLICATION_${key.toUpperCase()}_NOT_VALID`],
    ),
  )
