import React from 'react'

const items = [
  /* {
    labelKey: 'profile',
    url: '/profile',
    isPrivate: true,
    icon: <MdHome />,
  },*/
  {
    labelKey: 'users',
    url: '/users',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'devices',
    url: '/devices',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'community',
    url: '/community',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'logs',
    url: '/logs',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'statistics',
    url: '/statistics',
    isPrivate: true,
    needVerify: true,
  },

  {
    labelKey: 'feedback',
    url: '/feedback',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'promotions',
    url: '/promotions',
    isPrivate: true,
    needVerify: true,
  },
  /*{
    labelKey: 'logout',
    icon: <MdExitToApp />,
    className: 'nav-bar-menu-item__logout',
    isPrivate: true,
    isLogout: true,
  },*/
]

export default items
